import { useEffect, useState } from "react";
import { getUploadedStory } from "../../publicFunctions/debriefHandler";
import { getCardImageUrlByCard } from "../../publicFunctions/publicHelpers/imageUrlBuilder";
import { arrowBackCircleOutline, openOutline } from "ionicons/icons";
import { IonIcon } from "@ionic/react";

import DeleteAThing from "../forum/DeleteAThing";
import { deleteTavernStory } from "../../config/firebase";
import { nightModeStyleWhite } from "../../publicFunctions/publicHelpers/helpers";

function SelectMyUploads({
  myUploads,
  loadedStory,
  setLoadedStory,
  isWorking,
  handleUploadRefresh,
  refreshMyUploads,
  nightMode,
}) {
  const [fetchingStory, setFetchingStory] = useState(false);
  const [selectedStoryId, setSelectedStoryId] = useState("");
  const [myRenderedUploadsList, setMyRenderedUploadsList] = useState(null);

  const clearLoadedStory = () => {
    setLoadedStory(null);
    setSelectedStoryId("");
  };

  // create the renderedList
  useEffect(() => {
    if (myUploads?.length) {
      const tempStoryArray = [...myUploads];
      tempStoryArray.sort((a, b) => {
        if (b.lastUpdatedDate && a.lastUpdatedDate) {
          return b.lastUpdatedDate.getTime() - a.lastUpdatedDate.getTime();
        } else {
          return 0;
        }
      });

      const tempRender = [];
      for (let i = 0; i < tempStoryArray.length; i++) {
        const storyObj = tempStoryArray[i];
        const storyTitle = storyObj?.storyTitle ? storyObj.storyTitle : "STORY";
        if (!storyObj?.storyId) {
          continue;
        }

        const renderedCards = [];
        if (!renderedCards.length && storyObj.cards) {
          renderedCards.push(
            <img
              className="debrief-genre-title-card"
              key={"genre_" + storyObj.storyId + "_img"}
              src={getCardImageUrlByCard(storyObj.cards.genre, true)}
            />,
            <img
              className="debrief-genre-title-card"
              key={"prompt1_" + storyObj.storyId + "_img"}
              src={getCardImageUrlByCard(storyObj.cards.prompt1, true)}
            />,
            <img
              className="debrief-genre-title-card"
              key={"prompt2_" + storyObj.storyId + "_img"}
              src={getCardImageUrlByCard(storyObj.cards.prompt2, true)}
            />
          );
        }

        tempRender.push(
          <div
            key={storyObj.storyId}
            className={
              "debrief-story-container debrief-story-container--tavern cursor--pointer" +
              (nightMode ? " debrief-story-container--nightMode" : "")
            }
            role="button"
            onClick={(event) => {
              event.preventDefault();
              setSelectedStoryId(storyObj.storyId);
            }}
          >
            <div
              style={{ gridColumn: "1/-1", justifySelf: "end" }}
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
              }}
            >
              <DeleteAThing
                description={"Tavern Story"}
                handleDelete={() => {
                  deleteTavernStory({ storyId: storyObj.storyId })
                    .then(() => {
                      handleUploadRefresh();
                    })
                    .catch((err) => {
                      console.log(err);
                    });
                }}
              />
            </div>
            <div className="flex align-items--center">{renderedCards}</div>
            <div className={"flex flex--direction-column justify-content--end"}>
              <p className="description--large bold">{storyTitle}</p>
            </div>
            <div
              className="justify-self--end align-self--end"
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
                window.open("/story/tavern/" + storyObj.storyId, "_blank");
              }}
            >
              <div className="read-content description--small flex align-items--center bold underline-on-hover">
                Read Story
                <IonIcon className="social-icon" icon={openOutline} />
              </div>
            </div>
          </div>
        );
      }
      setMyRenderedUploadsList(tempRender);
    } else {
      setMyRenderedUploadsList(null);
    }
  }, [myUploads, nightMode]);

  useEffect(() => {
    if (selectedStoryId && myUploads) {
      // get the loaded story
      setFetchingStory(true);
      getUploadedStory(selectedStoryId)
        .then((tempLoadedStory) => {
          if (tempLoadedStory) {
            setLoadedStory(tempLoadedStory);
          }
        })
        .catch((err) => {
          console.log(err);
          // try again
          getUploadedStory(selectedStoryId)
            .then((tempLoadedStory) => {
              if (tempLoadedStory) {
                setLoadedStory(tempLoadedStory);
              }
            })
            .catch((errNext) => {
              console.log("failed again");
              console.log(errNext);
              setSelectedStoryId("");
            });
        })
        .finally(() => {
          setFetchingStory(false);
        });
    }
  }, [selectedStoryId, myUploads]);

  const onSubmit = (event) => {
    event.preventDefault();
    setLoadedStory({ storyId: "" });
  };

  return (
    <div className="container margin-top-bigger">
      {loadedStory ? (
        <div>
          <div className="flex flex--justify-start align-items--center flex--gap-smaller">
            <IonIcon
              onClick={() => {
                clearLoadedStory();
              }}
              className="social-icon cursor--pointer"
              icon={arrowBackCircleOutline}
            />
            <p
              onClick={() => {
                clearLoadedStory();
              }}
              className="description underline bold cursor--pointer"
            >
              Exit this story
            </p>
          </div>
          <p className="description--smaller">
            Careful! Make sure you save any changes before navigating away from
            your story!
          </p>
        </div>
      ) : (
        <div
          key={refreshMyUploads}
          className="flex justify-content--center flex--direction-column margin-bottom-huge"
        >
          <h2
            style={nightMode ? nightModeStyleWhite : {}}
            className="heading-secondary margin--none"
          >
            Create and Share a Personal Project
          </h2>
          <p className="description">
            Note: this is for personal projects only. Stories from active
            Battles <strong>do not</strong> belong in the Tavern.
            <br />
            If you are currently participating in a Battle, then submit your
            story on the{" "}
            <a
              className="simple-underline this-anchor-is-a-button"
              href="https://www.writingbattle.com/battle"
            >
              Battle page
            </a>
            . Not Here.
          </p>
          <br />
          <button
            className="btn btn--register min-width--larger story-submission-btn max-width--readable-center"
            disabled={isWorking}
            onClick={onSubmit}
          >
            Create New Story
          </button>
          <br />
          <br />
          <br />
          <br />
          {myRenderedUploadsList?.length ? (
            <>
              <p className="description--large bold center-text">or...</p>
              <div className="flex flex--direction-column flex--gap-small">
                <h2
                  style={nightMode ? nightModeStyleWhite : {}}
                  className="heading-secondary margin--none"
                >
                  Update Existing Tavern Story
                </h2>

                <div className="flex flex--direction-column flex--gap-small">
                  {myRenderedUploadsList}
                </div>
              </div>
            </>
          ) : null}
        </div>
      )}
    </div>
  );
}

export default SelectMyUploads;
