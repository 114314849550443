function ConsentBanner({ giveConsentHandler, closeConsentHandler }) {
  return (
    <div className="consent-banner white margin-auto">
      {/* <button
        onClick={() => {}}
        className="general-icon-box general-icon-box--close"
      >
        <IonIcon
          className="general-icon general-icon--white"
          icon={closeOutline}
        />
      </button> */}
      <div className="flex align-items--center justify-content--center margin-auto flex--gap-normal content-banner--stacked">
        <div className="flex flex--direction-column content-banner-buttons--unstack">
          <p className="description--small center-text">
            Writing Battle uses cookies to make our site work and also for
            analytics and advertising purposes.
            <br />
            You can learn more about how we use cookies in our{" "}
            <a
              href="https://www.writingbattle.com/privacy-policy"
              target="_blank"
              className="description--small this-anchor-is-a-button clickable clickable-text clickable-text--no-margin"
            >
              Privacy Policy
            </a>
            .
          </p>
        </div>
        <button
          onClick={() => {
            giveConsentHandler();
            closeConsentHandler();
          }}
          className="btn description--small"
        >
          Okay, whatever
        </button>
        {/* <button className="btn btn--small-font description--small btn--full--winter">
          No, thanks
        </button> */}
      </div>
    </div>
  );
}

export default ConsentBanner;
