import { IonIcon } from "@ionic/react";
import { sunnyOutline, moonOutline } from "ionicons/icons";

function DayNightToggle({
  refreshNightMode,
  nightMode,
  hideWhenMenuClicked = false,
}) {
  if (hideWhenMenuClicked) {
    return null;
  }
  return (
    <div
      className={
        "cursor--pointer day-night-toggle" +
        (nightMode ? " white-border" : " column-reverse")
      }
      onClick={() => {
        refreshNightMode(!nightMode);
      }}
    >
      <IonIcon
        className="social-icon"
        icon={nightMode ? moonOutline : sunnyOutline}
      />
      <div className={"circle-icon" + (nightMode ? " white-border" : "")}></div>
    </div>
  );
}

export default DayNightToggle;
