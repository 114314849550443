import { useEffect, useState } from "react";
import {
  duelStates,
  stateNames,
  doIHaveADuelReady,
  outReasons,
  nightModeStyle,
  nightModeStyleWhite,
} from "../../publicFunctions/publicHelpers/helpers";

const DID_NOT_RECEIVE_STORY_MESSAGE =
  "We did not receive your story. If you believe this is a mistake, " +
  "please email admin@writingbattle.com as soon as possible with any " +
  "details. If however, you did not have time, no problem! Life gets busy. " +
  "Since you do not have a story in the Battle, you will not be asked to " +
  "judge. Thank you, and we hope to see you in a future Battle.";

const DID_NOT_RECEIVE_PRO_MESSAGE =
  "We did not receive your story. If you believe this is a mistake, " +
  "please email admin@writingbattle.com as soon as possible with any " +
  "details. If however, you did not have time, no problem! Life gets busy. " +
  "Thank you, and we hope to see you in a future Battle.";

const THANK_YOU_FOR_DUEL =
  "Thank you for completing your Duel! Please keep an " +
  "eye out on your email in case there's " +
  "anything that needs to be addressed. We manually check every Duel to " +
  "ensure a fun and fair competition.";

function MessageWithPreview({
  battleInfo,
  loadedGameState,
  loadedDuel,
  isFirstDuel,
  isOut,
  nightMode,
}) {
  const [message, setMessage] = useState("");

  const peerJudged = battleInfo?.rawTuple?.peerJudged ? true : false;

  useEffect(() => {
    const {
      duelIsReady,
      duelNotCompletedOnTime,
      finishedAllDuels,
      isDuel,
      howManyDuelsComplete,
    } = doIHaveADuelReady(battleInfo, loadedGameState);

    const state = battleInfo.rawTuple.state;
    if (peerJudged && isOut) {
      let message = loadedGameState?.storyId
        ? "Your story is out of the competition."
        : "We did not receive your story in time.";
      if (loadedGameState.status.reason === outReasons.PROMPT_DQ) {
        message +=
          " Your prompts did not meet the requirements of the contest.";
      } else if (
        loadedGameState.status.reason === outReasons.NOT_SUBMIT_STORY
      ) {
        message += " We did not receive it before the deadline.";
      }
      message +=
        " If you believe this to be a mistake, please email us at admin@writingbattle.com";
      setMessage(message);
    } else if (state === stateNames.STORIES_SUBMITTED) {
      if (loadedGameState?.storyId) {
        setMessage(
          "We have received your story." +
            (peerJudged
              ? " Please come back to judge your first Duel!"
              : " Please come back for Debrief which will begin shortly.")
        );
      } else {
        setMessage(
          peerJudged
            ? DID_NOT_RECEIVE_STORY_MESSAGE
            : DID_NOT_RECEIVE_PRO_MESSAGE
        );
      }
    } else if (state === stateNames.FIRST_DUELS) {
      if (!peerJudged) {
        setMessage(
          "Judging is currently underway... the most up-to-date results will be on the Debrief page. Good luck!"
        );
      } else if (loadedGameState?.storyId) {
        // if this is the first duel then the user has not completed anything yet
        if (finishedAllDuels) {
          setMessage(
            "Whoa! Did you finish judging all of your Duels? Excellent " +
              "work! Thank you. Please keep an eye out on your email in case there's " +
              "anything that needs to be addressed. We manually check every Duel to " +
              "ensure a fun and fair competition."
          );
        }
        // else if (!isDuelAndNotCeasefireNext) {
        //   setMessage(
        //     "Next up is the CEASEFIRE! Which means you get to find out how your story" +
        //       " is faring thus far in the competition. " +
        //       "If your story no longer has a chance to " +
        //       "win the competition, you will have to indicate that you still want to " +
        //       "hang in for the glory of Battle. Good luck and see you at Ceasefire! " +
        //       "Keep an eye out for the ceasefire email. If you have already received the email, " +
        //       " then you can ignore this message."
        //   );
        // }
        else if (isFirstDuel && !loadedDuel) {
          setMessage(
            "Hmmm please hang tight. It looks like we're preparing your " +
              "Duel. If in 15minutes you refresh the page and still see this" +
              " message, then please email admin@writingbattle.com and we'll " +
              "look into it."
          );
        } else if (
          duelNotCompletedOnTime ||
          loadedDuel?.state === duelStates.NOT_COMPLETED
        ) {
          setMessage(
            "We did not receive the judgement for your last Duel. If you believe this to be " +
              "a mistake, then please email admin@writingbattle.com and " +
              "we'll look into the matter."
          );
        } else if (howManyDuelsComplete > 0) {
          setMessage(THANK_YOU_FOR_DUEL);
        }
      } else if (!duelIsReady || loadedDuel?.state === duelStates.COMPLETE) {
        setMessage(THANK_YOU_FOR_DUEL);
      } else if (!loadedGameState?.storyId) {
        setMessage(
          peerJudged
            ? DID_NOT_RECEIVE_STORY_MESSAGE
            : DID_NOT_RECEIVE_PRO_MESSAGE
        );
      }
    }
  }, [loadedGameState]);

  return (
    <div
      style={nightMode ? nightModeStyle : {}}
      className="message-and-preview-wrapper"
    >
      <p
        style={nightMode ? nightModeStyleWhite : {}}
        className="heading-tertiary center-text"
      >
        {message}
      </p>
      {loadedGameState?.storyId && !isOut ? (
        <div>
          <button
            disabled={!loadedGameState?.storyId}
            onClick={() =>
              window.open(
                "/story/" +
                  battleInfo.rawTuple.battleId +
                  "/" +
                  loadedGameState?.storyId,
                "_blank"
              )
            }
            className="btn btn--register min-width--larger story-submission-btn"
          >
            Preview your story
          </button>
        </div>
      ) : null}
    </div>
  );
}

export default MessageWithPreview;
