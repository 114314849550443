import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "../../styles/everythingQuill.css";
import {
  dayModeDefault,
  dayModeStyleBlack,
  nightModeStyle,
} from "../../publicFunctions/publicHelpers/helpers";

const hiddenToolbar = {
  toolbar: false,
};

const modules = {
  toolbar: [
    ["bold", "italic", "underline", "strike"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["link"],
    ["clean"],
  ],
};

let formats = [
  "header",
  "bold",
  "italic",
  "underline",
  "strike",
  "list",
  "bullet",
  "indent",
  "link",
];

function ForumQuillWrapper({
  contentHandler,
  content,
  isReadOnly,
  hideHeader,
  isReply,
  nightMode,
}) {
  // we need to make sure this doesn't change
  // according to api
  const placeholder = isReadOnly
    ? ""
    : isReply
    ? "Write your comment here."
    : "Body of discussion goes here.";
  const toolbar = hideHeader ? hiddenToolbar : modules;

  function handleChange(content, delta, source, editor) {
    if (contentHandler) {
      contentHandler(editor.getContents());
    }
  }

  return (
    <ReactQuill
      style={nightMode ? nightModeStyle : dayModeDefault}
      className={"forum-editor break-long-words"}
      theme="snow"
      value={content}
      onChange={handleChange}
      readOnly={isReadOnly}
      modules={toolbar}
      formats={formats}
      placeholder={placeholder}
    />
  );
}

export default ForumQuillWrapper;
