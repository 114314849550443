import { useEffect, useState } from "react";
import JudgeList from "./JudgeList";
import { getBattleJudgesDoc } from "../../publicFunctions/publicBattleHandler";
import { shuffleArray } from "../../publicFunctions/publicHelpers/helpers";

function BattleJudges({ battleId, nightMode }) {
  const [battleJudgesDoc, setBattleJudgesDoc] = useState(null);
  useEffect(() => {
    if (battleId) {
      // get the battleJudges doc -- may be null
      // shuffle proJudges and industryJudges lists
      getBattleJudgesDoc(battleId)
        .then((data) => {
          if (data?.proJudges?.length) {
            shuffleArray(data.proJudges);
          }
          if (data?.industryJudges?.length) {
            shuffleArray(data.industryJudges);
          }
          if (data) {
            setBattleJudgesDoc(data);
          } else {
            setBattleJudgesDoc(null);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [battleId]);

  if (!battleJudgesDoc) {
    return null;
  }

  return (
    <div className="green-background--darkerer">
      {battleJudgesDoc ? (
        <>
          <JudgeList
            key={"Industry-" + battleId}
            forIndustryJudges={true}
            battleJudgesDoc={battleJudgesDoc}
            battleId={battleId}
            nightMode={nightMode}
          />
          <JudgeList
            key={"Pro-" + battleId}
            forIndustryJudges={false}
            battleJudgesDoc={battleJudgesDoc}
            battleId={battleId}
            nightMode={nightMode}
          />
        </>
      ) : null}
    </div>
  );
}

export default BattleJudges;
