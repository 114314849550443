import Lottie from "react-lottie-player";
import loadingAnimation from "../../img/animations/loadinganimation.json";
import { useEffect, useState } from "react";
import SelectUserButton from "./SelectUserButton";

function SelectUsers({
  isLoading,
  listOfSelectableUserIds,
  suggestedUserIds,
  handleUserSelectedToBeTagged,
  doNotRenderTheseUserIdsMap,
  nightMode,
}) {
  const [renderedSelectableUsers, setRenderedSelectableUsers] = useState([]);
  const [renderedSuggestedUsers, setRenderedSuggestedUsers] = useState([]);

  useEffect(() => {
    if (listOfSelectableUserIds?.length) {
      const tempRendered = [];
      for (let i = 0; i < listOfSelectableUserIds.length; i++) {
        const userId = listOfSelectableUserIds[i];
        if (userId && !doNotRenderTheseUserIdsMap.has(userId)) {
          tempRendered.push(
            <SelectUserButton
              key={i + "_" + userId}
              userId={userId}
              clickingRemovesMe={false}
              handleWhatHappensWhenYouClickMe={handleUserSelectedToBeTagged}
              nightMode={nightMode}
            />
          );
        }
      }
      setRenderedSelectableUsers(tempRendered);
    }
  }, [listOfSelectableUserIds, doNotRenderTheseUserIdsMap, nightMode]);

  useEffect(() => {
    if (suggestedUserIds?.length) {
      const tempRendered = [];
      for (let i = 0; i < suggestedUserIds.length; i++) {
        const userId = suggestedUserIds[i];
        if (userId && !doNotRenderTheseUserIdsMap.has(userId)) {
          tempRendered.push(
            <SelectUserButton
              key={i + "_" + userId}
              userId={userId}
              clickingRemovesMe={false}
              handleWhatHappensWhenYouClickMe={handleUserSelectedToBeTagged}
              nightMode={nightMode}
            />
          );
        }
      }
      setRenderedSuggestedUsers(tempRendered);
    }
  }, [suggestedUserIds, doNotRenderTheseUserIdsMap, nightMode]);

  return (
    <div className="search-bar-container--less-padding">
      {isLoading ? (
        <div className="flex align-items--center">
          <div className="margin-left-sm">
            <Lottie
              loop
              animationData={loadingAnimation}
              play
              style={{ width: 50, height: 50 }}
            ></Lottie>
          </div>
          <p className="description--small">Searching for users...</p>
        </div>
      ) : renderedSelectableUsers?.length ? (
        <div className="select-users-list">{renderedSelectableUsers}</div>
      ) : null}
      {renderedSuggestedUsers?.length ? (
        <>
          <p className="description--smaller margin-top-smaller">
            Suggested users to tag:
          </p>
          <div className="select-users-list">{renderedSuggestedUsers}</div>
        </>
      ) : null}
    </div>
  );
}

export default SelectUsers;
