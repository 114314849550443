import { useEffect, useState } from "react";
import Lottie from "react-lottie-player";
import loadingAnimation from "../../img/animations/loadinganimation.json";
import { IonIcon } from "@ionic/react";
import { checkmarkCircleOutline, closeCircle } from "ionicons/icons";
import { getUploadedStory } from "../../publicFunctions/debriefHandler";
import SetUploadedStory from "./SetUploadedStory";
import { updateMyFeaturedStories } from "../../config/firebase";
import { nightModeStyleWhite } from "../../publicFunctions/publicHelpers/helpers";

function SelectUploadedStories({
  featuredStories,
  uploads,
  cancelEditingFeaturedStories,
  refreshMyProfile,
  tavernStories,
  nightMode,
}) {
  const [stories, setStories] = useState(null);
  const [renderedStoryUploads, setRenderedStoryUploads] = useState([]);
  const [storiesToFeatureOrUnfeature, setStoriesToFeatureOrUnfeature] =
    useState([]);
  const [changesMade, setChangesMade] = useState(false);
  const [storyToChange, setStoryToChange] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  async function downloadInfoForAllUploads(uploads, tavernStories) {
    let tempMasterList = [];
    if (uploads?.length) {
      tempMasterList = tempMasterList.concat(uploads);
    }
    if (tavernStories?.length) {
      tempMasterList = tempMasterList.concat(tavernStories);
    }
    const tempStories = [];
    setIsLoading(true);
    for (let i = 0; i < tempMasterList.length; i++) {
      const upload = tempMasterList[i];
      // this is a debrief story, so get it from that collection
      const tempStory = await getUploadedStory(upload.storyId);
      if (tempStory) {
        tempStories.push(tempStory);
      }
    }
    if (tempStories.length) {
      setStories(tempStories);
    }
    setIsLoading(false);
  }

  useEffect(() => {
    if (!stories && (uploads?.length || tavernStories?.length)) {
      downloadInfoForAllUploads(uploads, tavernStories);
    }
  }, [uploads, tavernStories]);

  useEffect(() => {
    if (stories?.length) {
      const alreadyChecked = new Map();
      if (featuredStories?.length) {
        featuredStories.forEach((story) => {
          alreadyChecked.set(story.storyId, true);
        });
      }

      const tempRendered = [];
      const tempFeaturedUnfeatured = [];
      for (let i = 0; i < stories.length; i++) {
        const story = stories[i];
        tempFeaturedUnfeatured.push({
          storyId: story.storyId,
          alreadyFeatured: alreadyChecked.has(story.storyId),
          requestedAction: "none",
        });

        tempRendered.push(
          <SetUploadedStory
            key={"story_" + i.toString(10)}
            story={story}
            alreadyChecked={alreadyChecked.has(story.storyId)}
            handleChangesMade={(storyId, checked) => {
              setStoryToChange({ storyId, checked });
            }}
          />
        );
      }
      if (tempRendered.length) {
        setRenderedStoryUploads(tempRendered);
      }
      if (tempFeaturedUnfeatured.length) {
        setStoriesToFeatureOrUnfeature(tempFeaturedUnfeatured);
      }
    }
  }, [stories, featuredStories, nightMode]);

  useEffect(() => {
    if (storyToChange) {
      const storyId = storyToChange.storyId;
      const checked = storyToChange.checked;

      for (let i = 0; i < storiesToFeatureOrUnfeature.length; i++) {
        const story = storiesToFeatureOrUnfeature[i];
        if (story.storyId === storyId) {
          if (
            (story.alreadyFeatured && checked) ||
            (!story.alreadyFeatured && !checked)
          ) {
            story.requestedAction = "none";
          } else if (checked) {
            story.requestedAction = "feature";
          } else {
            story.requestedAction = "unfeature";
          }
          break;
        }
      }

      // now go through the entire array again and see if something doesn't equal 'none'
      // and then we know if changes were made
      let tempChangesMade = false;
      for (let i = 0; i < storiesToFeatureOrUnfeature.length; i++) {
        const story = storiesToFeatureOrUnfeature[i];
        if (story.requestedAction !== "none") {
          tempChangesMade = true;
          break;
        }
      }
      setChangesMade(tempChangesMade);
      setStoriesToFeatureOrUnfeature(
        JSON.parse(JSON.stringify(storiesToFeatureOrUnfeature))
      );
      setStoryToChange(null);
    }
  }, [storyToChange]);

  return (
    <div className="flex flex--direction-column justify-content--center align-items--center flex--gap-small">
      <p className="description center-text max-width--readable-center bold">
        Select the stories you want featured on your profile, then click the
        checkmark to save changes.
      </p>
      {renderedStoryUploads}
      {isLoading ? (
        <div className="center-flex">
          <Lottie
            loop
            animationData={loadingAnimation}
            play
            style={{ width: 75, height: 75 }}
          ></Lottie>
        </div>
      ) : (
        <div className="redraw--youSure align-self--center margin-auto">
          <p
            style={nightMode ? nightModeStyleWhite : {}}
            className="description"
          >
            All done?
          </p>
          <div
            onClick={async () => {
              if (!changesMade) {
                return;
              }
              setIsLoading(true);
              try {
                const { data } = await updateMyFeaturedStories({
                  storiesToFeatureOrUnfeature,
                });
                refreshMyProfile();
              } catch (err) {
                console.log(err);
              } finally {
                setIsLoading(false);
              }
              cancelEditingFeaturedStories();
            }}
          >
            <IonIcon
              className={changesMade ? "im-sure" : "im-sure im-sure--grey"}
              icon={checkmarkCircleOutline}
            />
          </div>
          <IonIcon
            onClick={() => {
              cancelEditingFeaturedStories();
            }}
            className="not-sure"
            icon={closeCircle}
          />
        </div>
      )}
    </div>
  );
}

export default SelectUploadedStories;
