import { useEffect, useState } from "react";
import Comments from "./Comments";
import LeaveAComment from "./LeaveAComment";
import { fetchSingleComment } from "../../publicFunctions/forumHandler";
import Comment from "./Comment";
import {
  DOES_NOT_EXIST,
  getStoryIdByUserId,
  getStoryIdByUserIdAndRaidId,
} from "../../publicFunctions/debriefHandler";
import { getPublicProfileByUserId } from "../../publicFunctions/publicProfileHandler";
import { nightModeStyleDarkBackgroundOnly } from "../../publicFunctions/publicHelpers/helpers";

function UploadedStoryCommentSection({
  myProfile,
  uploadedStory,
  userInfo,
  isFromBattle,
  commentId,
  nightMode,
}) {
  const [refresh, setRefresh] = useState(false);

  // this is for loading a specific comment that has been replied to
  const [commentData, setCommentData] = useState(null);
  const [renderedHighlightedComment, setRenderedHighlightedComment] =
    useState(null);

  useEffect(() => {
    if (commentId) {
      fetchSingleComment(commentId)
        .then((data) => {
          if (data) {
            setCommentData(data);
          }
        })
        .catch((err) => {
          console.log(err);
          // just do it again
          fetchSingleComment(commentId)
            .then((data) => {
              if (data) {
                setCommentData(data);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        });
    }
  }, [commentId]);

  async function getUserData(commentStuff) {
    if (!commentStuff) {
      return;
    }
    // get public profile for this user
    const publicUser = await getPublicProfileByUserId(
      commentStuff.userId,
      true
    );
    let tempStoryId;
    if (uploadedStory.battleId !== "tavern") {
      tempStoryId = await getStoryIdByUserId(
        uploadedStory.battleId,
        commentStuff.userId
      );
    } else if (uploadedStory.raid) {
      tempStoryId = await getStoryIdByUserIdAndRaidId(
        uploadedStory.raid,
        commentStuff.userId
      );
    } else {
      // TODO: make this more sophisticated, maybe going to the user's
      // featured story in tavern
    }

    let storyId = null;
    if (tempStoryId && tempStoryId !== DOES_NOT_EXIST) {
      storyId = tempStoryId;
    }
    setRenderedHighlightedComment(
      <div className="container padding-top-bot--sm">
        <Comment
          myProfile={myProfile}
          key={"highlighted_comment" + commentData.commentId}
          comment={commentData}
          publicUser={publicUser}
          uploadedStory={uploadedStory}
          storyId={storyId}
          canEdit={
            commentData?.userId &&
            userInfo?.userId &&
            commentData.userId === userInfo.userId
          }
          thisIsMyStory={thisIsMyStory}
          myUserId={userInfo?.userId ? userInfo.userId : ""}
          forceRefresh={() => forceRefreshComments()}
          highlighted={true}
          nightMode={nightMode}
        />
      </div>
    );
  }

  useEffect(() => {
    if (commentData) {
      getUserData(commentData);
    }
  }, [commentData, nightMode]);

  const forceRefreshComments = () => {
    setRefresh(!refresh);
  };
  const thisIsMyStory =
    userInfo?.userId && uploadedStory?.userId === userInfo.userId;

  return (
    <div style={nightMode ? nightModeStyleDarkBackgroundOnly : {}}>
      {renderedHighlightedComment ? renderedHighlightedComment : null}
      <LeaveAComment
        userInfo={userInfo}
        storyId={uploadedStory.storyId}
        typeOfComments={uploadedStory.typeOfComments}
        forceRefreshComments={forceRefreshComments}
        isFromBattle={isFromBattle}
        nightMode={nightMode}
      />
      {uploadedStory?.storyId &&
      (!commentId || commentData) &&
      myProfile?.isInitialized() ? (
        <div style={{ margin: "2.4rem 0" }}>
          <Comments
            myProfile={myProfile}
            key={refresh}
            uploadedStory={uploadedStory}
            userInfo={userInfo}
            forceRefreshComments={forceRefreshComments}
            skipCommentId={commentId}
            nightMode={nightMode}
          />
        </div>
      ) : null}
    </div>
  );
}

export default UploadedStoryCommentSection;
