import { nightModeStyleWhite } from "../../publicFunctions/publicHelpers/helpers";

function ForumBodyNav({ selectPageHandler, selectedPage, nightMode }) {
  const selectableItemClass = "heading-tertiary debrief-nav-item";
  let discussClass = selectableItemClass;
  let betaReadClass = selectableItemClass;
  if (selectedPage === "topics") {
    discussClass +=
      " debrief-nav-item--selected" +
      (nightMode ? " selected-light" : " selected-dark");
  } else if (selectedPage === "betaRead") {
    betaReadClass +=
      " debrief-nav-item--selected" +
      (nightMode ? " selected-light" : " selected-dark");
  }

  return (
    <div className="container debrief-body-nav padding--none">
      <div
        tabIndex={0}
        role="button"
        onClick={() => {
          selectPageHandler("topics");
        }}
      >
        <p
          style={nightMode ? nightModeStyleWhite : {}}
          className={discussClass}
        >
          Discuss
        </p>
      </div>
      <div
        tabIndex={0}
        role="button"
        onClick={() => {
          selectPageHandler("betaRead");
        }}
      >
        <p
          style={nightMode ? nightModeStyleWhite : {}}
          className={betaReadClass}
        >
          Beta-Read
        </p>
      </div>
    </div>
  );
}

export default ForumBodyNav;
