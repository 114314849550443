import { useEffect, useState } from "react";
import { findNextDuel, getNextDuel } from "../../config/firebase";
import {
  JUDGING_ADMIN_JUDGE,
  JUDGING_INDUSTRY_JUDGE,
  JUDGING_PRO_JUDGE,
  JUDGING_SPARTAN,
} from "../../publicFunctions/adminDuelHandler";
import LoadingSection from "../LoadingSection";
import { nightModeStyleWhite } from "../../publicFunctions/publicHelpers/helpers";

function GetDuel({
  battleId,
  onBattleIdChange,
  onLoadDuel,
  initialDuelId,
  headerMessage,
  judgeType,
  judgeGameState,
  onUpdatedGameStateFromServer,
  onUpdateReportNotes,
  nightMode,
}) {
  const [isWorking, setIsWorking] = useState(false);
  const [duelId, setDuelId] = useState(initialDuelId ? initialDuelId : "");
  const [message, setMessage] = useState("");
  const [duelObject, setDuelObject] = useState(null);
  const [numberOfOpenDuels, setNumberOfOpenDuels] = useState(-1);
  const [numberOfReservedDuels, setNumberOfReserverdDuels] = useState(-1);
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    // do we show the button or not? what else should we show?
    if (judgeType === JUDGING_ADMIN_JUDGE) {
      setIsInitialized(true);
    } else if (
      judgeType === JUDGING_PRO_JUDGE ||
      judgeType === JUDGING_INDUSTRY_JUDGE
    ) {
      onGetNextDuel(true);
    } else if (judgeType === JUDGING_SPARTAN) {
      setNumberOfReserverdDuels(0);
    }
  }, [judgeType]);

  const onGetNextDuel = (onlyCount = false) => {
    setIsWorking(true);
    if (!isInitialized) {
      setMessage("One sec...");
    } else {
      setMessage("Finding your next Duel...");
    }
    getNextDuel({ battleId, onlyCount })
      .then(({ data }) => {
        if (data?.success) {
          setMessage("");
          if (data.activeDuel) {
            if (data.judgeNote || data.adminNote) {
              onUpdateReportNotes({
                judgeNote: data.judgeNote ? data.judgeNote : "",
                adminNote: data.adminNote ? data.adminNote : "",
              });
            } else {
              onUpdateReportNotes(null);
            }
            onLoadDuel(data.activeDuel);
          } else {
            onUpdateReportNotes(null);
            setNumberOfOpenDuels(
              data.numberOfOpenDuels ? data.numberOfOpenDuels : 0
            );
            setNumberOfReserverdDuels(
              data.numberOfReservedDuels ? data.numberOfReservedDuels : 0
            );
            onLoadDuel(null);
            if (data.updatedGameState) {
              onUpdatedGameStateFromServer(data.updatedGameState);
            }
          }
        } else {
          const combinedMessage =
            "Hmmmm something went wrong. " + (data.message ? data.message : "");
          setMessage(combinedMessage);
        }
      })
      .catch((err) => {
        if (err?.message) {
          console.log(err.message);
        }
        setNumberOfOpenDuels(0);
        setMessage("Something went wrong. Please refresh the page.");
        onLoadDuel(null);
      })
      .finally(() => {
        setIsInitialized(true);
        setIsWorking(false);
      });
  };

  const onDuelIdChange = (event) => {
    setDuelId(event.target.value);
  };

  const onFindNextDuelAdmin = async (event) => {
    event.preventDefault();
    try {
      if (!battleId) return;
      setIsWorking(true);
      setMessage("Finding your next Duel...");
      const { data } = await findNextDuel({ battleId });
      if (data?.duelId) {
        setDuelId(data.duelId);
        setDuelObject(data);
        setMessage("Duel ID populated. Click 'Load Duel'");
      } else if (data?.message) {
        setMessage(data.message);
      } else {
        setMessage("Maybe you're all done? Email admin@writingbattle.com");
      }
    } catch (err) {
      console.log(err);
      setMessage(
        "Hmmmm the servers might be busy. Please try again in a few minutes." +
          " If you continue to receive this error, please email admin@writingbattle.com"
      );
    } finally {
      setIsInitialized(true);
      setIsWorking(false);
    }
  };

  const onDownloadDuel = async (event) => {
    event.preventDefault();
    try {
      if (!duelId) return;
      setIsWorking(true);
      setMessage("Loading Duel...");
      if (duelObject) {
        onLoadDuel(duelObject);
      } else {
        const { data } = await findNextDuel({ duelId });
        setMessage("Woohoo! Got it!");
        setDuelObject(data);
        onLoadDuel(data);
      }
    } catch (err) {
      console.log(err);
      setMessage(
        "Hmmmm the servers might be busy. Please try again in a few minutes." +
          " If you continue to receive this error, please email admin@writingbattle.com"
      );
    } finally {
      setIsWorking(false);
    }
  };

  return (
    <div style={nightMode ? nightModeStyleWhite : {}} className="get-duel">
      <div className="flex flex--direction-column flex--align-center register-form">
        {/* <div>
          <label htmlFor="admin-duel-battleId">
            <strong>Battle ID</strong> {"("}
            Do<strong> not</strong> change. Admin use only{")"}
            <br /> Refresh the page if you accidently change this value.
          </label>
          <input
            id="admin-duel-battleId"
            className="story-title-input"
            value={battleId}
            onChange={(event) => onBattleIdChange(event.target.value)}
          />
        </div> */}
        <h2
          style={nightMode ? nightModeStyleWhite : {}}
          className="heading-secondary center-text margin-bottom-sm"
        >
          {!headerMessage ? "Welcome!" : headerMessage}
        </h2>
        {isInitialized && numberOfReservedDuels > 0 ? (
          <p className="description">
            # of remaining Reserved Duels: {numberOfReservedDuels.toString(10)}
          </p>
        ) : null}
        {numberOfOpenDuels > 0 ? (
          <p className="description">
            # of available Open Duels: {numberOfOpenDuels.toString(10)}
          </p>
        ) : numberOfOpenDuels === 0 && numberOfReservedDuels === 0 ? (
          <p className="description">
            There doesn't seem to be any Duels available that you could judge
            right now.
          </p>
        ) : numberOfReservedDuels <= 0 &&
          numberOfOpenDuels === -1 &&
          !initialDuelId ? (
          <LoadingSection nightMode={nightMode} />
        ) : null}

        <p className="description center-text max-width--readable-center">
          {message}
        </p>
        {!initialDuelId &&
        isInitialized &&
        (numberOfOpenDuels > 0 || numberOfReservedDuels > 0) ? (
          <button
            className="btn btn--register min-width--larger story-submission-btn"
            disabled={isWorking || duelId || !battleId}
            onClick={
              judgeType === JUDGING_ADMIN_JUDGE
                ? () => {
                    onFindNextDuelAdmin();
                  }
                : () => {
                    onGetNextDuel(false);
                  }
            }
          >
            Judge Duel
          </button>
        ) : null}
        {judgeType && judgeType === JUDGING_ADMIN_JUDGE ? (
          <>
            <div>
              <label htmlFor="admin-duel-duelId">
                <strong>Duel ID</strong> {"("}
                Do<strong> not</strong> change manually{")"}
                <br />
                {!initialDuelId
                  ? "Click 'Find Next Duel' to populate this field."
                  : ""}
              </label>
              <input
                id="admin-duel-duelId"
                className="story-title-input"
                value={duelId}
                onChange={onDuelIdChange}
                disabled={initialDuelId}
              />
            </div>
            <button
              className="btn btn--register min-width--larger story-submission-btn"
              disabled={isWorking || !duelId}
              onClick={onDownloadDuel}
            >
              Load Duel
            </button>
          </>
        ) : null}
      </div>
    </div>
  );
}

export default GetDuel;
