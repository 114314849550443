import Lottie from "react-lottie-player";
import loadingAnimation from "../img/animations/loadinganimation.json";
import { nightModeStyleWhite } from "../publicFunctions/publicHelpers/helpers";

function LoadingSection({ nightMode }) {
  return (
    <div className="container">
      <div className="lottie-wrapper fade-in--quick">
        <h2
          style={nightMode ? nightModeStyleWhite : {}}
          className="heading-secondary center-text"
        >
          Loading...
        </h2>
        <div className="center-flex">
          <Lottie
            loop
            animationData={loadingAnimation}
            play
            style={{ width: 150, height: 150 }}
          ></Lottie>
        </div>
      </div>
    </div>
  );
}

export default LoadingSection;
