import { useEffect, useState } from "react";
import { getCardImageUrlByCard } from "../../publicFunctions/publicHelpers/imageUrlBuilder";
import SubCardSelector from "./SubCardSelector";

function CardSelector({
  collectionMeta,
  myCards,
  requestCollectionMeta,
  requestMyCards,
  selectedCardHandler,
  myProfile,
  neverShowUnownedCards = false,
  genreFirst = false,
  hideDisplayCard = false,
  promptSelecting = false,
  nightMode,
}) {
  const [renderedSelector, setRenderedSelector] = useState(null);

  useEffect(() => {
    if (!collectionMeta) {
      requestCollectionMeta();
    }
  }, [collectionMeta]);

  useEffect(() => {
    if (myProfile?.userId && !myCards) {
      requestMyCards(myProfile.userId);
    }
  }, [myCards, myProfile]);

  // here's our render function
  useEffect(() => {
    if (collectionMeta?.cardGroups?.length && myCards) {
      // even if the user doesn't have myCards yet, it will be an empty map
      const freebieMap = new Map();
      for (let i = 0; i < collectionMeta.freebies.length; i++) {
        freebieMap.set(collectionMeta.freebies[i], true);
      }

      const tempRender = [];
      for (let i = 0; i < collectionMeta.cardGroups.length; i++) {
        tempRender.push(
          <SubCardSelector
            key={collectionMeta.cardGroups[i].groupName + "_subGroup"}
            subCollection={collectionMeta.cardGroups[i]}
            myCards={myCards}
            showOnlyOwnedCards={neverShowUnownedCards}
            selectedCardHandler={selectedCardHandler}
            hideDisplayCard={hideDisplayCard}
            promptSelecting={promptSelecting}
            isFreebie={
              freebieMap.has(collectionMeta.cardGroups[i].groupName)
                ? true
                : false
            }
            collectionMeta={collectionMeta}
            nightMode={nightMode}
          />
        );
      }

      setRenderedSelector(
        <div className="flex flex--direction-column flex--gap-normal">
          {tempRender}
        </div>
      );
    }
  }, [myCards, collectionMeta, neverShowUnownedCards, nightMode]);

  return (
    <div
      className={
        "margin-auto" + (promptSelecting ? " card-selector-wrapper" : null)
      }
    >
      {renderedSelector}
    </div>
  );
}

export default CardSelector;
