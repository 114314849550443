import { nightModeStyleWhite } from "../../publicFunctions/publicHelpers/helpers";

function DebriefBodyNav({
  selectPageHandler,
  selectedPage,
  showFinalShowdown,
  showHouses,
  showFeedback,
  isTavern,
  nightMode,
}) {
  const selectableItemClass =
    "heading-tertiary debrief-nav-item margin-bottom-sm" +
    (nightMode ? " white" : " black");

  const selectedClass =
    selectableItemClass +
    " debrief-nav-item--selected" +
    (nightMode ? " selected-light" : " selected-dark");

  return (
    <div className="container debrief-body-nav">
      <div
        tabIndex={0}
        role="button"
        onClick={() => {
          selectPageHandler("stories");
        }}
      >
        <p
          className={
            selectedPage === "stories" ? selectedClass : selectableItemClass
          }
        >
          Stories
        </p>
      </div>
      {showHouses ? (
        <div
          tabIndex={0}
          role="button"
          onClick={() => {
            selectPageHandler("houses");
          }}
        >
          <p
            className={
              selectedPage === "houses" ? selectedClass : selectableItemClass
            }
          >
            Houses
          </p>
        </div>
      ) : null}
      {showFinalShowdown ? (
        <div
          tabIndex={0}
          role="button"
          onClick={() => {
            selectPageHandler("finalShowdown");
          }}
        >
          <p
            className={
              selectedPage === "finalShowdown"
                ? selectedClass
                : selectableItemClass
            }
          >
            Final Showdown
          </p>
        </div>
      ) : null}
      {showFeedback ? (
        <div
          tabIndex={0}
          role="button"
          onClick={() => {
            selectPageHandler("feedback");
          }}
        >
          <p
            className={
              selectedPage === "feedback" ? selectedClass : selectableItemClass
            }
          >
            Feedback
          </p>
        </div>
      ) : null}
      {isTavern ? (
        <div
          tabIndex={0}
          role="button"
          onClick={() => {
            selectPageHandler("create");
          }}
        >
          <p
            className={
              selectedPage === "create" ? selectedClass : selectableItemClass
            }
          >
            Create / Update
          </p>
        </div>
      ) : null}
      {isTavern ? (
        <div
          tabIndex={0}
          role="button"
          onClick={() => {
            selectPageHandler("collection");
          }}
        >
          <p
            className={
              selectedPage === "collection"
                ? selectedClass
                : selectableItemClass
            }
          >
            Collection
          </p>
        </div>
      ) : null}
      {isTavern ? (
        <div
          tabIndex={0}
          role="button"
          onClick={() => {
            selectPageHandler("generator");
          }}
        >
          <p
            className={
              selectedPage === "generator" ? selectedClass : selectableItemClass
            }
          >
            Prompt Generator
          </p>
        </div>
      ) : null}
      {isTavern ? (
        <div
          tabIndex={0}
          role="button"
          onClick={() => {
            selectPageHandler("raids");
          }}
        >
          <p
            className={
              selectedPage === "raids" ? selectedClass : selectableItemClass
            }
          >
            Raids
          </p>
        </div>
      ) : null}
    </div>
  );
}

export default DebriefBodyNav;
