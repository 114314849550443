import { useState } from "react";
import Lottie from "react-lottie-player";
import loadingAnimation from "../../img/animations/simpleLoading.json";
import { IonIcon } from "@ionic/react";
import { closeCircle, checkmarkCircleOutline } from "ionicons/icons";
import { lockInGenre } from "../../config/firebase";
import {
  nightModeStyleLessDark,
  nightModeStyleWhite,
} from "../../publicFunctions/publicHelpers/helpers";

function GenreLockIn({ battleId, refreshGameState, nightMode }) {
  const [errorMessage, setErrorMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showYouSure, setShowYouSure] = useState(false);

  const onSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    setShowYouSure(true);
    setErrorMessage(
      "Hey! If you proceed then you will not be able to redraw your Genre. Are you sure?"
    );
  };

  return (
    <div
      style={nightMode ? nightModeStyleLessDark : {}}
      className="create-topic"
    >
      <h2
        style={nightMode ? nightModeStyleWhite : {}}
        className="heading-secondary center-text margin--none"
      >
        Lock-In Genre
      </h2>
      <br />
      <p className="description--small center-text margin-bottom-md">
        In order to sign up or see the Beta-Read list, you must lock in your
        genre.
        <br />
        Click the button below to lock in your genre.
      </p>
      <p className="description--smaller center-text">
        Note: This will set your Genre Draws to zero, but will not technically
        count as a "draw." So you would still be eligable for the no-redraw
        trophy. If you still have regular draws, then those will not be depleted
        and you could still redraw those cards.
      </p>
      <br />
      <br />
      <form className="register-form" onSubmit={onSubmit}>
        {errorMessage ? (
          <p
            style={nightMode ? nightModeStyleWhite : {}}
            className="description error-text"
          >
            {errorMessage}
          </p>
        ) : null}
        {showYouSure ? (
          <div className="flex justify-content--center">
            <div className="redraw--youSure">
              <p
                style={nightMode ? nightModeStyleWhite : {}}
                className="description"
              >
                Are you sure?
              </p>
              <IonIcon
                onClick={() => {
                  setShowYouSure(false);
                  lockInGenre({ battleId })
                    .then((result) => {
                      if (result.data.success) {
                        setErrorMessage("Success!");
                        refreshGameState();
                      } else {
                        setErrorMessage(
                          "Hmmm... that didn't seem to work. Please try again in a few minutes. If this continues then please email admin@writingbattle.com"
                        );
                      }
                    })
                    .catch((err) => {
                      setErrorMessage(err.message);
                    })
                    .finally(() => {
                      setIsSubmitting(false);
                    });
                }}
                className="im-sure"
                icon={checkmarkCircleOutline}
              />
              <IonIcon
                onClick={() => {
                  setShowYouSure(false);
                  setIsSubmitting(false);
                }}
                className="not-sure"
                icon={closeCircle}
              />
            </div>
          </div>
        ) : null}
        <div className="flex justify-content--center">
          <button
            className="btn btn--register max-width--larger min-width--larger"
            disabled={isSubmitting}
          >
            Lock-In
            <div className={isSubmitting ? "simple-loader" : "hidden"}>
              <Lottie
                loop
                animationData={loadingAnimation}
                speed={1.5}
                play
              ></Lottie>
            </div>
          </button>
        </div>
      </form>
    </div>
  );
}

export default GenreLockIn;
