import { useEffect, useState } from "react";
import BattleDate from "./BattleDate";
import { getBattleSwatchByBattleId } from "../../publicFunctions/publicHelpers/homeHelper";
import {
  getSuperSimpleEst,
  getSuperSimpleEstAndYear,
} from "../../publicFunctions/publicHelpers/helpers";

function BattleDatesList({ battles, showFewer }) {
  const [renderedDates, setRenderedDates] = useState(null);

  const getDateText = (initialDate) => {
    return getSuperSimpleEst(initialDate);
  };

  useEffect(() => {
    if (battles?.length) {
      const alreadyShown = new Map();
      const tempRenderedDates = [];
      const countMax = showFewer ? 4 : battles.length;
      let count = 0;
      const now = new Date();
      for (let i = 0; i < battles.length && count < countMax; i++) {
        const battle = battles[i];
        if (
          !battle?.initialDate ||
          !battle?.lockInDate ||
          !battle.battleId ||
          alreadyShown.has(battle.battleId.substring(0, 3))
        ) {
          continue;
        }
        if (battle.lockInDate.getTime() < now.getTime()) {
          continue;
        }
        count++;
        alreadyShown.set(battle.battleId.substring(0, 3), true);
        const battleSwatch = getBattleSwatchByBattleId(battle.battleId);
        const image = battleSwatch.quarterLandscape;
        const dateText = getDateText(battle.initialDate);
        const index = i.toString(10) + "_date";
        tempRenderedDates.push(
          <BattleDate
            key={index}
            index={index}
            image={image}
            dateText={dateText}
            yearText={battle.initialDate.getFullYear().toString(10)}
            battleId={battle.battleId}
          />
        );
      }
      setRenderedDates(tempRenderedDates);
    }
  }, [battles]);

  return <div className="battles-dates-list">{renderedDates}</div>;
}

export default BattleDatesList;
