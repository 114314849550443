import { nightModeStyleWhite } from "../../publicFunctions/publicHelpers/helpers";
import Reveal from "../animations/Reveal";
import JudgeCard from "./JudgeCard";

function MVWSection({ nightMode }) {
  return (
    <>
      <div className="container center-text">
        <Reveal type="fromBottom" rotate={true}>
          <span className={"subheading" + (nightMode ? " blue" : "")}>
            Mo Battles, Mo Money
          </span>
        </Reveal>
        <Reveal type="fromBottom" rotate={true}>
          <h2
            style={nightMode ? nightModeStyleWhite : {}}
            className="heading-secondary margin-bottom-md"
          >
            MVW 2024
          </h2>
        </Reveal>
      </div>
      <div className="container">
        <Reveal type="fromBottom" fadeIn={true}>
          <div className="flex flex--direction-column">
            <div className="mvw-grid">
              <JudgeCard
                cardImage={
                  "https://firebasestorage.googleapis.com/v0/b/writing-battle.appspot.com/o/pastWinners%2Fnov2023%2Fnick_on_card.webp?alt=media"
                }
                judgeName={"Nick Smith"}
                accoladeImage={
                  "https://firebasestorage.googleapis.com/v0/b/writing-battle.appspot.com/o/trophyImages%2Fmvw_c.png?alt=media"
                }
                goToType={"Story"}
                goToLink={
                  "https://writingbattle.com/past-winners?year=2023&battle=Autumn&featured=2"
                }
                accolade1={"Writing Battle MVW 2024"}
                accolade2={"2023 Short Story Winner"}
                learnMoreLink={
                  "https://writingbattle.com/past-winners?year=2023&battle=Autumn&featured=2"
                }
                isMVW={true}
              />
              <p
                style={nightMode ? nightModeStyleWhite : {}}
                className="description mvw-description align-self--center"
              >
                Nick Smith won $1,000 cash by earning the most points across the
                Battles in 2024.
                <br />
                <br />
                Writers accumulate points depending on how well their stories do
                in each Battle. We sum up each writer's best five Battles in a
                Calendar year. For the 2025 Battles, the "MVW" cash prize is
                $2,000 USD!
              </p>
            </div>
          </div>
        </Reveal>
      </div>
    </>
  );
}

export default MVWSection;
