import { useEffect, useState } from "react";
import {
  informationCircleOutline,
  caretForwardOutline,
  caretDownOutline,
} from "ionicons/icons";
import { IonIcon } from "@ionic/react";
import Lottie from "react-lottie-player";
import loadingAnimation from "../../img/animations/simpleLoading.json";
import LoadingSection from "../LoadingSection";
import GenreLockIn from "./GenreLockIn";
import {
  getBetaReadObject,
  setBetaRead,
} from "../../publicFunctions/forumHandler";
import {
  nightModeStyle,
  nightModeStyleLessDark,
  nightModeStyleWhite,
} from "../../publicFunctions/publicHelpers/helpers";

const WRITING_BATTLE = "Writing Battle";

function BetaReadSignUp({
  userInfo,
  battleState,
  gameState,
  refreshGameState,
  handleForceRefresh,
  nightMode,
}) {
  const [isLoading, setIsLoading] = useState(true);
  const [name, setName] = useState("");
  const [contest, setContest] = useState("");
  const [email, setEmail] = useState("");
  const [timezone, setTimezone] = useState("");
  const [genre, setGenre] = useState("");
  const [feedbackType, setFeedbackType] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [betaReadObject, setBetaReadObject] = useState(null);
  const [contestAndGenreEnabled, setContestAndGenreEnabled] = useState(true);
  const [isExpanded, setIsExpanded] = useState(false);

  console.log(battleState);
  console.log(gameState);

  // battleState can be loading, disabled_genre, disabled_writing, enabled_default, or enabled_writing

  useEffect(() => {
    if (userInfo?.isInitialized() && gameState) {
      const notInGame = gameState ? false : true;
      getBetaReadObject(userInfo.userId)
        .then((result) => {
          if (result) {
            console.log(result);
            setBetaReadObject(result);
            if (
              !result.battleGenre &&
              result.deleted &&
              result.contest === WRITING_BATTLE &&
              notInGame
            ) {
              setGenre("");
              setContest("");
            } else if (
              result.battleGenre &&
              !notInGame &&
              result.battleGenre.includes(gameState.battleId)
            ) {
              console.log("here1111");
              setGenre(result.genre);
              setContest(WRITING_BATTLE);
            } else {
              setGenre(result.genre);
              setContest(result.contest);
            }
            setName(result.name);
            setEmail(result.email);
            setTimezone(result.timezone);
            setFeedbackType(result.feedbackType);
          }
        })
        .catch((err) => {
          console.log("here");
          console.log(err);
        });
    }
  }, [userInfo, gameState]);

  useEffect(() => {
    if (battleState !== "loading") {
      setIsLoading(false);
      if (battleState === "enabled_writing") {
        setContestAndGenreEnabled(false);
        setContest(WRITING_BATTLE);
        // check gamestate
        let tempGenre = "";
        if (gameState?.cards?.genre?.text) {
          tempGenre = gameState.cards.genre.text;
        }
        setGenre(tempGenre);
      }
    }
  }, [battleState, gameState]);

  const settingBetaReadListing = (deleted, successMessage) => {
    setIsSubmitting(true);
    const userId = userInfo.userId;
    const battleId = gameState?.battleId ? gameState.battleId : "";
    setBetaRead(
      battleId,
      userId,
      name,
      email,
      contest,
      timezone,
      genre,
      feedbackType,
      deleted
    )
      .then(() => {
        setErrorMessage(successMessage);
        setTimeout(() => {
          handleForceRefresh();
          if (deleted) {
            setBetaReadObject(null);
          } else {
            setBetaReadObject({ deleted: false });
          }
        }, 500);
      })
      .catch((err) => {
        setErrorMessage(
          "Hmmm... something went wrong. Please try again in a few minutes or contact admin@writingbattle.com"
        );
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    settingBetaReadListing(false, "Success!");
  };

  // battleState = "disabled_genre";

  if (isLoading) {
    return <LoadingSection nightMode={nightMode} />;
  } else if (battleState === "disabled_genre") {
    return (
      <GenreLockIn
        battleId={gameState?.battleId ? gameState.battleId : ""}
        refreshGameState={refreshGameState}
        nightMode={nightMode}
      />
    );
  } else if (battleState === "disabled_writing") {
    return (
      <p className="description center-text">
        The Beta-Read List is disabled for those that have not joined the Battle
        if one is currently in progress.
      </p>
    );
  }

  return (
    <div
      style={nightMode ? nightModeStyleLessDark : {}}
      className="create-topic"
    >
      <div
        role="button"
        onClick={(event) => {
          event.preventDefault();
          setIsExpanded(!isExpanded);
        }}
        className={
          "flex justify-content--center align-items--center flex--gap-normal expand-outline" +
          (nightMode ? " expand-outline-dark" : "")
        }
      >
        <IonIcon
          style={nightMode ? nightModeStyleWhite : {}}
          className="battle-icon--small"
          icon={isExpanded ? caretDownOutline : caretForwardOutline}
        ></IonIcon>
        <p className="description--larger bold center-text margin--none">
          Click to Open Sign Up Form
        </p>
      </div>
      {isExpanded ? (
        <>
          <br />
          <p className="description--small center-text margin-bottom-md">
            Listings are automatically removed after five days.
            <br />
            During a Peer-Judged Battle, you will only be able to see WB
            listings and only in your own genre.
          </p>
          {betaReadObject && !betaReadObject.deleted ? (
            <div className="flex justify-content--end margin-bottom-md">
              <button
                className="btn btn--lightRed max-width--larger min-width--larger"
                disabled={isSubmitting}
                onClick={(event) => {
                  event.preventDefault();
                  settingBetaReadListing(true, "Successfully removed listing");
                }}
              >
                Remove My Listing
                <div className={isSubmitting ? "simple-loader" : "hidden"}>
                  <Lottie
                    loop
                    animationData={loadingAnimation}
                    speed={1.5}
                    play
                  ></Lottie>
                </div>
              </button>
            </div>
          ) : null}
          <form className="register-form" onSubmit={onSubmit}>
            <div className="flex flex--gap-small">
              <div>
                <label htmlFor="firstNameBeta">First Name</label>
                <input
                  style={nightMode ? nightModeStyle : {}}
                  id="firstNameBeta"
                  type="text"
                  placeholder="Jane"
                  required
                  disabled={isSubmitting}
                  maxLength={50}
                  value={name}
                  onChange={(event) => {
                    setName(event.target.value);
                  }}
                />
              </div>
              <div>
                <label htmlFor="emailBeta">Email</label>
                <input
                  style={nightMode ? nightModeStyle : {}}
                  id="emailBeta"
                  type="email"
                  placeholder="me@example.com"
                  maxLength={75}
                  required
                  disabled={isSubmitting}
                  value={email}
                  onChange={(event) => {
                    setEmail(event.target.value);
                  }}
                />
              </div>
            </div>
            <div>
              <label htmlFor="contestBeta">Contest / Personal Project</label>
              <input
                style={nightMode ? nightModeStyle : {}}
                id="contestBeta"
                type="text"
                placeholder="Which contest is this for?"
                required
                disabled={isSubmitting || !contestAndGenreEnabled}
                maxLength={75}
                value={contestAndGenreEnabled ? contest : WRITING_BATTLE}
                onChange={(event) => {
                  if (contestAndGenreEnabled) {
                    setContest(event.target.value);
                  }
                }}
              />
            </div>
            <div className="flex flex--gap-small">
              <div>
                <label htmlFor="genreBeta">Genre</label>
                <input
                  style={nightMode ? nightModeStyle : {}}
                  id="genreBeta"
                  type="text"
                  placeholder="Sci-Fi"
                  required
                  disabled={isSubmitting || !contestAndGenreEnabled}
                  maxLength={100}
                  value={genre}
                  onChange={(event) => {
                    setGenre(event.target.value);
                  }}
                />
              </div>
              <div>
                <label htmlFor="timezoneBeta">Timezone</label>
                <input
                  style={nightMode ? nightModeStyle : {}}
                  id="timezoneBeta"
                  type="text"
                  placeholder="ET"
                  required
                  disabled={isSubmitting}
                  maxLength={25}
                  value={timezone}
                  onChange={(event) => {
                    setTimezone(event.target.value);
                  }}
                />
              </div>
            </div>
            <div>
              <label htmlFor="feedbackTypeBeta">
                What type of feedback do you need?
              </label>
              <input
                style={nightMode ? nightModeStyle : {}}
                id="feedbackTypeBeta"
                type="text"
                placeholder="Describe the type of feedback you would like"
                required
                disabled={isSubmitting}
                maxLength={130}
                value={feedbackType}
                onChange={(event) => {
                  setFeedbackType(event.target.value);
                }}
              />
            </div>
            {errorMessage ? (
              <p className="description error-text">{errorMessage}</p>
            ) : null}
            <div className="flex justify-content--center">
              <button
                className="btn btn--register max-width--larger min-width--larger"
                disabled={isSubmitting}
              >
                {!betaReadObject || betaReadObject?.deleted
                  ? "Submit"
                  : "Update My Listing"}
                <div className={isSubmitting ? "simple-loader" : "hidden"}>
                  <Lottie
                    loop
                    animationData={loadingAnimation}
                    speed={1.5}
                    play
                  ></Lottie>
                </div>
              </button>
            </div>
          </form>
          <div className="grid-info margin-top-bottom-sm flex--gap-smaller">
            <div className="justify-self--end">
              <IonIcon
                style={nightMode ? nightModeStyleWhite : {}}
                className="battle-icon"
                icon={informationCircleOutline}
              />
            </div>
            <p className="description">
              <span className="italic">What's a Beta-Read?</span>
              <br />
              Have you ever had someone proof read your writing before
              submitting it somewhere? Then you have already participated in a
              "Beta-Read!" Writers tend to swap stories to get a fresh set of
              eyes on their own work and reciprocate the favour. This form is
              for writers to list their email so they may connect with other
              writers to Beta-Read.
            </p>
          </div>
        </>
      ) : null}
    </div>
  );
}

export default BetaReadSignUp;
