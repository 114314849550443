import { useEffect, useState } from "react";
import Lottie from "react-lottie-player";
import loadingAnimation from "../../img/animations/loadinganimation.json";
import { reportStory } from "../../config/firebase";
import {
  nightModeStyle,
  nightModeStyleWhite,
} from "../../publicFunctions/publicHelpers/helpers";

function ReportStory({
  battleId,
  loadedDuel,
  reportNotes,
  refreshMockState,
  nightMode,
}) {
  const [isWorking, setIsWorking] = useState(false);
  const [submitFormCollapsed, setSubmitFormCollapsed] = useState(true);
  const [storyTitle, setStoryTitle] = useState("");
  const [reportReason, setReportReason] = useState("");
  const [message, setMessage] = useState(
    "Please fill out the story title and reason that " +
      "you this story should be disqualified."
  );

  console.log(loadedDuel);

  useEffect(() => {
    if (reportReason && !storyTitle) {
      setMessage("Please write the story title in the top text input.");
    } else if (!reportReason && storyTitle) {
      setMessage(
        "Please write the reason that you believe this story should be disqualified."
      );
    } else if (message && reportReason && storyTitle) {
      setMessage("");
    }
  }, [storyTitle, reportReason]);

  const handleReportStory = async () => {
    let judgeReportString = "";
    if (storyTitle) {
      judgeReportString += storyTitle + " ~~~~ ";
    }
    if (reportReason) {
      judgeReportString += reportReason;
    }
    setIsWorking(true);
    const duelId = loadedDuel.duelId;
    try {
      const { data } = await reportStory({
        battleId,
        duelId,
        judgeNote: judgeReportString,
      });
      if (data?.success) {
        refreshMockState();
      } else {
        if (data?.message) {
          setMessage(data.message);
        } else {
          setMessage("Hmmmm... something went wrong. Please try again.");
        }
        setIsWorking(false);
      }
    } catch (err) {
      if (err?.message) {
        console.log(err.message);
      }
      setMessage("Hmmmm... something went wrong. Please try again.");
      setIsWorking(false);
    }
  };

  return (
    <div
      style={nightMode ? nightModeStyleWhite : {}}
      className={
        "flex justify-content--center align-items--center " +
        "flex--direction-column border-thin margin-bottom-larger" +
        (nightMode ? " border-thin--white" : "")
      }
    >
      {reportNotes ? (
        <p className="description--small max-width--readable-center white-space center-text">
          <u>YOUR REPORT:</u>
          <br />
          {reportNotes.judgeNote ? reportNotes.judgeNote : ""}
          <br />
          <br />
          <span className="bold">
            <u>ADMIN RESPONSE:</u>
            <br />
            {reportNotes.adminNote ? reportNotes.adminNote : ""}
          </span>
          <br />
          <br />
        </p>
      ) : null}
      {submitFormCollapsed ? (
        <>
          <p className="description center-text bold">
            Do you believe a story in this Duel should be disqualified?
          </p>
          <button
            className="btn btn--full btn--full--spring min-width--larger"
            disabled={isWorking || !loadedDuel}
            onClick={() => {
              setSubmitFormCollapsed(false);
            }}
          >
            Report a Story
          </button>
        </>
      ) : (
        <div className="register-form">
          <div className="flex justify-content--center flex--gap-normal">
            <h2
              style={nightMode ? nightModeStyleWhite : {}}
              className="heading-secondary center-text margin-bottom-sm"
            >
              Report Story
            </h2>
            <p
              onClick={() => {
                setSubmitFormCollapsed(true);
              }}
              className="description underline align-self--end cursor--pointer"
            >
              Cancel
            </p>
          </div>
          <div className="flex flex--direction-column">
            <label htmlFor="report-story-title">
              <strong>(*) Which story did you want to report?</strong>
            </label>
            <input
              style={nightMode ? nightModeStyle : {}}
              id="report-story-title"
              className="story-title-input"
              value={storyTitle}
              onChange={(event) => {
                setStoryTitle(event.target.value);
              }}
              disabled={isWorking}
              placeholder="STORY TITLE"
            />
          </div>
          <div className="flex flex--direction-column">
            <label htmlFor="report-reason">
              <strong>
                (*) Detailed reason why the story should be disqualified.
              </strong>
            </label>
            <textarea
              style={nightMode ? nightModeStyle : {}}
              id="report-reason"
              className="feedback-text-area text-area--resize-vertical"
              value={reportReason}
              disabled={isWorking}
              onChange={(event) => {
                setReportReason(event.target.value);
              }}
              placeholder="Why should this story be disqualified?"
            />
            {isWorking ? (
              <>
                <p className="description center-text">One sec...</p>
                <div className="center-flex flex justify-content--center">
                  <Lottie
                    loop
                    animationData={loadingAnimation}
                    play
                    style={{ width: "5rem", height: "5rem" }}
                  ></Lottie>
                </div>
              </>
            ) : (
              <p className="description--small max-width--readable-center--smaller center-text">
                {message}
              </p>
            )}
            <button
              className="btn btn--full btn--full--spring min-width--larger align-self--end margin-top-bottom-sm"
              disabled={isWorking || !reportReason || !storyTitle}
              onClick={handleReportStory}
            >
              Send Report
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default ReportStory;
