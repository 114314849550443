import { useEffect, useState } from "react";
import { nightModeStyleWhite } from "../../publicFunctions/publicHelpers/helpers";

function GenreDefinitions({ definitionDoc, battleInfo, nightMode }) {
  const [renderedDefinitions, setRenderedDefinitions] = useState(null);

  useEffect(() => {
    if (definitionDoc && battleInfo?.rawTuple?.genres?.length) {
      const tempRender = [];
      for (let i = 0; i < battleInfo.rawTuple.genres.length; i++) {
        const genre = battleInfo.rawTuple.genres[i];
        if (definitionDoc[genre]) {
          const def = definitionDoc[genre];
          tempRender.push(
            <p
              key={i.toString(10) + "_" + genre}
              className="description center-text max-width--readable-center--larger"
            >
              <span className="bold">{genre}</span>: {def}
              <br />
              <br />
            </p>
          );
        }
      }
      if (tempRender?.length) {
        setRenderedDefinitions(
          <div className="flex flex--direction-column">{tempRender}</div>
        );
      } else {
        setRenderedDefinitions(null);
      }
    } else {
      setRenderedDefinitions(null);
    }
  }, [definitionDoc, battleInfo]);

  if (!renderedDefinitions) {
    return null;
  }
  return (
    <div
      style={nightMode ? nightModeStyleWhite : {}}
      className="padding-top-bot--smaller border-bottom-thin--blue padding-left-right-small"
    >
      <h2
        style={nightMode ? nightModeStyleWhite : {}}
        className="heading-secondary center-text margin--none"
      >
        Genre Definitions
        <br />
        <br />
      </h2>
      {renderedDefinitions}
    </div>
  );
}

export default GenreDefinitions;
