import { IonIcon } from "@ionic/react";
import {
  calendarOutline,
  cashOutline,
  bookmarkOutline,
  happyOutline,
  ribbonOutline,
} from "ionicons/icons";
import { useNavigate } from "react-router-dom";
import { getBattleSwatchByBattleId } from "../publicFunctions/publicHelpers/homeHelper";
import { useState } from "react";
import { nightModeStyleWhite } from "../publicFunctions/publicHelpers/helpers";

function Pricing({
  className,
  battleInfo,
  openLogInHandler,
  isLoggedIn,
  nextStyle,
  nightMode,
}) {
  const [hover, setHover] = useState(false);
  const navigate = useNavigate();
  const colorSwatch = getBattleSwatchByBattleId(battleInfo.rawTuple.battleId);
  return (
    <div
      style={{
        overflow: "hidden",
        backgroundSize: "auto 18%",
        backgroundRepeat: "no-repeat",
        backgroundImage: `url(${
          getBattleSwatchByBattleId(battleInfo.rawTuple.battleId).halfLandscape
        })`,
        backgroundPosition: "top left",
      }}
      className={"pricing-battle " + className}
    >
      <header className="battle-header">
        <div className="pricing-name-and-logo">
          <div></div>
          <p
            style={{ color: "black" }}
            className="heading-tertiary bold margin-bottom-sm white-shadow--huge"
          >
            {battleInfo.battleTitle}
          </p>
        </div>
        <p
          style={nightMode && nextStyle ? nightModeStyleWhite : {}}
          className="battle-description-text bold"
        >
          {battleInfo.shortDescription}
        </p>
        <div
          style={nightMode && nextStyle ? nightModeStyleWhite : {}}
          className="pricing-dollar-and-amount"
        >
          <p
            style={nightMode && nextStyle ? nightModeStyleWhite : {}}
            className="dollar"
          >
            $
          </p>
          <p
            style={nightMode && nextStyle ? nightModeStyleWhite : {}}
            className="battle-price"
          >
            {battleInfo.currentPrice}
          </p>
          <p
            style={nightMode && nextStyle ? nightModeStyleWhite : {}}
            className="percent-off"
          >
            {battleInfo.percentOff === "0"
              ? "USD"
              : "(" + battleInfo.percentOff + "% off)"}
          </p>
        </div>
        <p
          style={nightMode && nextStyle ? nightModeStyleWhite : {}}
          className="pricing-discount"
        >
          {battleInfo.percentOff === "0"
            ? "Price includes all applicable taxes"
            : "Entry fee increases to $" +
              battleInfo.finalPrice +
              " on " +
              battleInfo.earlyEntryDeadline}
        </p>
      </header>
      <ul className="list">
        <li className="list-item">
          <IonIcon className="list-icon" icon={calendarOutline} />
          <span>
            Begins <strong>{battleInfo.startDate}</strong>
          </span>
        </li>
        <li className="list-item">
          <IonIcon
            className="list-icon"
            icon={battleInfo.peerJudged ? happyOutline : ribbonOutline}
          />
          <span>
            {battleInfo.peerJudged ? "Peer Judged" : "Professionally Judged"}
          </span>
        </li>
        <li className="list-item">
          <IonIcon className="list-icon" icon={cashOutline} />
          <span>{battleInfo.cashText + " cash prize pool"}</span>
        </li>
        <li className="list-item">
          <IonIcon className="list-icon" icon={bookmarkOutline} />
          <span>Winners are published</span>
        </li>
      </ul>
      <div className="battle-sign-up">
        <button
          className={
            "btn btn" + (className.includes("next") ? "--outline" : "--full")
          }
          onMouseEnter={() => {
            setHover(true);
          }}
          onMouseLeave={() => {
            setHover(false);
          }}
          onClick={
            !isLoggedIn
              ? () => {
                  navigate(
                    "/join-battle?autoSelectBattleId=" +
                      battleInfo.rawTuple.battleId
                  );
                }
              : () =>
                  navigate(
                    "/join-battle?autoSelectBattleId=" +
                      battleInfo.rawTuple.battleId
                  )
          }
          style={{
            backgroundColor: hover
              ? colorSwatch.colorDarkest
              : colorSwatch.colorDark,
            color: "#010101",
          }}
        >
          Register
        </button>
      </div>
    </div>
  );
}

export default Pricing;
