import { useEffect, useState } from "react";
import { constructTarotUrl } from "../../publicFunctions/miscHandler";
import {
  nightModeStyle,
  nightModeStyleWhite,
} from "../../publicFunctions/publicHelpers/helpers";

function TarotMeaningsList({ list, orientation, nightMode }) {
  const [renderedList, setRenderedList] = useState([]);

  useEffect(() => {
    if (list?.length) {
      const tempRender = [];

      for (let i = 0; i < list.length; i++) {
        const tarotObj = list[i];
        const index = tarotObj.i;
        const imgUrl = constructTarotUrl(index, true);
        tempRender.push(
          <div
            style={nightMode ? nightModeStyleWhite : {}}
            className="tarot-meanings-list"
            key={index.toString(10) + "_meaning"}
          >
            <div className="flex justify-content--end">
              <img className="small-tarot" src={imgUrl} />
            </div>
            <div className="flex flex--direction-column">
              <p className="description--larger bold">
                {tarotObj.name +
                  (orientation ? (tarotObj.reversed ? " (REVERSED)" : "") : "")}
              </p>
              {orientation ? (
                <p className="description white">
                  {tarotObj.reversed ? tarotObj.r : tarotObj.u}
                </p>
              ) : (
                <>
                  <p className="description">
                    Upright:{" "}
                    <span className="description--small">{tarotObj.u}</span>
                  </p>
                  <p className="description">
                    Reversed:{" "}
                    <span className="description--small">{tarotObj.r}</span>
                  </p>
                </>
              )}{" "}
            </div>
          </div>
        );
      }

      setRenderedList(tempRender);
    } else {
      setRenderedList(null);
    }
  }, [list, nightMode]);

  return (
    <div
      style={nightMode ? nightModeStyle : {}}
      className={"container tarot-meanings-list-container"}
    >
      {renderedList}
    </div>
  );
}

export default TarotMeaningsList;
