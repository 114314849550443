import { IonIcon } from "@ionic/react";
import {
  pencilOutline,
  bookOutline,
  paperPlaneOutline,
  trophyOutline,
} from "ionicons/icons";
import GlanceContainer from "./GlanceContainer";

function AtAGlance({
  initDate,
  submitDate,
  firstDuelDate,
  lastDuelDate,
  winnersDate,
  maxWords,
  peerJudged,
  nightMode,
}) {
  return (
    <div className="glance-section">
      <div className="flex margin-auto justify--content-center">
        <div
          className={
            "container grid " +
            (peerJudged ? "grid--4-cols " : "grid--3-cols ") +
            "grid--center-h grid--glance"
          }
        >
          <GlanceContainer
            icon={pencilOutline}
            title="Start Writing"
            dates={[initDate]}
            isOdd={true}
            description="Receive and redraw your prompts, and start writing"
            nightMode={nightMode}
          />
          <GlanceContainer
            icon={paperPlaneOutline}
            title="Submit by"
            dates={[submitDate]}
            isOdd={false}
            description={"Submit an up-to " + maxWords + " word short story"}
            nightMode={nightMode}
          />
          {peerJudged ? (
            <GlanceContainer
              icon={bookOutline}
              title="Read Stories"
              dates={[firstDuelDate, lastDuelDate]}
              isOdd={true}
              description="Read a total of 10 short stories over a 3 week period"
              nightMode={nightMode}
            />
          ) : null}
          <GlanceContainer
            icon={trophyOutline}
            title="Victory"
            dates={[winnersDate]}
            isOdd={peerJudged ? false : true}
            description="Winners are announced and all feedback is released"
            nightMode={nightMode}
          />
        </div>
      </div>
    </div>
  );
}

export default AtAGlance;
