import { useEffect, useState } from "react";
import { getPublicProfileByUserId } from "../../publicFunctions/publicProfileHandler";
import DefaultProfilePicture from "../../img/profile_picture.png";
import DefaultProfilePictureWhite from "../../img/profile_picture_white.png";
import { IonIcon } from "@ionic/react";
import { addOutline, closeOutline } from "ionicons/icons";
import { nightModeStyle } from "../../publicFunctions/publicHelpers/helpers";

function SelectUserButton({
  userId,
  clickingRemovesMe,
  handleWhatHappensWhenYouClickMe,
  isReadOnly,
  nightMode,
}) {
  const [publicUser, setPublicUser] = useState(null);

  useEffect(() => {
    if (userId) {
      getPublicProfileByUserId(userId)
        .then((publicUser) => {
          if (publicUser) {
            setPublicUser(publicUser);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [userId]);

  let editedName = "";
  if (publicUser?.name) {
    if (publicUser.name.length > 15) {
      editedName = publicUser.name.slice(0, 13) + "...";
    } else {
      editedName = publicUser.name;
    }
  }

  let selectUserClassName =
    "select-user-button" +
    (nightMode ? " black-background--lessDark" : " white-background-new");
  if (nightMode) {
    if (clickingRemovesMe && !isReadOnly) {
      selectUserClassName +=
        " green-background--darkestest-new greener-background-on-hover";
    } else if (clickingRemovesMe && isReadOnly) {
      selectUserClassName += " cursor--auto box-shadow--none";
    } else {
      selectUserClassName += " dark-green-background-on-hover";
    }
  } else {
    if (clickingRemovesMe && !isReadOnly) {
      selectUserClassName += " green-background white-background-on-hover";
    } else if (clickingRemovesMe && isReadOnly) {
      selectUserClassName += " cursor--auto box-shadow--none";
    } else {
      selectUserClassName += " green-background-on-hover";
    }
  }

  return (
    <div>
      {publicUser ? (
        <div
          role="button"
          className={selectUserClassName}
          onClick={() => {
            handleWhatHappensWhenYouClickMe(
              publicUser.userId,
              clickingRemovesMe
            );
          }}
        >
          <div className="flex align-items--center flex--gap-tiny">
            <img
              className="basic-user-info--img-smaller"
              src={
                publicUser.profilePic
                  ? publicUser.profilePic
                  : nightMode
                  ? DefaultProfilePictureWhite
                  : DefaultProfilePicture
              }
            />
            <p className="description--smaller description--small-set-width">
              {editedName}
            </p>
          </div>
          {!isReadOnly ? (
            <div className="flex align-items--center">
              <IonIcon
                className="social-icon"
                icon={clickingRemovesMe ? closeOutline : addOutline}
              />
            </div>
          ) : null}
        </div>
      ) : null}
    </div>
  );
}

export default SelectUserButton;
