import { useEffect } from "react";
import { nightModeStyleWhite } from "../../publicFunctions/publicHelpers/helpers";

function ThankYou({ wasSuccessful, resetThankYou, gifted, nightMode }) {
  useEffect(() => {
    window.scrollTo(0, 0);
    return () => {
      // On unmount, make sure this page isn't shown again
      resetThankYou();
    };
  }, []);

  return (
    <div className="thank-you-content">
      <h2
        style={nightMode ? nightModeStyleWhite : {}}
        className="heading-secondary margin-bottom-md"
      >
        {wasSuccessful
          ? gifted
            ? "Check your email inbox"
            : "Get ready to battle"
          : "Something's not quite right"}
      </h2>
      <p className="jb-description">
        {wasSuccessful
          ? gifted
            ? "The battle ticket(s) that you can gift to your friends have been sent to your email inbox. Please email\
          admin@writingbattle.com with any questions."
            : "You will receive a confirmation email in your inbox\
           shortly. Welcome, and enjoy the battle! Please email\
            admin@writingbattle.com with any questions."
          : "We failed to sign you up for one or more battles.\
           Please refresh the page and try again or email us\
            at admin@writingbattle.com. Sorry for any inconvenience."}
      </p>
    </div>
  );
}

export default ThankYou;
