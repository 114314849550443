import { useEffect, useState } from "react";
import { IonIcon } from "@ionic/react";
import {
  getBattleInfo,
  nightModeStyleWhite,
} from "../../publicFunctions/publicHelpers/helpers";
import { getIconByBattleId } from "../../publicFunctions/publicHelpers/homeHelper";
import { checkmarkOutline } from "ionicons/icons";
import { getBattleSwatchByBattleId } from "../../publicFunctions/publicHelpers/homeHelper";

function SelectBattle({
  battles,
  selectedBattles,
  selectBattleHandler,
  alreadyJoinedMap,
  giftingMode,
  nightMode,
}) {
  const [renderedBattles, setRenderedBattles] = useState([]);

  const createRenderedBattles = (battleArray, selectedMap) => {
    return battleArray.map((battle, index) => {
      const isSelected = selectedMap.has(battle.battleId);

      const battleInfo = getBattleInfo(battle);
      getIconByBattleId(battleInfo.battleTitle);
      let title = battleInfo.battleTitleLong.replace(" Battle", "");
      const titleSplit = title.split(" ");
      if (titleSplit.length >= 3) {
        title = titleSplit[0] + " " + titleSplit[1] + "\n";
        const titleSlice = titleSplit.slice(2, titleSplit.length);
        title = title + titleSlice.join(" ");
      }

      const alreadyJoined =
        !giftingMode && alreadyJoinedMap.has(battle.battleId);
      let className = "battle-select-button";
      if (index === 0) className += " battle-select-soonest";
      if (isSelected) className += " battle-select-button--selected";
      if (alreadyJoined) className += " battle-select-button--alreadyJoined";

      const battleClicked = (battleObject) => {
        selectBattleHandler(battleObject);
      };

      return (
        <div
          key={battle.battleId}
          onClick={!alreadyJoined ? () => battleClicked(battle) : () => {}}
          role="button"
          tabIndex={-1}
        >
          <div
            style={{
              overflow: "hidden",
              backgroundSize: "auto 28%",
              backgroundRepeat: "no-repeat",
              backgroundImage: `url(${
                getBattleSwatchByBattleId(battle.battleId).halfLandscape
              })`,
              backgroundPosition: "top left",
            }}
            className={className}
          >
            <div
              className={
                "checked-border" + (nightMode ? " checked-border--white" : "")
              }
              style={{
                visibility: alreadyJoined ? "hidden" : "visible",
              }}
            >
              <IonIcon
                className={"jb-battle-icon battle-checked"}
                icon={checkmarkOutline}
                style={{
                  visibility: isSelected ? "visible" : "hidden",
                }}
              />
            </div>
            <div className="flex jb-flex--gap-small justify-content--center">
              <div className="jb-battle-icon"></div>
              <p
                style={{ color: "black" }}
                className="jb-battle-name center-text pre-wrap battle-name--select white-shadow--huge"
              >
                {title}
              </p>
              <div className="jb-battle-icon"></div>
            </div>
            <br />
            <p className="jb-description--smaller span--3 center-text">
              {battleInfo.shortDescription}
            </p>
            <p className="jb-description center-text">
              {battleInfo.cashText + " in cash prizes"}
              <br />
              <span className="bold">{battleInfo.startDate}</span>
            </p>
            <div className="pricing-dollar-and-amount">
              <p className="dollar">$</p>
              <p
                style={nightMode ? nightModeStyleWhite : {}}
                className="battle-price"
              >
                {battleInfo.currentPrice}
              </p>
              <p
                style={nightMode ? nightModeStyleWhite : {}}
                className="percent-off"
              >
                {battleInfo.percentOff === "0"
                  ? "USD"
                  : "(" + battleInfo.percentOff + "% off)"}
              </p>
            </div>
            <p className="jb-description center-text bold">
              {alreadyJoined ? "Already Joined!" : "Join today!"}
            </p>
          </div>
        </div>
      );
    });
  };

  const maxNumberOfBattles = battles.length > 7 ? 7 : battles.length;

  useEffect(() => {
    if (battles?.length) {
      const selectedMap = new Map();
      for (const selected of selectedBattles) {
        selectedMap.set(selected.battleId, true);
      }
      setRenderedBattles(
        createRenderedBattles(battles.slice(0, maxNumberOfBattles), selectedMap)
      );
    }
  }, [battles, selectedBattles, giftingMode, nightMode]);

  return <div className="battle-select-grid">{renderedBattles}</div>;
}

export default SelectBattle;
