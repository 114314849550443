import { IonIcon } from "@ionic/react";
import {
  lockOpenOutline,
  alarmOutline,
  glassesOutline,
  analyticsOutline,
} from "ionicons/icons";
import Reveal from "../animations/Reveal";
import { nightModeStyleWhite } from "../../publicFunctions/publicHelpers/helpers";

function FeatureSection({ nightMode }) {
  return (
    <div
      style={nightMode ? nightModeStyleWhite : {}}
      className={
        nightMode ? "purple-background--lessDark" : "purple-background--lighter"
      }
    >
      <div className="container grid grid--4-cols padding-top-bot--smaller">
        <Reveal type="fromBottom" delay={0.1}>
          <div className="feature">
            <IonIcon className="feature-icon" icon={lockOpenOutline} />
            <p className={"feature-title" + (nightMode ? " blue" : "")}>
              Keep Your Rights
            </p>
            <p className="feature-text">
              It's your story. You retain all the rights with every submission.
            </p>
          </div>
        </Reveal>
        <Reveal type="fromBottom" delay={0.2}>
          <div className="feature">
            <IonIcon className="feature-icon" icon={alarmOutline} />
            <p className={"feature-title" + (nightMode ? " blue" : "")}>
              About Time
            </p>
            <p className="feature-text">
              Get those creative juices flowing! Write, re-write, submit in a
              few days.
            </p>
          </div>
        </Reveal>
        <Reveal type="fromBottom" delay={0.3}>
          <div className="feature">
            <IonIcon className="feature-icon" icon={glassesOutline} />
            <p className={"feature-title" + (nightMode ? " blue" : "")}>
              Read to Learn
            </p>
            <p className="feature-text">
              Learn what works and consider why by reading other entries in the
              Battle
            </p>
          </div>
        </Reveal>
        <Reveal type="fromBottom" delay={0.4}>
          <div className="feature">
            <IonIcon className="feature-icon" icon={analyticsOutline} />
            <p className={"feature-title" + (nightMode ? " blue" : "")}>
              Debrief
            </p>
            <p className="feature-text">
              Choose to share your story after the Battle and get even more
              feedback!
            </p>
          </div>
        </Reveal>
      </div>
    </div>
  );
}

export default FeatureSection;
