import { useEffect, useRef, useState } from "react";
import LoadingSection from "../LoadingSection";
import TrophySelectorItem from "./TrophySelectorItem";
import Lottie from "react-lottie-player";
import loadingAnimation from "../../img/animations/loadinganimation.json";
import { pageScrollSettings } from "../../publicFunctions/publicHelpers/helpers";
import { judgeGiveTrophy } from "../../config/firebase";

function TrophySelector({
  possibleStoriesForTrophies,
  trophyType,
  alreadyGaveTrophy,
  swissRound,
  battleId,
  onUpdatedGameStateFromServer,
  nightMode,
}) {
  const topRef = useRef(null);
  const [renderedTrophyList, setRenderedTrophyList] = useState(null);
  const [isWorking, setIsWorking] = useState(false);
  const [message, setMessage] = useState("");

  const numberOfTrophy =
    trophyType === "creative" ? "1" : trophyType === "prose" ? "2" : "3";
  const trophyName =
    trophyType === "creative"
      ? "Confidently Creative"
      : trophyType === "prose"
      ? "Poetic Prose"
      : "Captivating Character";

  useEffect(() => {
    setMessage("");
    const tempRender = [];
    for (let i = 0; i < possibleStoriesForTrophies.length; i++) {
      const storyObj = possibleStoriesForTrophies[i];
      if (!alreadyGaveTrophy.has(storyObj.storyId)) {
        tempRender.push(
          <TrophySelectorItem
            key={i.toString(10) + "_" + storyObj.storyId}
            storyObj={storyObj}
            battleId={battleId}
            swissRound={swissRound}
            trophyType={trophyType}
            handleTrophySelect={handleTrophySelect}
            isWorking={isWorking}
          />
        );
      }
    }
    setRenderedTrophyList(<div key={trophyType + "list"}>{tempRender}</div>);
  }, []);

  const handleTrophySelect = async (
    argTrohpyType,
    argStoryId,
    argSwissRound
  ) => {
    if (isWorking) {
      return;
    }
    if (topRef?.current) {
      topRef.current.scrollIntoView(pageScrollSettings);
    }
    try {
      setIsWorking(true);
      const { data } = await judgeGiveTrophy({
        battleId,
        trophyType: argTrohpyType,
        storyId: argStoryId,
        swissRound: argSwissRound,
      });
      if (data?.success && data.updatedGameState) {
        onUpdatedGameStateFromServer(data.updatedGameState);
      } else {
        if (!data?.message) {
          setMessage(
            "Hmmmm.... something went wrong. Please refresh the page and try again." +
              "If this problem persists, please email admin@writingbattle.com"
          );
        } else {
          setMessage(data.message);
        }
      }
    } catch (err) {
      if (err?.message) {
        console.log(err.message);
        setMessage(
          "Hmmmm.... something went wrong. Please refresh the page and try again." +
            "If this problem persists, please email admin@writingbattle.com"
        );
      }
    } finally {
      setIsWorking(false);
    }
  };

  return (
    <div ref={topRef} className="center-text margin-left-right-tiny">
      {message ? <p className="description bold">{message}</p> : null}
      {isWorking ? (
        <>
          <p className="description center-text">Giving trophy. One sec...</p>
          <div className="center-flex flex justify-content--center">
            <Lottie
              loop
              animationData={loadingAnimation}
              play
              style={{ width: "5rem", height: "5rem" }}
            ></Lottie>
          </div>
        </>
      ) : (
        <>
          <p className="description--large">
            Please take the time to give out trophies for the previous round
            <br />
            <span className="description--smaller">
              Note: Trophies are only given out after each of the first three
              rounds.
            </span>
          </p>
          <p className="description">
            <span className="bold">{numberOfTrophy} of 3 trophies </span>to give
            out
          </p>
          <p className="description--smaller">
            one for each type: Confidently Creative, Poetic Prose, and
            Captivating Character.
            <br />
            You may not give the same story more than one trophy.
          </p>
          <p className="description--larger bold margin-top-smaller">
            {trophyName}
          </p>
          <p className="description--small bold">
            {getTrophyDescription(trophyType)}
          </p>
          <br />
          <p className="description--smaller">
            If you need a reminder, you may click the story title to open the
            story in a new tab
          </p>
          <br />
          {renderedTrophyList ? (
            <>{renderedTrophyList}</>
          ) : (
            <LoadingSection nightMode={nightMode} />
          )}
        </>
      )}
    </div>
  );
}

function getTrophyDescription(trophyType) {
  let description = "Choose one story that you found ";
  if (trophyType === "creative") {
    description += "most creative";
  } else if (trophyType === "prose") {
    description += "had the most beautiful prose";
  } else {
    description += "had the most unique or funny or interesting character";
  }
  description += " out of the list (you must choose one).";
  return description;
}

export default TrophySelector;
