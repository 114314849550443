const ONE_WEEK = 604800000;
const ONE_MONTH = 2592000000;
const TWENTY_FOUR_HOURS = 86400000;
const EIGHTEEN_HOURS = 64800000;
const ONE_HOUR = 3600000;
const NIGHT_MODE_KEY = "nightMode";

export function saveNightMode(nightMode = false) {
  try {
    localStorage.setItem(
      NIGHT_MODE_KEY,
      JSON.stringify(nightMode ? true : false)
    );
  } catch (err) {
    console.log(err);
  }
}

export function getNightMode() {
  try {
    const tempNightMode = localStorage.getItem(NIGHT_MODE_KEY);
    if (tempNightMode) {
      return JSON.parse(tempNightMode);
    } else {
      return false;
    }
  } catch (err) {
    console.log(err);
  }
}

export function saveDataToLocalStorageWithExpiry(
  key,
  data,
  isLongExpiry = false,
  isSuperLongExpiry = false,
  aLittleLessThanADay = false,
  makeItAMonth = false
) {
  let expiryPeriod;
  if (isSuperLongExpiry) {
    if (makeItAMonth) {
      expiryPeriod = ONE_MONTH;
    } else {
      expiryPeriod = ONE_WEEK;
    }
  } else if (isLongExpiry) {
    if (aLittleLessThanADay) {
      expiryPeriod = EIGHTEEN_HOURS;
    } else {
      expiryPeriod = TWENTY_FOUR_HOURS;
    }
  } else {
    expiryPeriod = ONE_HOUR;
  }
  try {
    const now = new Date();
    const item = {
      data: data,
      expiry: now.getTime() + expiryPeriod,
    };
    localStorage.setItem(key, JSON.stringify(item));
  } catch (err) {
    console.log(err);
  }
}

export function getDataFromLocalStorageWithExpiry(key) {
  try {
    const item = localStorage.getItem(key);
    if (item) {
      const parsedItem = JSON.parse(item);
      const now = new Date();
      const expiry = new Date(parsedItem.expiry);

      if (now.getTime() < expiry.getTime()) {
        return parsedItem.data; // Return data if not expired
      } else {
        localStorage.removeItem(key); // Remove expired data from localStorage
      }
    }
  } catch (err) {
    console.log(err);
  }
  return null;
}
