import { IonIcon } from "@ionic/react";
import { menuOutline, closeOutline } from "ionicons/icons";
import { nightModeStyle } from "../../publicFunctions/publicHelpers/helpers";

function MobileNav({ onClick, nightMode }) {
  return (
    <button
      style={nightMode ? nightModeStyle : {}}
      onClick={onClick}
      className="btn-mobile-nav"
    >
      <IonIcon
        className={
          "icon-mobile-nav menu-outline" +
          (nightMode ? " icon-mobile-nav--white" : "")
        }
        icon={menuOutline}
      />
      <IonIcon
        className={
          "icon-mobile-nav close-outline" +
          (nightMode ? " icon-mobile-nav--white" : "")
        }
        icon={closeOutline}
      />
    </button>
  );
}

export default MobileNav;
