import {
  nightModeStyle,
  nightModeStyleWhite,
} from "../../publicFunctions/publicHelpers/helpers";

function TarotMeaningsNav({
  handleListChange,
  listKey,
  meaningsRef,
  nightMode,
}) {
  const navClassName = "heading-tertiary debrief-nav-item";
  return (
    <div
      style={nightMode ? nightModeStyle : {}}
      className={
        "padding-top-bot--sm" +
        (!nightMode ? " purple-background--lightest" : "")
      }
    >
      <h2
        style={nightMode ? nightModeStyleWhite : {}}
        ref={meaningsRef}
        className="heading-secondary margin--none center-text"
      >
        Tarot Meanings
      </h2>
      <p className="description center-text">A guide to all 78 tarot cards</p>
      {listKey === "none" ? (
        <p className="description center-text italic">
          Click on one of the tarot suits below
        </p>
      ) : null}
      <br />
      <div className="container debrief-body-nav justify-content--center">
        <div
          tabIndex={0}
          role="button"
          onClick={() => {
            handleListChange("major");
          }}
        >
          <p
            style={nightMode ? nightModeStyleWhite : {}}
            className={
              navClassName +
              (listKey === "major"
                ? " debrief-nav-item--selected" +
                  (nightMode ? " selected-light" : " selected-dark")
                : "")
            }
          >
            Major
          </p>
        </div>
        <div
          tabIndex={0}
          role="button"
          onClick={() => {
            handleListChange("wands");
          }}
        >
          <p
            style={nightMode ? nightModeStyleWhite : {}}
            className={
              navClassName +
              (listKey === "wands"
                ? " debrief-nav-item--selected" +
                  (nightMode ? " selected-light" : " selected-dark")
                : "")
            }
          >
            Wands
          </p>
        </div>
        <div
          tabIndex={0}
          role="button"
          onClick={() => {
            handleListChange("cups");
          }}
        >
          <p
            style={nightMode ? nightModeStyleWhite : {}}
            className={
              navClassName +
              (listKey === "cups"
                ? " debrief-nav-item--selected" +
                  (nightMode ? " selected-light" : " selected-dark")
                : "")
            }
          >
            Cups
          </p>
        </div>
        <div
          tabIndex={0}
          role="button"
          onClick={() => {
            handleListChange("swords");
          }}
        >
          <p
            style={nightMode ? nightModeStyleWhite : {}}
            className={
              navClassName +
              (listKey === "swords"
                ? " debrief-nav-item--selected" +
                  (nightMode ? " selected-light" : " selected-dark")
                : "")
            }
          >
            Swords
          </p>
        </div>
        <div
          tabIndex={0}
          role="button"
          onClick={() => {
            handleListChange("coins");
          }}
        >
          <p
            style={nightMode ? nightModeStyleWhite : {}}
            className={
              navClassName +
              (listKey === "coins"
                ? " debrief-nav-item--selected" +
                  (nightMode ? " selected-light" : " selected-dark")
                : "")
            }
          >
            Coins
          </p>
        </div>
      </div>
    </div>
  );
}

export default TarotMeaningsNav;
