import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  dayModeStyleBlack,
  nightModeStyle,
  nightModeStyleWhite,
} from "../../publicFunctions/publicHelpers/helpers";

const { default: Countdown } = require("../battle/Countdown");

function HomePageCountdown({
  battle,
  alreadyJoinedMap,
  userInfo,
  openLogInHandler,
  nightMode,
}) {
  const [dateAndTitleObject, setDateAndTitleObject] = useState(null);

  useEffect(() => {
    if (battle && alreadyJoinedMap && userInfo) {
      setDateAndTitleObject(
        getTitleAndDate(battle, alreadyJoinedMap, userInfo)
      );
    }
  }, [battle, alreadyJoinedMap, userInfo]);

  const navigate = useNavigate();

  function getTitleAndDate(battle, alreadyJoinedMap, userInfo) {
    const hasJoinedBattle = alreadyJoinedMap.has(battle.battleId);
    const now = new Date();
    let title;
    let date;
    // first, determine date. Either lock in or init
    let battleHasStarted = false;
    let afterLockIn = false;
    if (now > battle.lockInDate) {
      afterLockIn = true;
      battleHasStarted = true;
      if (hasJoinedBattle) {
        date = battle.submissionDate;
      } else {
        date = now; // can no longer join
      }
    } else if (now > battle.initialDate) {
      if (hasJoinedBattle) {
        date = battle.submissionDate;
      } else {
        date = battle.lockInDate;
      }
      battleHasStarted = true;
    } else {
      date = battle.initialDate;
    }

    // now determine title
    if (afterLockIn && !hasJoinedBattle && userInfo.isLoggedIn()) {
      title = "The Battle is underway and can no longer be joined.";
    } else if (!userInfo.isLoggedIn()) {
      if (afterLockIn) {
        title = "Please log in or register.";
      } else if (battleHasStarted) {
        title = "The Battle has started, but there's still time to register!";
      } else {
        title = "The Battle is about to begin. Register today!";
      }
    } else {
      if (battleHasStarted) {
        if (hasJoinedBattle) {
          title = "Go to the Battle! Story due in...";
        } else {
          title = "The Battle has started, but there's still time to join!";
        }
      } else {
        if (hasJoinedBattle) {
          title = "The Battle begins soon. Ready to Battle?";
        } else {
          title = "The Battle begins soon. Are you registered?";
        }
      }
    }

    return {
      title,
      date,
      hasJoinedBattle,
      afterLockIn,
    };
  }

  return (
    <div
      style={nightMode ? nightModeStyle : {}}
      className="home-page-countdown"
    >
      <div className="home-page-countdown-container">
        <div className="hp-countdown-wrapper">
          <p
            style={nightMode ? nightModeStyleWhite : dayModeStyleBlack}
            className="heading-secondary hp-title"
          >
            {dateAndTitleObject ? dateAndTitleObject.title : ""}
          </p>
          {dateAndTitleObject?.date ? (
            <Countdown date={dateAndTitleObject.date} isHomePage={true} />
          ) : null}
        </div>
        <div className="hp-buttons-wrapper">
          {!userInfo.isLoggedIn() ? (
            <>
              <button
                onClick={() => {
                  navigate("/join-battle");
                }}
                className="login-btn"
              >
                Register
              </button>
              <p className="description center-text">- OR -</p>
              <button
                onClick={() => openLogInHandler("login")}
                className="login-btn"
              >
                Log In
              </button>
            </>
          ) : null}
          {dateAndTitleObject?.hasJoinedBattle ? (
            <button
              onClick={() => {
                navigate("/battle");
              }}
              className="login-btn"
            >
              Go to Battle
            </button>
          ) : null}
          {!dateAndTitleObject?.hasJoinedBattle &&
          userInfo.isLoggedIn() &&
          !dateAndTitleObject?.afterLockIn ? (
            <button
              onClick={() => {
                navigate("/join-battle");
              }}
              className="login-btn"
            >
              Join today!
            </button>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default HomePageCountdown;
