import { useEffect, useState } from "react";
import { IonIcon } from "@ionic/react";
import { homeOutline } from "ionicons/icons";
import JudgeMallet from "../../img/judgeMallet.png";
import {
  getCardImageUrlByCard,
  getFlagImageUrlByFlagFileName,
} from "../../publicFunctions/publicHelpers/imageUrlBuilder";
import {
  dayModeStyleBlack,
  nightModeStyle,
  nightModeStyleLessDark,
  nightModeStyleWhite,
} from "../../publicFunctions/publicHelpers/helpers";

function DebriefHouseSelector({
  debriefMeta,
  houseSelectedHandler,
  myStory,
  myDuels,
  nightMode,
}) {
  const [renderedHouses, setRenderedHouses] = useState(null);

  useEffect(() => {
    if (debriefMeta?.genres?.length && debriefMeta.houses) {
      const mapOfHousesThatIJudged = new Map();
      if (myDuels?.length) {
        myDuels.forEach((duel) => {
          mapOfHousesThatIJudged.set(duel.houseId, true);
        });
      }

      const tempRenderArray = [];
      for (let i = 0; i < debriefMeta.genres.length; i++) {
        const genreObject = debriefMeta.genres[i];
        const housesForThisGenre =
          debriefMeta.houses["genre" + (i + 1).toString(10)];
        if (!housesForThisGenre) continue;
        tempRenderArray.push(
          <div
            key={i.toString(10) + "title"}
            className="debrief-genre-title--container"
          >
            <img
              className="debrief-genre-title-card"
              src={getCardImageUrlByCard(genreObject.card, true)}
            />
            <p
              style={nightMode ? nightModeStyleWhite : {}}
              className="heading-secondary margin--none"
            >
              {genreObject.genre}
            </p>
          </div>
        );

        // now go through house and add the little house cards
        const housesRender = [];
        const myHouse = myStory?.houseId ? myStory.houseId : "";
        for (let j = 0; j < housesForThisGenre.length; j++) {
          const house = housesForThisGenre[j];
          housesRender.push(
            <div
              style={nightMode ? nightModeStyleLessDark : {}}
              key={i.toString(10) + "_" + j.toString(10) + "house"}
              onClick={() => {
                houseSelectedHandler(house.houseId);
              }}
              className="battle-select-button"
              role="button"
            >
              <img
                className="debrief-flag"
                src={getFlagImageUrlByFlagFileName(house.flagFileName)}
              />
              <p className="description bold center-text">{house.name}</p>
              {myHouse && myHouse === house.houseId ? (
                <IonIcon
                  className="social-icon house-selector-icon"
                  icon={homeOutline}
                />
              ) : null}
              {mapOfHousesThatIJudged.has(house.houseId) ? (
                <img
                  className={"social-icon house-selector-icon"}
                  src={JudgeMallet}
                />
              ) : null}
            </div>
          );
        }
        tempRenderArray.push(
          <div
            key={i.toString(10) + "_" + "house-grid"}
            className="debrief-house-grid"
          >
            {housesRender}
          </div>
        );
      }
      setRenderedHouses(tempRenderArray);
    }
  }, [debriefMeta, myStory, myDuels, nightMode]);

  return (
    <div style={nightMode ? nightModeStyle : {}} className="container">
      <div className="house-selector">{renderedHouses}</div>
    </div>
  );
}

export default DebriefHouseSelector;
