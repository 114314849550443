import { IonIcon } from "@ionic/react";
import {
  TAROT_PREVIEW_3,
  TAROT_PREVIEW_4,
  TAROT_PREVIEW_5,
  TAROT_PREVIEW_6,
  TAROT_PREVIEW_7,
  TAROT_PREVIEW_8,
} from "../../publicFunctions/miscHandler";
import { openOutline } from "ionicons/icons";
import { nightModeStyleWhite } from "../../publicFunctions/publicHelpers/helpers";

function TarotPreview({ goToLink, nightMode }) {
  return (
    <div style={nightMode ? nightModeStyleWhite : {}}>
      <div className=" padding purple-background--light flex justify-content--center align-items--center flex--direction-column">
        <div className="flex justify-content--center ">
          <div className="tarot-image-list">
            <img className="tarot-preview-img" src={TAROT_PREVIEW_6} />
            <img className="tarot-preview-img" src={TAROT_PREVIEW_3} />
            <img className="tarot-preview-img" src={TAROT_PREVIEW_7} />
            <img className="tarot-preview-img" src={TAROT_PREVIEW_4} />
            <img className="tarot-preview-img" src={TAROT_PREVIEW_8} />
            <img className="tarot-preview-img" src={TAROT_PREVIEW_5} />
          </div>
        </div>
        <br />
        <br />
        <a href={goToLink} target="_blank" className="btn btn--outline">
          <div className="flex justify-conent--center align-items--center flex--gap-tiny">
            Order Your Tarot Deck
            <IonIcon icon={openOutline} />
          </div>
        </a>
      </div>
      <div
        className={
          "padding-bottom" +
          (nightMode ? " yellow-background--dark" : " yellow-background")
        }
      >
        <div
          className={
            nightMode
              ? "triangle-element--tarot--dark"
              : "triangle-element--tarot"
          }
        />
        <h2
          style={nightMode ? nightModeStyleWhite : {}}
          className="heading-secondary center-text bottom-margin--small"
        >
          What is Writing Battle?
        </h2>
        <p className="description center-text max-width--readable-center--larger">
          Writing Battle is a series of writing contests that uses Tarot and
          other prompt cards to inspire authors in their creative writing.
        </p>
        <br />
        <br />
        <br />
        <p className="description--large center-text bold">
          Keep scrolling to learn more! &darr;
        </p>
      </div>
    </div>
  );
}

export default TarotPreview;
