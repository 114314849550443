import { useEffect, useRef, useState } from "react";
import loadingAnimation from "../../img/animations/loadinganimation.json";
import { IonIcon } from "@ionic/react";
import { arrowUpOutline } from "ionicons/icons";
import { getCardImageUrlByCard } from "../../publicFunctions/publicHelpers/imageUrlBuilder";
import {
  nightModeStyleWhite,
  pageScrollSettings,
} from "../../publicFunctions/publicHelpers/helpers";
import BattleCard from "../battle/BattleCard";
import CardState from "../../publicFunctions/clientClasses/CardState";
import {
  createCardWrapperForTavernCard,
  createHowToEarnText,
  getTavernCard,
} from "../../publicFunctions/tavernHandler";
import Lottie from "react-lottie-player";

const NUMBER_OF_CARDS_PER_ROW = 5;

function SubCardSelector({
  subCollection,
  myCards,
  showOnlyOwnedCards,
  selectedCardHandler,
  hideDisplayCard = false,
  promptSelecting,
  isFreebie,
  collectionMeta,
  nightMode,
}) {
  const groupName = subCollection?.groupName
    ? subCollection.groupName
    : "Card Group";
  const topRef = useRef(null);
  const [collapsed, setCollapsed] = useState(true);
  const [renderedCards, setRenderedCards] = useState(null);
  const [displayCardState, setDisplayCardState] = useState(
    groupName.includes("Genre") || groupName.includes("Raids")
      ? new CardState("Genre")
      : new CardState("")
  );
  const [loadingDisplayCard, setLoadingDisplayCard] = useState(false);
  const [displayCardId, setDisplayCardId] = useState("");
  const [displayCardInfo, setDisplayCardInfo] = useState(null);
  const [showingAtLeastOneCard, setShowingAtLeastOneCard] = useState(false);
  const [cardsOwned, setCardsOwned] = useState(-1);
  const [totalCards, setTotalCards] = useState(-1);
  const [altInfo, setAltInfo] = useState(null);

  useEffect(() => {
    if (displayCardId) {
      setLoadingDisplayCard(true);
      getTavernCard(displayCardId)
        .then((tempCard) => {
          if (topRef?.current) {
            topRef.current.scrollIntoView(pageScrollSettings);
          }
          setDisplayCardInfo(tempCard);
          const tempState = new CardState();
          tempState.injectCardWithDataFromServer(
            createCardWrapperForTavernCard(tempCard)
          );
          tempState.text = tempCard.defaultText;
          tempState.type = tempCard.defaultType;
          setDisplayCardState(tempState);

          if (tempCard.altText && tempCard.altType) {
            setAltInfo({
              altText: tempCard.altText,
              altType: tempCard.altType,
              defaultText: tempCard.defaultText,
              defaultType: tempCard.defaultType,
              showingAltText: false,
            });
          } else {
            setAltInfo(null);
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setLoadingDisplayCard(false);
        });
    }
  }, [displayCardId]);

  useEffect(() => {
    const tempRenderedCards = [];
    const myCardsMap = myCards?.map ? myCards.map : new Map();
    const ownAll =
      isFreebie || (myCards && myCards[subCollection.groupName]) ? true : false;
    if (subCollection?.cards?.length) {
      const LIMIT = collapsed
        ? Math.floor(NUMBER_OF_CARDS_PER_ROW * 2)
        : subCollection.cards.length;

      let cardsShownSoFar = 0;
      let tempNumberOfCardsOwned = 0;
      const cardButtonWrapper = [];
      setTotalCards(
        subCollection.cards.length ? subCollection.cards.length : 0
      );
      // loop through it just counting the number you own
      for (let i = 0; i < subCollection.cards.length; i++) {
        const card = subCollection.cards[i];
        const cardOwned = ownAll || myCardsMap.has(card.id) ? true : false;
        if (cardOwned) {
          tempNumberOfCardsOwned++;
        }
      }

      for (
        let i = 0;
        i < subCollection.cards.length && cardsShownSoFar < LIMIT;
        i++
      ) {
        const card = subCollection.cards[i];
        const cardOwned = ownAll || myCardsMap.has(card.id) ? true : false;
        const thisIsAPartOfTheCollapseButton =
          cardsShownSoFar >= NUMBER_OF_CARDS_PER_ROW && collapsed
            ? true
            : false;
        let cardClass = !thisIsAPartOfTheCollapseButton
          ? "collection-card--mini fade-in card-select"
          : "collection-card--mini card-select--collapse-button";
        cardClass += cardOwned ? " collection-card--mini-owned" : "";
        if (
          card?.imageFileName &&
          card.id &&
          (!showOnlyOwnedCards || cardOwned)
        ) {
          cardsShownSoFar++;
          setShowingAtLeastOneCard(true);
          const arrayToPush = thisIsAPartOfTheCollapseButton
            ? cardButtonWrapper
            : tempRenderedCards;
          arrayToPush.push(
            <img
              key={card.id}
              className={cardClass}
              src={getCardImageUrlByCard(card, true)}
              onClick={() => {
                if (!thisIsAPartOfTheCollapseButton) {
                  if (!hideDisplayCard) {
                    setDisplayCardId(card.id);
                  }
                  selectedCardHandler(card.id);
                }
              }}
            />
          );
        }
        if (!cardsShownSoFar) {
          setShowingAtLeastOneCard(false);
        }
        setCardsOwned(tempNumberOfCardsOwned);
      }
      if (cardButtonWrapper.length) {
        tempRenderedCards.push(
          <div
            key={groupName + "_card_button"}
            className="card-collection-array card-collapse-button-wrapper"
            onClick={() => {
              setCollapsed(false);
            }}
          >
            {cardButtonWrapper}
            <div role="button" className="card-collapse-button center-text">
              <div className="btn btn--outline">
                <p className="description bold">Show All</p>
              </div>
            </div>
          </div>
        );
      }
    }
    setRenderedCards(tempRenderedCards);
  }, [collapsed, showOnlyOwnedCards, nightMode]);

  const hideAllCollapseOptions =
    renderedCards?.length && renderedCards.length <= NUMBER_OF_CARDS_PER_ROW
      ? true
      : false;

  if (!showingAtLeastOneCard) {
    return null;
  } else {
    return (
      <div className="sub-card-selector flex flex--direction-column">
        {promptSelecting ? (
          <p ref={topRef} className="description margin-none bold">
            {groupName}
          </p>
        ) : collectionMeta &&
          collectionMeta[groupName + "_ad"] &&
          !myCards[groupName] ? (
          <div className="flex margin-auto center-text flex--gap-small align-items--center margin-bottom-sm">
            <div className="flex flex--direction-column">
              <h2
                style={nightMode ? nightModeStyleWhite : {}}
                ref={topRef}
                className="heading-secondary margin-bottom-sm center-text"
              >
                {groupName}
              </h2>
              <p className="description--small italic center-text">
                Earn all of these cards when you order the physical deck
              </p>
            </div>
            <a
              role="button"
              href={collectionMeta[groupName + "_ad"].goToLink}
              target="_blank"
              className="flex flex--direction-column cursor--pointer this-anchor-is-a-button"
            >
              <img
                className="tarot-preview-img tarot-preview-img--small"
                src={collectionMeta[groupName + "_ad"].squareImage}
              />
              <p className="center-text description--small clickable-text bold">
                Buy this deck
              </p>
            </a>
          </div>
        ) : (
          <h2
            style={nightMode ? nightModeStyleWhite : {}}
            ref={topRef}
            className="heading-secondary margin-bottom-sm center-text"
          >
            {groupName}
          </h2>
        )}
        {groupName?.includes("Tarot") ? (
          <a
            role="button"
            href="https://www.writingbattle.com/tarot"
            target="_blank"
            className="description--small center-text cursor--pointer this-anchor-is-a-button clickable-text margin-bottom-sm"
          >
            Get your free daily Tarot reading!
          </a>
        ) : null}
        {cardsOwned >= 0 && totalCards >= 0 && !promptSelecting ? (
          <p className="description--small center-text margin-bottom-md">
            You've earned{" "}
            <span className="bold">{cardsOwned.toString(10)}</span> out of{" "}
            <span className="bold">{totalCards}</span> cards in this deck.
          </p>
        ) : null}
        <div
          className={
            "sub-card-selector-grid" +
            (hideDisplayCard ? " sub-card-selector-grid--1col" : "")
          }
        >
          {!hideDisplayCard ? (
            <div className="flex flex--direction-column">
              <div className="margin-auto">
                <BattleCard
                  cardState={displayCardState}
                  position={0}
                  requestUnlock={null}
                  isWorking={false}
                  cardIsBeingRedrawn={false}
                  forceLockCards={true}
                  makeThisAButton={true}
                  ownedBorder={
                    displayCardInfo &&
                    myCards &&
                    myCards[subCollection.groupName]
                      ? true
                      : false
                  }
                />
              </div>
              {loadingDisplayCard ? (
                <div className="center-flex">
                  <Lottie
                    loop
                    animationData={loadingAnimation}
                    play
                    style={{ width: "5rem", height: "5rem" }}
                  ></Lottie>
                </div>
              ) : null}
              {!loadingDisplayCard && displayCardInfo ? (
                <div className="margin-top-smaller">
                  {displayCardInfo && altInfo && displayCardState ? (
                    <div className="flex margin-auto">
                      <p
                        role="button"
                        onClick={() => {
                          if (displayCardState) {
                            const tempState = displayCardState.clone();
                            if (altInfo.showAltText) {
                              tempState.text = altInfo.defaultText;
                              tempState.type = altInfo.defaultType;
                            } else {
                              tempState.text = altInfo.altText;
                              tempState.type = altInfo.altType;
                            }
                            setDisplayCardState(tempState);
                            const tempAltInfo = JSON.parse(
                              JSON.stringify(altInfo)
                            );
                            tempAltInfo.showAltText = !tempAltInfo.showAltText;
                            setAltInfo(tempAltInfo);
                          }
                        }}
                        className="description--small center-text cursor--pointer margin-bottom-smaller clickable-text inline-block"
                      >
                        {altInfo.showAltText
                          ? "Switch to default prompt text"
                          : "Switch to alt prompt text"}
                      </p>
                    </div>
                  ) : null}
                  {displayCardInfo &&
                  myCards?.map &&
                  (myCards[subCollection.groupName] ||
                    myCards.map.has(displayCardInfo.id)) ? (
                    <p className="description--small bold margin-bottom-smaller">
                      You've earned this card!
                    </p>
                  ) : null}
                  <p className="description--small">
                    Artist:{" "}
                    <span className="bold">{displayCardInfo.artist}</span>
                  </p>
                  <p className="description--small">
                    Type:{" "}
                    <span className="italic">
                      {displayCardInfo.defaultType}
                    </span>
                  </p>
                  <p className="description--small">
                    Text:{" "}
                    <span className="italic">
                      {displayCardInfo.defaultText}
                    </span>
                  </p>
                  {
                    <p className="description--small">
                      How to Earn:{" "}
                      <span className="italic description--smaller white-space">
                        {createHowToEarnText(displayCardInfo.howToEarn)}
                      </span>
                    </p>
                  }
                  {displayCardInfo.defaultDefinition ? (
                    <p className="description--small">
                      <br />
                      Definition:{" "}
                      <span className="italic description--smaller">
                        {displayCardInfo.defaultDefinition}
                      </span>
                    </p>
                  ) : null}
                </div>
              ) : null}
            </div>
          ) : null}
          <div>
            <div className="card-collection-array">{renderedCards}</div>
            {!collapsed && !hideAllCollapseOptions ? (
              <div
                className="margin-top-bottom-md flex justify-content--center clickable-text flex--gap-normal"
                onClick={() => {
                  setCollapsed(true);
                  if (topRef?.current) {
                    topRef.current.scrollIntoView(pageScrollSettings);
                  }
                }}
              >
                <IonIcon className="social-icon" icon={arrowUpOutline} />
                <p className="description bold center-text">Collapse</p>
                <IonIcon className="social-icon" icon={arrowUpOutline} />
              </div>
            ) : null}
          </div>
        </div>
        {!promptSelecting ? (
          <div className="simple-divider margin-auto center-text"></div>
        ) : null}
      </div>
    );
  }
}

export default SubCardSelector;
