import "../../styles/register.css";
import { useEffect, useState } from "react";
import { IonIcon } from "@ionic/react";
import { closeOutline } from "ionicons/icons";
import LogIn from "./LogIn";
import Register from "./Register";
import ForgotPassword from "./ForgotPassword";
import {
  dayModeStyleBlack,
  nightModeStyleLessDark,
  nightModeStyleWhite,
} from "../../publicFunctions/publicHelpers/helpers";
import { color } from "framer-motion";

function RegisterOverlay({ closeHandler, goToPage, userInfo, nightMode }) {
  const [pageType, setPageType] = useState("login");
  const isOpen = goToPage !== "closed";

  useEffect(() => {
    if (goToPage !== "closed") {
      setPageType(goToPage);
    }
  }, [goToPage]);

  const switchPage = (pageToSwitchTo) => {
    setPageType(pageToSwitchTo);
  };

  const forgotPasswordClicked = () => {
    setPageType("forgotPassword");
  };

  return (
    <div
      className={
        "register-overlay fade-in" +
        (nightMode ? " register-overlay--black" : "")
      }
      style={{ display: isOpen ? "block" : "none" }}
    >
      <div
        style={nightMode ? nightModeStyleLessDark : {}}
        className="register-box center-box"
      >
        <button
          onClick={closeHandler}
          className="general-icon-box general-icon-box--close"
        >
          <IonIcon
            style={nightMode ? { color: "#b3b3b3" } : { color: "#343434" }}
            className="general-icon"
            icon={closeOutline}
          />
        </button>

        {pageType === "login" ? (
          <LogIn
            switchToForgotPasswordPage={forgotPasswordClicked}
            nightMode={nightMode}
          />
        ) : pageType === "register" ? (
          <Register nightMode={nightMode} />
        ) : (
          <ForgotPassword userInfo={userInfo} nightMode={nightMode} />
        )}
        <div className="simple-divider description--small center-text"></div>
        <a
          onClick={() =>
            switchPage(pageType === "login" ? "register" : "login")
          }
          className="description center-text clickable clickable-text"
        >
          {pageType === "login"
            ? "Not a member yet?"
            : pageType === "register"
            ? "Already a member?"
            : "Back to login"}
        </a>
      </div>
    </div>
  );
}

export default RegisterOverlay;
