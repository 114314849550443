import { useEffect, useState } from "react";
import { IonIcon } from "@ionic/react";
import {
  caretForwardOutline,
  caretDownOutline,
  closeOutline,
} from "ionicons/icons";
import Lottie from "react-lottie-player";
import ForumQuillWrapper from "./ForumQuillWrapper";
import loadingAnimation from "../../img/animations/simpleLoading.json";
import {
  createForumDiscussion,
  createForumMessage,
} from "../../publicFunctions/forumHandler";
import { subscribeToTopic } from "../../config/firebase";
import TagUsers from "../tag-users/TagUsers";
import ReplyToMessage from "./ReplyToMessage";
import {
  nightModeStyle,
  nightModeStyleLessDark,
  nightModeStyleWhite,
} from "../../publicFunctions/publicHelpers/helpers";

function CreateTopic({
  userId,
  isReply,
  topicId,
  handleForceRefresh,
  suggestedUserIds,
  replyToMessageRequest,
  handleClearReplyToMessage,
  nightMode,
}) {
  const [content, setContent] = useState(null);
  const [topicTitle, setTopicTitle] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [successfullyPosted, setSuccessfullyPosted] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [changesMade, setChangesMade] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [listOfTaggedUsers, setListOfTaggedUsers] = useState([]);
  const [modifyTaggedUser, setModifyTaggedUser] = useState(null);
  const [replyToMessageId, setReplyToMessageId] = useState("");

  useEffect(() => {
    if (replyToMessageRequest || modifyTaggedUser?.userIdToTag) {
      const userIdToTag = replyToMessageRequest
        ? replyToMessageRequest.userIdToTag
        : modifyTaggedUser.userIdToTag;
      const removeUserId = !replyToMessageRequest
        ? modifyTaggedUser.removeUserId
        : false;
      let tempList = [];
      if (listOfTaggedUsers?.length) {
        tempList.push(...listOfTaggedUsers);
      }
      if (removeUserId) {
        for (let i = tempList.length - 1; i >= 0; i--) {
          if (tempList[i] === userIdToTag) {
            tempList.splice(i, 1);
          }
        }
        setListOfTaggedUsers(tempList);
      } else if (tempList.length < 5) {
        let userIdIsAlreadyInList = false;
        for (let i = 0; i < tempList.length; i++) {
          if (userIdToTag === tempList[i]) {
            userIdIsAlreadyInList = true;
            break;
          }
        }
        if (!userIdIsAlreadyInList) {
          tempList.push(userIdToTag);
        }
        setListOfTaggedUsers(tempList);
      }

      setModifyTaggedUser(null);
      if (replyToMessageRequest) {
        setReplyToMessageId(replyToMessageRequest.replyToMessageId);
        handleClearReplyToMessage();
      }
    }
  }, [modifyTaggedUser, replyToMessageRequest]);

  const handleTagUser = (userIdToTag, removeUserId = false) => {
    if (userIdToTag) {
      setModifyTaggedUser({ userIdToTag, removeUserId });
    }
  };

  const contentHandler = (newValue) => {
    if (newValue) {
      // changes aren't made on load
      setContent(newValue);
      setChangesMade(true);
    }
  };

  const onTopicTitleChange = (event) => {
    if (!event.target.value?.length) {
      setTopicTitle(event.target.value);
      setErrorMessage("Please provide a discussion title.");
    } else if (event.target.value.length <= 100) {
      setTopicTitle(event.target.value);
      setErrorMessage("");
    } else {
      setErrorMessage("Please shorten the title of this discussion.");
    }
  };

  const onTopicCreate = () => {
    if (!isLoading && userId) {
      if (!topicTitle && !isReply) {
        setErrorMessage("Error. Please provide a discussion title.");
        return;
      }
      setIsLoading(true);
      if (isReply) {
        createForumMessage(
          userId,
          topicId,
          content,
          listOfTaggedUsers,
          replyToMessageId
        ).then(() => {
          setContent(null);
          handleForceRefresh();
          setIsLoading(false);
          setSuccessfullyPosted(true);
          setChangesMade(false);
          setListOfTaggedUsers([]);
          setReplyToMessageId("");
          setTimeout(() => {
            setSuccessfullyPosted(false);
          }, 2000);

          //success
        });
      } else {
        createForumDiscussion(
          userId,
          topicTitle,
          content,
          listOfTaggedUsers
        ).then((createdTopicId) => {
          setContent(null);
          handleForceRefresh();
          setIsLoading(false);
          setSuccessfullyPosted(true);
          setTopicTitle("");
          setChangesMade(false);
          setListOfTaggedUsers([]);
          subscribeToTopic({
            topicId: createdTopicId,
            checkingIfSubscribed: false,
            iWantToBeSubscribed: true,
          });
        });
      }
      setErrorMessage("");
    }
  };

  return (
    <div
      style={
        nightMode && isReply
          ? nightModeStyle
          : nightMode
          ? nightModeStyleLessDark
          : {}
      }
      className={isReply ? "create-topic create-reply" : "create-topic"}
    >
      {successfullyPosted || isReply ? (
        <h2
          style={nightMode ? nightModeStyleWhite : {}}
          className="heading-secondary center-text margin--none"
        >
          {successfullyPosted ? "Posted!" : "Comment"}
        </h2>
      ) : (
        <>
          <div
            role="button"
            onClick={(event) => {
              event.preventDefault();
              setIsExpanded(!isExpanded);
            }}
            className={
              "flex justify-content--center align-items--center flex--gap-normal expand-outline" +
              (nightMode ? " expand-outline-dark" : "")
            }
          >
            <IonIcon
              style={nightMode ? nightModeStyleWhite : {}}
              className="battle-icon--small"
              icon={isExpanded ? caretDownOutline : caretForwardOutline}
            ></IonIcon>
            <p role="button" className="description--larger bold center-text">
              Start a Discussion
            </p>
          </div>
          {isExpanded ? (
            <p className="description--smaller center-text italic max-width--readable-center">
              Before posting a question, kindly review the FAQ section on the{" "}
              <a
                style={{ color: "inherit", textDecoration: "none" }}
                href="https://www.writingbattle.com/rules"
                className="clickable-text"
                target="_blank"
              >
                Rules
              </a>{" "}
              page, as the information you seek may already be available there.
            </p>
          ) : null}
        </>
      )}
      {isExpanded || isReply ? (
        <>
          <br />
          <p className="description center-text">{errorMessage}</p>
          <div className="register-form">
            {isReply || successfullyPosted ? (
              <div></div>
            ) : (
              <div>
                <label htmlFor="discussionTitle">Discussion Title</label>
                <input
                  style={
                    nightMode ? nightModeStyle : { backgroundColor: "#f3f5f2" }
                  }
                  id="discussionTitle"
                  type="text"
                  placeholder="Title goes here..."
                  required
                  value={topicTitle}
                  onChange={onTopicTitleChange}
                />
              </div>
            )}
            {successfullyPosted ? (
              <div></div>
            ) : (
              <>
                {isReply && replyToMessageId ? (
                  <>
                    <div className="flex justify-content--end">
                      <div
                        role="button"
                        className="flex align-items--center cursor--pointer underline-on-hover"
                        onClick={() => {
                          setReplyToMessageId("");
                        }}
                      >
                        <p className="description--smaller">Remove Reply</p>
                        <IonIcon
                          style={nightMode ? nightModeStyleWhite : {}}
                          className="battle-icon--small"
                          icon={closeOutline}
                        />
                      </div>
                    </div>
                    <ReplyToMessage
                      replyToMessageId={replyToMessageId}
                      isWritingReply={true}
                      nightMode={nightMode}
                    />
                  </>
                ) : null}
                <div className={"forum-create-topic-wrapper"}>
                  <ForumQuillWrapper
                    content={content}
                    contentHandler={contentHandler}
                    isReply={isReply ? true : false}
                    isReadOnly={isLoading}
                    nightMode={nightMode}
                  />
                </div>
                <button
                  onClick={onTopicCreate}
                  className="btn btn--register min-width--even-larger align-self--end"
                  disabled={isLoading || !changesMade}
                >
                  {isReply ? "Post Comment" : "Create Discussion"}
                  <div className={isLoading ? "simple-loader" : "hidden"}>
                    <Lottie
                      loop
                      animationData={loadingAnimation}
                      speed={1.5}
                      play
                    />
                  </div>
                </button>
                <TagUsers
                  myUserId={userId}
                  suggestedUserIds={suggestedUserIds}
                  listOfTaggedUserIds={listOfTaggedUsers}
                  handleTagUser={handleTagUser}
                  nightMode={nightMode}
                />
              </>
            )}
          </div>
        </>
      ) : null}
    </div>
  );
}

export default CreateTopic;
