import DefaultProfilePicture from "../img/profile_picture.png";
import DefaultProfileWhite from "../img/profile_picture_white.png";
import { getTrophyImageUrlByFileName } from "../publicFunctions/publicHelpers/imageUrlBuilder";

function BasicUserInfo({ publicUserInfo, isTiny, truncateName, nightMode }) {
  const trophyFileName1 = publicUserInfo?.trophiesDisplayed?.trophy1?.fileName;
  const trophyFileName2 = publicUserInfo?.trophiesDisplayed?.trophy2?.fileName;
  return (
    <a
      role="button"
      style={{ textDecoration: "none", color: "inherit" }}
      href={"/profile/" + publicUserInfo.pageName}
      target="_blank"
      onClick={(event) => {
        // event.preventDefault();
        event.stopPropagation();
        // window.open("/profile/" + publicUserInfo.pageName, "_blank");
      }}
      className="basic-user-info break-long-words"
    >
      <div className="basic-user-img-wrapper">
        <img
          className={
            isTiny ? "basic-user-info--img-small" : "basic-user-info--img"
          }
          src={
            publicUserInfo?.profilePic
              ? publicUserInfo.profilePic
              : nightMode
              ? DefaultProfileWhite
              : DefaultProfilePicture
          }
        />
        {trophyFileName1 && !isTiny ? (
          <div
            className={
              !trophyFileName2 ? "basic-user-trophy" : "basic-user-trophy--left"
            }
          >
            <img
              className="basic-user-trophy--img"
              src={getTrophyImageUrlByFileName(trophyFileName1)}
            />
          </div>
        ) : null}
        {trophyFileName2 && !isTiny ? (
          <div className="basic-user-trophy">
            <img
              className="basic-user-trophy--img"
              src={getTrophyImageUrlByFileName(trophyFileName2)}
            />
          </div>
        ) : null}
      </div>
      <p
        className={
          (isTiny
            ? "description--smallest description--smaller-set-width"
            : "description mobile-wide-smaller-text") + " center-text"
        }
      >
        {publicUserInfo?.name
          ? isTiny && truncateName
            ? publicUserInfo.name.slice(0, 8) +
              (publicUserInfo.name.length > 8 ? "..." : "")
            : publicUserInfo.name
          : ""}
      </p>
    </a>
  );
}

export default BasicUserInfo;
