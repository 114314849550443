import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Lottie from "react-lottie-player";
import loadingAnimation from "../img/animations/loadinganimation.json";
import SEO from "../components/SEO";
import { joinGiftedBattle, receiveDigitalDeck } from "../config/firebase";
import { nightModeStyleWhite } from "../publicFunctions/publicHelpers/helpers";

const ERROR_MSG =
  "Something went wrong. Please try again in a few minutes or email admin@writingbattle.com";

const STANDARD_GIFT_SUCCESS_MSG =
  "You have successfully signed up for the Battle! Please look out for a confirmation email sent to your inbox. Are you brand new to Writing Battle? Please familiarize yourself with the rules by navigating to the Rules section in the top menu.";
const STANDARD_DIGITAL_DECK_SUCCESS_MSG =
  "You have successfully added a digital deck to your Writing Battle account! You can head to the Tavern now to use the deck.";

const STANDARD_GIFT_IN_ORDER_TO_MSG =
  "In order to join with your Battle Ticket";
const STANDARD_DIGITAL_DECK_IN_ORDER_TO_MSG =
  "In order to add this Digital Deck to your account";

function GiftedBattle({
  userInfo,
  openLogInHandler,
  typeOfTicket = "Gift",
  nightMode,
}) {
  const params = useParams();
  const [giftCode] = useState(params.giftCode);
  const [errMessage, setErrMessage] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [signingUpForBattle, setSigningUpForBattle] = useState(false);
  const [successfullyCompletedTask, setSuccessfullyCompletedTask] =
    useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (!userInfo?.isStillCheckingLogin()) {
      setIsLoading(false);
      if (!userInfo.isLoggedIn()) {
        openLogInHandler("register");
        setErrMessage("");
      } else {
        // we are logged in, so we need to initialize the sign up!
        setSigningUpForBattle(true);
        if (typeOfTicket === "Digital Deck") {
          receiveDigitalDeck({ digitalDeckCode: giftCode })
            .then((result) => {
              if (result.data.success) {
                setSuccessfullyCompletedTask(true);
              } else if (result.data.reason) {
                setErrMessage(result.data.reason);
              } else {
                setErrMessage(ERROR_MSG);
              }
            })
            .catch((err) => {
              setErrMessage(ERROR_MSG);
            })
            .finally(() => {
              setSigningUpForBattle(false);
            });
        } else {
          joinGiftedBattle({ giftCode })
            .then((result) => {
              if (result.data.success) {
                setSuccessfullyCompletedTask(true);
              } else if (result.data.reason) {
                setErrMessage(result.data.reason);
              } else {
                setErrMessage(ERROR_MSG);
              }
            })
            .catch((err) => {
              setErrMessage(ERROR_MSG);
            })
            .finally(() => {
              setSigningUpForBattle(false);
            });
        }
      }
    }
  }, [userInfo]);

  let successMsg;
  let inOrderToMsg;
  if (typeOfTicket === "Digital Deck") {
    successMsg = STANDARD_DIGITAL_DECK_SUCCESS_MSG;
    inOrderToMsg = STANDARD_DIGITAL_DECK_IN_ORDER_TO_MSG;
  } else {
    // GIFT
    successMsg = STANDARD_GIFT_SUCCESS_MSG;
    inOrderToMsg = STANDARD_GIFT_IN_ORDER_TO_MSG;
  }

  return (
    <div
      className={
        "padding-top-bot--md " +
        (nightMode ? "hero-background--black" : "hero-background")
      }
    >
      <div style={nightMode ? nightModeStyleWhite : {}} className="container">
        <SEO
          title={typeOfTicket + " | Writing Battle"}
          description=""
          name="Writing Battle"
          type="website"
        />
        <p
          style={nightMode ? nightModeStyleWhite : {}}
          className="heading-secondary center-text bold margin-bottom-sm"
        >
          {isLoading && !userInfo.isLoggedIn()
            ? "Loading..."
            : signingUpForBattle
            ? "Please wait one moment..."
            : errMessage
            ? "Hmmm..."
            : successfullyCompletedTask
            ? "Success!"
            : "Welcome!"}
        </p>
        {isLoading || signingUpForBattle ? (
          <div className="flex center-flex justify-content--center">
            <Lottie
              loop
              animationData={loadingAnimation}
              play
              style={{ width: "5rem", height: "5rem" }}
            ></Lottie>
          </div>
        ) : null}
        <p className="description center-text max-width--readable-center margin-bottom-md">
          {successfullyCompletedTask
            ? successMsg
            : errMessage
            ? errMessage
            : !userInfo?.isLoggedIn() && !userInfo.isStillCheckingLogin()
            ? inOrderToMsg +
              ", you must Register with an account. If you already have an account, then please Log In."
            : ""}
        </p>
        <div className="hp-buttons-wrapper">
          {!userInfo?.isLoggedIn() && !userInfo.isStillCheckingLogin() ? (
            <>
              <button
                onClick={() => {
                  navigate("/join-battle");
                }}
                className="login-btn"
              >
                Register
              </button>
              <p className="description center-text">- OR -</p>
              <button
                onClick={() => openLogInHandler("login")}
                className="login-btn"
              >
                Log In
              </button>
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default GiftedBattle;
