import { giftOutline } from "ionicons/icons";
import { IonIcon } from "@ionic/react";
import { nightModeStyleWhite } from "../../publicFunctions/publicHelpers/helpers";

function SwitchToGifting({ giftingMode, handleGiftingClicked, nightMode }) {
  return (
    <div
      style={nightMode ? nightModeStyleWhite : {}}
      className="flex justify-content--center"
    >
      {!giftingMode ? (
        <div
          onClick={handleGiftingClicked}
          className="flex flex--align-center flex--gap-smaller cursor--pointer"
        >
          <p className="description clickable-text description-sm--mobile">
            Interested in gifting a Battle to a friend?
          </p>
          <IonIcon
            style={nightMode ? nightModeStyleWhite : {}}
            className="battle-icon--small"
            icon={giftOutline}
          />
        </div>
      ) : (
        <div>
          <p
            onClick={handleGiftingClicked}
            className="description clickable-text"
          >
            Cancel Gifting-mode
          </p>
        </div>
      )}
    </div>
  );
}

export default SwitchToGifting;
