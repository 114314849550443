import ReCAPTCHA from "react-google-recaptcha";
import Lottie from "react-lottie-player";
import loadingAnimation from "../../img/animations/simpleLoading.json";
import ThreadedLogo from "../../img/WritingBattleLogo_800.webp";
import { useRef } from "react";
import { newsletterSignUp } from "../../config/firebase";
import { useState } from "react";
import Reveal from "../animations/Reveal";
import {
  nightModeStyleLessDark,
  nightModeStyleLessDarkBackgroundOnly,
} from "../../publicFunctions/publicHelpers/helpers";

function CTASection({ nightMode }) {
  const [firstName, setFirstName] = useState("");
  const [email, setEmail] = useState("");
  const [whereDidYouHear, setWhereDidYouHear] = useState("");
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const recaptchaRef = useRef();

  const onSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    setIsDisabled(true);
    recaptchaRef.current
      .executeAsync()
      .then((token) => {
        newsletterSignUp({ firstName, email, whereDidYouHear, token })
          .then((result) => {
            const returnMessage = result.data;
            setMessage(returnMessage);
            if (
              !returnMessage.includes("Success") &&
              !returnMessage.includes("Thanks")
            ) {
              setIsDisabled(false);
            }
          })
          .catch((err) => {
            setIsDisabled(false);
            setMessage(err.message);
          })
          .finally(() => {
            setIsLoading(false);
          });
      })
      .catch((err) => {
        setIsLoading(false);
        setIsDisabled(false);
        setMessage(err.message);
      });
  };

  const onFirstNameChange = (event) => {
    setFirstName(event.target.value);
  };

  const onEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const whereDidYouHearChange = (event) => {
    setWhereDidYouHear(event.target.value);
  };

  return (
    <section className="section-cta">
      <div className="container">
        <Reveal type="fromLeft" fadeIn={true}>
          <div className="cta">
            <div className="cta-text-box">
              <h2 className="heading-secondary">Never miss a battle!</h2>
              <p className="cta-text">
                Subscribe to the Writing Battle monthly newsletter. We highlight
                discussions, writers, and short stories from our community and
                let you know when a battle is imminent. Emails are never shared
                with a third party.
              </p>

              <form className="cta-form" onSubmit={onSubmit}>
                <div>
                  <label htmlFor="full-name">First Name</label>
                  <input
                    style={nightMode ? nightModeStyleLessDark : {}}
                    id="full-name"
                    type="text"
                    placeholder="Jane"
                    required
                    onChange={onFirstNameChange}
                    value={firstName}
                  />
                </div>

                <div>
                  <label htmlFor="email">Email Address</label>
                  <input
                    style={nightMode ? nightModeStyleLessDark : {}}
                    id="email"
                    type="email"
                    placeholder="me@example.com"
                    required
                    onChange={onEmailChange}
                    value={email}
                  />
                </div>

                <div>
                  <label htmlFor="select-where">
                    Where did you hear about us?
                  </label>
                  <select
                    style={nightMode ? nightModeStyleLessDark : {}}
                    name=""
                    id="select-where"
                    required
                    onChange={whereDidYouHearChange}
                    value={whereDidYouHear}
                  >
                    <option value="">Please choose one option:</option>
                    <option value="googleAd">Google Ad</option>
                    <option value="facebook">Facebook</option>
                    <option value="instagram">Instagram</option>
                    <option value="twitter">Twitter</option>
                    <option value="writersOnlineNewsletter">
                      Writers Online Newsletter
                    </option>
                    <option value="friends">Friends and family</option>
                    <option value="nycm">NYCM forums</option>
                    <option value="searchEngine">Search engine</option>
                    <option value="other">Other</option>
                  </select>
                </div>
                <button
                  className="btn btn--form"
                  disabled={isLoading || isDisabled}
                >
                  Join mailing list
                  <div className={isLoading ? "simple-loader" : "hidden"}>
                    <Lottie
                      loop
                      animationData={loadingAnimation}
                      speed={1.5}
                      play
                    ></Lottie>
                  </div>
                </button>
              </form>
              <p className="description margin-top-smaller">{message}</p>
              <div>
                <ReCAPTCHA
                  ref={recaptchaRef}
                  size="invisible"
                  sitekey="6LfnWFslAAAAAB-TcxUWGVHSo2yRplMeRFIonhUb"
                />
              </div>
            </div>
            <div className="cta-img-box">
              <img className="cta-img" src={ThreadedLogo} alt="" />
            </div>
          </div>
        </Reveal>
      </div>
    </section>
  );
}

export default CTASection;
