import "../styles/rulesPage.css";
import "../styles/rulesQueries.css";
import { useState, useEffect } from "react";
import PricingSection from "../components/PricingSection";
import RulesBody from "../components/rules/RulesBody";
import {
  determineBattleToLoad,
  getBattleInfo,
} from "../publicFunctions/publicHelpers/helpers";
import LoadingSection from "../components/LoadingSection";
import BasicNav from "../components/BasicNav";
import SEO from "../components/SEO";

function Rules({
  battles,
  openLogInHandler,
  userInfo,
  alreadyJoinedMap,
  nightMode,
}) {
  const [selectedBattle, setSelectedBattle] = useState("");
  const [battleOptions, setBattleOptions] = useState([]);
  const [loadedBattle, setLoadedBattle] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // the first time we have battles then do this
    if (battles.length) {
      const MAX_BATTLES_TO_SHOW = 7;
      const optionsTemp = [];
      for (let i = 0; i < battles.length && i < MAX_BATTLES_TO_SHOW; i++) {
        const battle = battles[i];
        const formattedTitle =
          battle.titlePrefix +
          " " +
          battle.initialDate.getFullYear().toString(10) +
          " " +
          battle.type;
        optionsTemp.push(
          <option key={i} value={formattedTitle}>
            {formattedTitle}
          </option>
        );
      }
      optionsTemp.unshift(
        <option key={-1} value={""}>
          {"Select a Battle"}
        </option>
      );
      setBattleOptions(optionsTemp);
      setLoadedBattle(
        getBattleInfo(determineBattleToLoad(battles, alreadyJoinedMap))
      );
      setIsLoading(false);
    }
  }, [battles, alreadyJoinedMap]);

  const selectedBattleHandler = (e) => {
    const newValue = e.target.value;
    if (newValue && battles.length) {
      setSelectedBattle(newValue);

      const battle = determineBattleFromDropdown(battles, newValue);
      const battleInfo = getBattleInfo(battle);
      setLoadedBattle(battleInfo);
    }
  };

  return (
    <div className={nightMode ? "hero-background--black" : "hero-background"}>
      <SEO
        title="Rules | Writing Battle"
        description="Brush up on the rules of Writing Battle."
        name="Writing Battle"
        type="website"
      />
      <div>
        {!isLoading && battleOptions?.length && loadedBattle ? (
          <div>
            <BasicNav
              battleOptions={battleOptions}
              selectedBattle={selectedBattle}
              selectedBattleHandler={selectedBattleHandler}
              title={"Rules"}
              nightMode={nightMode}
            />
            <RulesBody
              battleInfo={loadedBattle}
              userInfo={userInfo}
              peerJudged={loadedBattle.rawTuple.peerJudged ? true : false}
              nightMode={nightMode}
            />
          </div>
        ) : (
          <LoadingSection nightMode={nightMode} />
        )}
      </div>
      <PricingSection
        battles={battles}
        hideTitle={true}
        openLogInHandler={openLogInHandler}
        userInfo={userInfo}
        alreadyJoinedMap={alreadyJoinedMap}
        nightMode={nightMode}
      />
    </div>
  );
}

function determineBattleFromDropdown(battles, dropdownText) {
  let pick = battles[0];
  battles.forEach((battle) => {
    const tempText =
      battle.titlePrefix +
      " " +
      battle.initialDate.getFullYear().toString(10) +
      " " +
      battle.type;
    if (tempText === dropdownText) {
      pick = battle;
    }
  });
  return pick;
}

export default Rules;
