import { useState } from "react";
import { useEffect } from "react";
import {
  getRaids,
  unknownCardImageFileName,
} from "../../publicFunctions/tavernHandler";
import {
  convertTimestampToObject,
  getSimpleTimeInUserTimeZone,
  nightModeStyle,
} from "../../publicFunctions/publicHelpers/helpers";
import { getCardImageUrlByCard } from "../../publicFunctions/publicHelpers/imageUrlBuilder";
import Countdown from "../battle/Countdown";
import Raid from "./Raid";

function Raids({
  myProfile,
  myCards,
  debriefMeta,
  setLoadedStory,
  requestMyCards,
  nightMode,
}) {
  const [raids, setRaids] = useState([]);
  const [renderedRaidList, setRenderedRaidList] = useState(null);

  useEffect(() => {
    // request cards
    if (myProfile?.userId) {
      const override = true;
      requestMyCards(myProfile.userId, override);
    }

    // get raids
    if (!raids?.length) {
      getRaids()
        .then((tempRaids) => {
          if (tempRaids?.length) {
            setRaids(tempRaids);
          }
        })
        .catch((err) => {});
    }
  }, [myProfile]);

  useEffect(() => {
    // note: even if the user doesn't have any cards it will still be an object
    if (raids?.length && myCards) {
      const tempOrderedRaids = [...raids];
      const completedRaidAtSomePointMap = new Map();
      for (let i = 0; i < raids.length; i++) {
        const raid = raids[i];
        const raidName = raid.name;
        if (raidName && raid.id) {
          if (raidName in myCards) {
            completedRaidAtSomePointMap.set(raid.id, true);
          }
        }
      }

      // sort the objects
      tempOrderedRaids.sort((a, b) => {
        const dateUnlockedA = a.dateUnlocked ? a.dateUnlocked : new Date();
        const dateUnlockedB = b.dateUnlocked ? b.dateUnlocked : new Date();
        const idA = a.id ? a.id : "";
        const idB = b.id ? b.id : "";
        if (
          completedRaidAtSomePointMap.has(idB) &&
          !completedRaidAtSomePointMap.has(idA)
        ) {
          return -1;
        } else if (
          completedRaidAtSomePointMap.has(idA) &&
          !completedRaidAtSomePointMap.has(idB)
        ) {
          return 1;
        } else {
          return dateUnlockedB.getTime() - dateUnlockedA.getTime();
        }
      });

      // now we want to inject the debriefMeta upcoming raids between the raids completed and the raids not
      if (debriefMeta?.nextRaids?.length) {
        const nextRaids = debriefMeta.nextRaids;
        let index = 0;
        for (let i = 0; i < tempOrderedRaids.length; i++) {
          if (completedRaidAtSomePointMap.has(tempOrderedRaids[i].id)) {
            index = i;
            break;
          } else {
            index++;
          }
        }
        // insert the next raids here
        for (let i = nextRaids.length - 1; i >= 0; i--) {
          tempOrderedRaids.splice(index, 0, nextRaids[i]);
        }
      }

      // now go through the tempOrderedRaids list and render them
      const tempRendered = [];
      for (let i = 0; i < tempOrderedRaids.length; i++) {
        const raid = tempOrderedRaids[i];
        if (!raid) {
          continue;
        }
        if (raid.unlocked) {
          // raid is unlocked
          tempRendered.push(
            <div key={i.toString(10) + "_raid"}>
              <Raid
                raid={raid}
                completedRaidAtSomePoint={completedRaidAtSomePointMap.has(
                  raid.id
                )}
                myProfile={myProfile}
                setLoadedStory={setLoadedStory}
                nightMode={nightMode}
              />
            </div>
          );
        } else if (raid.startDate) {
          // raid is "next"
          const renderedTinyCards = [];
          if (raid.numberOfHiddenCards) {
            for (let j = 0; j < raid.numberOfHiddenCards; j++) {
              renderedTinyCards.push(
                <img
                  className="debrief-genre-title-card"
                  key={j.toString(10) + "_" + i.toString(10) + "_unknownImg"}
                  src={getCardImageUrlByCard(
                    { imageFileName: unknownCardImageFileName },
                    true
                  )}
                />
              );
            }
            for (
              let j = 0;
              raid.alreadyPublicLoot && j < raid.alreadyPublicLoot.length;
              j++
            ) {
              renderedTinyCards.push(
                <img
                  className="debrief-genre-title-card"
                  key={j.toString(10) + "_" + i.toString(10) + "_img"}
                  src={getCardImageUrlByCard(
                    { imageFileName: raid.alreadyPublicLoot[j].imageFileName },
                    true
                  )}
                />
              );
            }
          }
          tempRendered.push(
            <div key={i.toString(10) + "_raid"} className="raid-container">
              <h2 className="heading-secondary margin--none center-text">
                Incoming Raid!
              </h2>

              <p className="description--large">
                {getSimpleTimeInUserTimeZone(raid.startDate)}
              </p>
              <div className="max-width--larger">
                <Countdown date={raid.startDate} />
              </div>
              <p className="description--larger bold margin--none center-text">
                Loot:
              </p>
              <p className="description--small center-text italic">
                Please note that brand new cards [?] are kept secret until the
                Raid is available.
              </p>
              <div>{renderedTinyCards}</div>
            </div>
          );
        }
      }
      setRenderedRaidList(tempRendered);
    }
  }, [myCards, raids, debriefMeta, nightMode]);

  return (
    <div style={nightMode ? nightModeStyle : {}} className="container">
      <p className="description--large bold center-text margin-top-bigger">
        Welcome!
      </p>
      <p className="description center-text max-width--readable-center">
        There's no min/max word count to earn the loot from a Raid. There's also
        no time limit and when they're available, Raids never expire! These are
        meant to be pretty chill and to be done at your own leisure. Have fun :
        {")"}
      </p>
      <div className="raid-list">{renderedRaidList}</div>
    </div>
  );
}

export default Raids;
