import { useEffect, useRef, useState } from "react";
import StoryPromptsSelector from "./StoryPromptsSelector";
import MyQuillWrapper from "../battle/MyQuillWrapper";
import Lottie from "react-lottie-player";
import loadingAnimation from "../../img/animations/loadinganimation.json";
import {
  MAXIMUM_DEFINITION_CHARACTERS,
  MAXIMUM_TITLE_CHARACTERS,
} from "../../publicFunctions/tavernHandler";
import MyDebriefStory from "./MyDebriefStory";
import { updateTavernStory } from "../../config/firebase";
import {
  dayModeDefault,
  dayModeStyleBlack,
  nightModeStyle,
  nightModeStyleLessDark,
  nightModeStyleWhite,
  pageScrollSettings,
} from "../../publicFunctions/publicHelpers/helpers";
import { getCustomWordCount } from "../../publicFunctions/publicHelpers/quillHelpers";

function UploadTavernStoryEditor({
  myProfile,
  myCards,
  collectionMeta,
  requestCollectionMeta,
  requestMyCards,
  handleUploadRefresh,
  loadedStory,
  isWorking,
  setIsWorking,
  raidObject,
  nightMode,
}) {
  const [changesMade, setChangesMade] = useState(false);
  const [firstCard, setFirstCard] = useState(null);
  const [secondCard, setSecondCard] = useState(null);
  const [thirdCard, setThirdCard] = useState(null);
  const [storyContent, setStoryContent] = useState(null);
  const [storyTitle, setStoryTitle] = useState("");
  const [promptMessage, setPrompMessage] = useState("");
  const [message, setMessage] = useState("");
  const [genreDefinition, setGenreDefinition] = useState("");

  // This is a weird one. When you load a story, we don't want a change to be made the first time
  const [atLeastOneContentChange, setAtLeastOneContentChange] = useState(false);
  // const myCounter = useRef(null);

  // new stuff
  const quillRef = useRef(null);
  const [wordCount, setWordCount] = useState(0);

  // Update word count on mount and on content change
  useEffect(() => {
    const editor = quillRef?.current?.getEditor();
    if (editor) {
      const updateWordCount = () => {
        const wordCount = getCustomWordCount(editor);
        setWordCount(wordCount);
      };

      // Listen for 'text-change' events to update word count
      editor.on("text-change", updateWordCount);

      // Cleanup the event listener when the component unmounts
      return () => {
        editor.off("text-change", updateWordCount);
      };
    }
  }, []);

  // end of new stuff

  const choosePromptTitleRef = useRef(null);

  const clearMessages = () => {
    setMessage("");
    setPrompMessage("");
  };

  const promptCardHandler = (cardPosition, id, type, text, definition = "") => {
    let setFunc;
    if (cardPosition === "first") {
      setFunc = setFirstCard;
      if (definition) {
        setGenreDefinition(definition);
      }
    } else if (cardPosition === "second") {
      setFunc = setSecondCard;
    } else if (cardPosition === "third") {
      setFunc = setThirdCard;
    }
    if (setFunc) {
      setFunc({
        id: id ? id : "",
        type: type ? type : "",
        text: text ? text : "",
      });

      // If we are loading story then we don't want to say that
      // things have changed when they have not
      if (
        !loadedStory?.storyId ||
        !loadedStory?.cards ||
        (loadedStory.cards &&
          changesMadeToThisCard(
            loadedStory.cards,
            cardPosition,
            id,
            text,
            type
          ))
      ) {
        setChangesMade(true);
        clearMessages();
      }
    }
  };

  const storyContentHandler = (newValue) => {
    if (newValue) {
      // changes aren't made on load
      if (atLeastOneContentChange) {
        setChangesMade(true);
      } else {
        setAtLeastOneContentChange(true);
      }
      clearMessages();
      setStoryContent(newValue);
      // added code
      const editor = quillRef?.current?.getEditor();
      if (editor) {
        const wordCount = getCustomWordCount(editor); // Get the custom word count
        setWordCount(wordCount); // Update word count state
      }
    }
  };

  const onStoryTitleChange = (event) => {
    if (atLeastOneContentChange) {
      setChangesMade(true);
    } else {
      setAtLeastOneContentChange(true);
    }
    setStoryTitle(event.target.value);
    clearMessages();
  };

  useEffect(() => {
    if (loadedStory) {
      if (loadedStory.storyContent) {
        setStoryContent(loadedStory.storyContent);
      }
      if (loadedStory.storyTitle) {
        setStoryTitle(loadedStory.storyTitle);
      }
      if (!loadedStory.storyId) {
        if (choosePromptTitleRef?.current) {
          choosePromptTitleRef.current.scrollIntoView(pageScrollSettings);
        }
      }
    } else {
      // TODO: (important) CLEAR EVERYTHING
    }
  }, [loadedStory]);

  const onSubmit = async (event) => {
    event.preventDefault();

    // do all checks
    if (!firstCard?.id || !secondCard?.id || !thirdCard?.id) {
      setPrompMessage(
        "Warning: Changes not saved. You must select any three cards for this story."
      );
      if (choosePromptTitleRef?.current) {
        choosePromptTitleRef.current.scrollIntoView(pageScrollSettings);
      }
      return;
    }
    if (!storyTitle) {
      setMessage("Warning: Changes not saved. You must enter a story title.");
      return;
    }
    if (!storyContent) {
      setMessage("Warning: Changes not saved. You must write a story.");
      return;
    }

    setIsWorking(true);
    try {
      const { data } = await updateTavernStory({
        storyId: loadedStory.storyId,
        storyContent,
        storyTitle,
        firstCard,
        secondCard,
        thirdCard,
        genreDefinition,
        raidId: raidObject ? raidObject.id : "",
      });
      if (data.success) {
        setMessage("Successfully saved!");
        handleUploadRefresh(data.updatedStory);
        setTimeout(() => {
          setIsWorking(false);
          setChangesMade(false);
        }, 2500);
      }
    } catch (err) {
      if (err.message) {
        setMessage(err.message);
        setIsWorking(false);
      }
    }
  };

  return (
    <div>
      <div
        style={nightMode ? nightModeStyle : dayModeDefault}
        className="brown-background--lighterer"
      >
        <StoryPromptsSelector
          myProfile={myProfile}
          myCards={myCards}
          collectionMeta={collectionMeta}
          requestCollectionMeta={requestCollectionMeta}
          requestMyCards={requestMyCards}
          promptCardHandler={promptCardHandler}
          loadedStory={loadedStory}
          choosePromptTitleRef={choosePromptTitleRef}
          promptMessage={promptMessage}
          raidObject={raidObject}
          nightMode={nightMode}
        />
        <div className="margin-top-bigger">
          <div className="container">
            <h2
              style={nightMode ? nightModeStyleWhite : dayModeStyleBlack}
              className="heading-secondary center-text margin--none"
            >
              {raidObject ? "Step 1 of 2" : "Step 2 of 3"}: Edit Story Details
            </h2>
            <p className="description center-text">
              Note: Stories that you save are NOT automatically shared in the
              Tavern.
            </p>
            {raidObject ? (
              <p className="description--small center-text italic">
                You do not have to share your Raid story in the Tavern to earn
                the loot, but what's the fun in that?
              </p>
            ) : null}
            <div className="first-stage-container">
              <div className="story-submit-controls register-form flex">
                <div className="flex flex--direction-column flex--gap-normal">
                  <div>
                    <label htmlFor="storyTitleEl" disabled={isWorking}>
                      Title (*required)
                    </label>
                    <input
                      style={
                        nightMode ? nightModeStyleLessDark : dayModeDefault
                      }
                      disabled={isWorking}
                      id="storyTitleEl"
                      type="text"
                      required
                      value={storyTitle}
                      onChange={onStoryTitleChange}
                      className="story-title-input"
                      maxLength={MAXIMUM_TITLE_CHARACTERS}
                    />
                  </div>
                  {!raidObject ? (
                    <div>
                      <label htmlFor="genreDefinition" disabled={isWorking}>
                        Genre Definition or Logline
                      </label>
                      <input
                        style={
                          nightMode ? nightModeStyleLessDark : dayModeDefault
                        }
                        disabled={isWorking}
                        id="genreDefinition"
                        type="text"
                        value={genreDefinition}
                        onChange={(event) => {
                          event.preventDefault();
                          setGenreDefinition(event.target.value);
                          clearMessages();
                        }}
                        className="story-title-input"
                        maxLength={MAXIMUM_DEFINITION_CHARACTERS}
                      />
                    </div>
                  ) : null}
                </div>
                <button
                  onClick={() =>
                    window.open(
                      "/story/tavern/" + loadedStory.storyId,
                      "_blank"
                    )
                  }
                  className="btn btn--register min-width--larger story-submission-btn"
                  disabled={isWorking || changesMade || !loadedStory?.storyId}
                >
                  View
                </button>
                <button
                  className="btn btn--register min-width--larger story-submission-btn"
                  disabled={isWorking || !changesMade}
                  onClick={onSubmit}
                >
                  Save
                  <div className={isWorking ? "simple-loader" : "hidden"}>
                    <Lottie
                      loop
                      animationData={loadingAnimation}
                      speed={1.5}
                      play
                    ></Lottie>
                  </div>
                </button>
              </div>
              {message ? (
                <p className="description bold red center-text">{message}</p>
              ) : null}

              <MyQuillWrapper
                reference={quillRef}
                storyContent={storyContent}
                storyContentHandler={storyContentHandler}
                isReadOnly={false}
              />
              <div>
                <p className="description">Official Word Count: {wordCount}</p>
              </div>
              <h2
                style={nightMode ? nightModeStyleWhite : dayModeStyleBlack}
                className="heading-secondary center-text margin-top-bottom-md"
              >
                {raidObject ? "Step 2 of 2" : "Step 3 of 3"}: Share Story in
                Tavern
              </h2>
              {!loadedStory?.storyId ? (
                <p className="description center-text">
                  Please save your story before adding content warnings and
                  other options. You can find the save button above your story
                  content. Stories that are saved are NOT automatically shared
                  in the Tavern. After saving, you must come back here to Step 3
                  to share with your peers (of course, this is completely
                  optional).
                </p>
              ) : null}
              {changesMade && loadedStory?.storyId ? (
                <p className="description center-text">
                  Please save any changes before sharing story. You can find the
                  save button above your story content.
                </p>
              ) : null}
              {!changesMade && loadedStory?.storyId ? (
                <MyDebriefStory
                  myProfile={myProfile}
                  resetListHandler={() => {}}
                  isTavern={true}
                  myUploadedStory={loadedStory}
                  refreshMyUploadedStory={(updatedStory) => {
                    if (updatedStory) {
                      handleUploadRefresh(updatedStory);
                    }
                  }}
                  nightMode={nightMode}
                />
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function changesMadeToThisCard(cards, cardPosition, id, text, type) {
  if (!cards?.genre || !cards.prompt1 || !cards.prompt2) {
    return true;
  }
  if (cardPosition === "first") {
    return (
      cards.genre.id !== id ||
      cards.genre.text !== text ||
      cards.genre.type !== type
    );
  } else if (cardPosition === "second") {
    return (
      cards.prompt1.id !== id ||
      cards.prompt1.text !== text ||
      cards.prompt1.type !== type
    );
  } else if (cardPosition === "third") {
    return (
      cards.prompt2.id !== id ||
      cards.prompt2.text !== text ||
      cards.prompt2.type !== type
    );
  }
  return true;
}

export default UploadTavernStoryEditor;
