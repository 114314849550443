import { useState } from "react";
import Lottie from "react-lottie-player";
import loadingAnimation from "../../img/animations/simpleLoading.json";
import { logInWithEmailAndPassword } from "../../publicFunctions/registerHandler";
import { nightModeStyle } from "../../publicFunctions/publicHelpers/helpers";

function LogIn({ switchToForgotPasswordPage, nightMode }) {
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const onSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    try {
      await logInWithEmailAndPassword(email, password);
      setPassword("");
      setIsLoading(false);
    } catch (err) {
      let message = "";
      if (err && err.message.includes("user-not-found")) {
        message = "Incorrect email or password.";
      } else if (err && err.message.includes("wrong-password")) {
        message = "Incorrect password. Please try again.";
      } else {
        message =
          "Hmmm that didn't seem to work. Please check your \
        email and password and try again.";
      }
      setPassword("");
      setErrorMessage(message);
      setIsLoading(false);
    }
  };
  const onEmailChange = (event) => {
    setEmail(event.target.value);
  };
  const onPasswordChange = (event) => {
    setPassword(event.target.value);
  };

  return (
    <div>
      <form className="register-form" onSubmit={onSubmit}>
        <div>
          <label htmlFor="loginEmail">Email</label>
          <input
            style={nightMode ? nightModeStyle : {}}
            autoFocus
            id="loginEmail"
            type="email"
            placeholder="me@example.com"
            required
            value={email}
            onChange={onEmailChange}
          />
        </div>
        <div>
          <label htmlFor="loginPassword">Password</label>
          <input
            style={nightMode ? nightModeStyle : {}}
            id="loginPassword"
            type="password"
            placeholder="password"
            required
            value={password}
            onChange={onPasswordChange}
          />
        </div>
        <div className="align-self--end">
          <a
            onClick={switchToForgotPasswordPage}
            className="description--smaller underline right-text clickable"
          >
            Forgot password?
          </a>
        </div>
        {errorMessage ? (
          <p className="description error-text">{errorMessage}</p>
        ) : null}
        <button className="btn btn--register" disabled={isLoading}>
          Log In
          <div className={isLoading ? "simple-loader" : "hidden"}>
            <Lottie
              loop
              animationData={loadingAnimation}
              speed={1.5}
              play
            ></Lottie>
          </div>
        </button>
      </form>
    </div>
  );
}

export default LogIn;
