import { IonIcon } from "@ionic/react";
import {
  getSuperSimpleEstWithTime,
  getSimplestRangePossible,
  nightModeStyleWhite,
} from "../../publicFunctions/publicHelpers/helpers";

function GlanceContainer({
  icon,
  title,
  dates,
  isOdd,
  description,
  nightMode,
}) {
  return (
    <div className="glance-container">
      <div
        style={
          nightMode && isOdd
            ? { backgroundColor: "#08161c", overflow: "hidden" }
            : nightMode && !isOdd
            ? { backgroundColor: "#112c38", overflow: "hidden" }
            : {}
        }
        className={
          isOdd
            ? "glance-card glance-card--odd"
            : "glance-card glance-card--even"
        }
      >
        <h3
          style={nightMode ? nightModeStyleWhite : {}}
          className="heading-tertiary center-text"
        >
          {title}
        </h3>
        <p
          style={nightMode ? nightModeStyleWhite : {}}
          className="description center-text"
        >
          <em>
            {dates.length > 1
              ? getSimplestRangePossible(dates[0], dates[1])
              : getSuperSimpleEstWithTime(dates[0])}
          </em>
        </p>
        <IonIcon
          style={nightMode ? nightModeStyleWhite : {}}
          className="rules-icon"
          icon={icon}
        />
        <p
          style={nightMode ? nightModeStyleWhite : {}}
          className="description--small center-text"
        >
          {description}
        </p>
      </div>
    </div>
  );
}

export default GlanceContainer;
