import { useRef } from "react";
import {
  nightModeStyleLessDark,
  nightModeStyleWhite,
} from "../../publicFunctions/publicHelpers/helpers";

function PastWinnersNav({
  isLoading,
  selectedYear,
  selectedYearHandler,
  selectedBattle,
  selectedBattleHandler,
  years,
  battles,
  nightMode,
}) {
  const titleRef = useRef(null);
  const yearOptions = years.map((year, index) => {
    return (
      <option key={index} value={year}>
        {year}
      </option>
    );
  });

  const battleOptions = getBattlesForThisYear(selectedYear, battles, years).map(
    (battle, index) => {
      return (
        <option key={index} value={battle}>
          {battle}
        </option>
      );
    }
  );

  function getBattlesForThisYear(year, battles, years) {
    let battleOptions = [];
    if (years.length !== battles.length) {
      console.log("Error. Year does not have battle options.");
      return battleOptions;
    }
    for (let i = 0; i < years.length; i++) {
      if (year === years[i]) {
        battleOptions = battles[i];
        break;
      }
    }
    return battleOptions;
  }

  return (
    <div className="container past-winners-nav">
      <h1
        style={nightMode ? nightModeStyleWhite : {}}
        className="heading-primary"
      >
        Past Winners
      </h1>
      <form className="cta-form past-winners-form" action="#">
        <div className="flex flex--direction-column justify-self--end">
          <label className={nightMode ? "white" : "black"}>Select Year</label>
          <select
            style={nightMode ? nightModeStyleLessDark : {}}
            className="select--small"
            onChange={(event) => {
              if (titleRef.current) {
                titleRef.current.focus();
              }
              selectedYearHandler(event);
            }}
            value={selectedYear}
            disabled={isLoading}
          >
            {yearOptions}
          </select>
        </div>
        <div className="flex flex--direction-column">
          <label className={nightMode ? "white" : "black"}>
            Select Battle Title
          </label>
          <select
            ref={titleRef}
            style={nightMode ? nightModeStyleLessDark : {}}
            onChange={selectedBattleHandler}
            value={selectedBattle}
            disabled={isLoading}
            required
          >
            {battleOptions}
          </select>
        </div>
      </form>
    </div>
  );
}

export default PastWinnersNav;
