import "../styles/home.css";
import "../styles/homeQueries.css";
import HowItWorksSection from "../components/landing/HowItWorksSection";
import JudgesSection from "../components/landing/JudgesSection";
import TestimonialsSection from "../components/landing/TestimonialsSections";
import PricingSection from "../components/PricingSection";
import FeatureSection from "../components/landing/FeatureSection";
import CTASection from "../components/landing/CTASection";
import { useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import HomePageCountdown from "../components/landing/HomePageCountdown";
import MarkYourCalendar from "../components/landing/MarkYourCalendary";
import SEO from "../components/SEO";
import Hero from "../components/landing/Hero";
import { useState } from "react";
import FirstCTA from "../components/landing/FirstCTA";
import MVWSection from "../components/landing/MVWSection";
import StillHaveNotJoined from "../components/landing/StillHaveNotJoined";
import PagePreview from "../components/preview/PagePreview";
import FinalTestimonialSection from "../components/landing/FinalTestimonialSection";
import PeerVSPro from "../components/landing/PeerVSPro";
import { convertAllTimestampsToDatesInObject } from "../publicFunctions/publicHelpers/helpers";

function Home({
  battles,
  openLogInHandler,
  userInfo,
  alreadyJoinedMap,
  removeSEO = false,
  nightMode,
}) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [now] = useState(new Date());
  const [battleForCoutdown, setBattleForCountdown] = useState(null);

  useEffect(() => {
    const resetPasswordEmail = searchParams.get("resetPasswordEmail");
    if (resetPasswordEmail) {
      setTimeout(function () {
        openLogInHandler("forgotPassword");
      }, 1500);
    }
  }, []);

  // Figure out the battle to show
  useEffect(() => {
    if (
      battles?.length &&
      userInfo &&
      !userInfo.isStillCheckingLogin() &&
      alreadyJoinedMap
    ) {
      let battleToUse;
      for (let i = 0; i < battles.length; i++) {
        const tempBattle = battles[i];
        if (
          tempBattle?.submissionDate?.getTime() > now.getTime() &&
          tempBattle.submissionDate.getTime() - now.getTime() < 1814400000
        ) {
          battleToUse = tempBattle;
          convertAllTimestampsToDatesInObject(
            JSON.parse(JSON.stringify(battleToUse))
          );

          break;
        }
      }
      if (battleToUse) {
        setBattleForCountdown(battleToUse);
      }
    }
  }, [battles, userInfo, alreadyJoinedMap]);

  // 3 weeks out
  // const showTheHomePageCountdown =
  //   battles?.length &&
  //   userInfo &&
  //   !userInfo.isStillCheckingLogin() &&
  //   battles[0].submissionDate.getTime() > now.getTime() &&
  //   battles[0].submissionDate.getTime() - now.getTime() < 1814400000 &&
  //   alreadyJoinedMap;

  return (
    <div>
      {!removeSEO ? (
        <SEO
          title="Writing Battle"
          description="The writer's colosseum. 
          A competitive writing platform for storytellers around the world. 
          Every story receives oodles of feedback. 
          Join the most supportive writing community on the web. You up for the challenge?"
          name="Writing Battle"
          type="website"
        />
      ) : null}
      <main
        className={nightMode ? "hero-background--black" : "hero-background"}
      >
        {battleForCoutdown ? (
          <HomePageCountdown
            battle={battleForCoutdown}
            alreadyJoinedMap={alreadyJoinedMap}
            userInfo={userInfo}
            openLogInHandler={openLogInHandler}
            nightMode={nightMode}
          />
        ) : null}
        <Hero
          openLogInHandler={openLogInHandler}
          userInfo={userInfo}
          now={now}
          battles={battles}
          alreadyJoinedMap={alreadyJoinedMap}
          nightMode={nightMode}
        />
        <FirstCTA
          isUnderHero={true}
          userInfo={userInfo}
          openLogInHandler={openLogInHandler}
          now={now}
          nightMode={nightMode}
        />
        <HowItWorksSection now={now} nightMode={nightMode} />
        <JudgesSection nightMode={nightMode} />
        <TestimonialsSection nightMode={nightMode} />
        <PricingSection
          battles={battles}
          openLogInHandler={openLogInHandler}
          userInfo={userInfo}
          alreadyJoinedMap={alreadyJoinedMap}
          nightMode={nightMode}
        />
        <PeerVSPro battles={battles} nightMode={nightMode} />
        <FeatureSection nightMode={nightMode} />
        <CTASection nightMode={nightMode} />
        <MarkYourCalendar battles={battles} nightMode={nightMode} />
        <MVWSection nightMode={nightMode} />
        <PagePreview
          userInfo={userInfo}
          hideLogInButtons={true}
          openLogInHandler={openLogInHandler}
          animated={true}
          page="Tavern"
          nightMode={nightMode}
        />
        <PagePreview
          userInfo={userInfo}
          hideLogInButtons={true}
          openLogInHandler={openLogInHandler}
          animated={true}
          page="Forum"
          nightMode={nightMode}
        />
        <PagePreview
          userInfo={userInfo}
          hideLogInButtons={true}
          openLogInHandler={openLogInHandler}
          animated={true}
          page="Debrief"
          nightMode={nightMode}
        />
        <FinalTestimonialSection nightMode={nightMode} />
        <StillHaveNotJoined
          userInfo={userInfo}
          openLogInHandler={openLogInHandler}
          battles={battles}
          nightMode={nightMode}
        />
      </main>
    </div>
  );
}

export default Home;
