import { useEffect } from "react";
import SupportingLogo from "../../img/logos/writingBattleLogoSupporting_800.png";
import SupportingLogoWhite from "../../img/logos/writingBattleLogoSupportingWhite.webp";
import IconLogo from "../../img/logos/writingBattleLogoFull_800_no_text.webp";
import DayNightToggle from "../DayNightToggle";

function StoryHeader({
  userInfo,
  openLogInHandler,
  setUserHasBeenPromptedToLogin,
  nightMode,
  refreshNightMode,
}) {
  useEffect(() => {
    if (userInfo) {
      if (!userInfo.isLoggedIn() && !userInfo.isStillCheckingLogin()) {
        openLogInHandler("login");
        setUserHasBeenPromptedToLogin(true);
      }
    }
  }, [userInfo]);

  return (
    <div
      className={
        "story-read-header " +
        (nightMode ? "hero-background--black" : "hero-background")
      }
    >
      <div className="flex flex--align-center flex--gap-smaller">
        <DayNightToggle
          refreshNightMode={refreshNightMode}
          nightMode={nightMode}
        />
        <div
          className={
            "flex flex--direction-column " +
            "align-items--center justify-content--center flex--gap-smaller"
          }
        >
          <img
            className="read-icon-logo"
            src={IconLogo}
            alt="Writing Battle logo"
          />
        </div>
      </div>
      <img
        className="read-supporting-logo"
        src={nightMode ? SupportingLogoWhite : SupportingLogo}
        alt="Writing Battle logo"
      />
      {!userInfo ||
      (!userInfo.isStillCheckingLogin() && !userInfo.isLoggedIn()) ? (
        <button
          onClick={(event) => {
            openLogInHandler("login");
            setUserHasBeenPromptedToLogin(true);
          }}
          className="login-btn read-login"
        >
          Log In
        </button>
      ) : (
        <div></div>
      )}
    </div>
  );
}

export default StoryHeader;
