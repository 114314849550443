import { useRef, useState } from "react";
import ReplyList from "./ReplyList";
import CreateTopic from "./CreateTopic";
import DeleteAThing from "./DeleteAThing";
import {
  deleteTopic,
  updateTopicTitle,
} from "../../publicFunctions/forumHandler";
import EditAThing from "./EditAThing";
import { createUUID } from "../../publicFunctions/publicHelpers/helpers";
import Subscribe from "./Subscribe";
import ForumAndDebriefRules from "../siteRules/ForumAndDebriefRules";

function Topic({
  myProfile,
  userId,
  opUserId,
  topicTitle,
  topicId,
  stalePaginationArray,
  searchParams,
  setSearchParams,
  nightMode,
}) {
  const commentRef = useRef(null);
  const [topicTitleEdit, setTopicTitleEdit] = useState(
    topicTitle ? topicTitle : ""
  );
  const [topicTitleReal, setTopicTitleReal] = useState(
    topicTitle ? topicTitle : ""
  );
  const [forceRefresh, setForceRefresh] = useState("");
  const [editingTitle, setEditingTitle] = useState(false);
  const [willNeedToSeek, setWillNeedToSeek] = useState(false);
  const [suggestedUserIds, setSuggestedUserIds] = useState([]);
  const [replyToMessageRequest, setReplyToMessageRequest] = useState(null);

  const handleForceRefresh = () => {
    if (willNeedToSeek) {
      // peek at the last page in the paginationArray
      setForceRefresh("seekLastPage_" + createUUID());
    } else {
      setForceRefresh("normal_" + createUUID());
    }
  };

  const handleToggleEdit = (shouldBeEditing) => {
    setEditingTitle(shouldBeEditing);
  };

  const handleWillNeedToSeek = (seek) => {
    setWillNeedToSeek(seek);
  };

  const handleGatherSuggestedUserIds = (list) => {
    if (list?.length) {
      setSuggestedUserIds(list);
    }
  };

  const handleReplyToMessage = (replyToMessageId, userIdToTag) => {
    setReplyToMessageRequest({ replyToMessageId, userIdToTag });
    if (commentRef?.current) {
      commentRef.current.scrollIntoView();
    }
  };

  const handleClearReplyToMessage = () => {
    setReplyToMessageRequest(null);
  };

  return (
    <div className="container">
      <div
        className={
          "topic-header " +
          (nightMode ? "blue-background--darker" : "yellow-background")
        }
      >
        <div className="flex flex--direction-column">
          {topicId && userId ? (
            <Subscribe topicId={topicId} userId={userId} />
          ) : null}
          {userId === opUserId || myProfile?.isModerator ? (
            <DeleteAThing
              rulesType={"forum"}
              isMyThing={userId === opUserId}
              isModerator={myProfile?.isModerator ? true : false}
              description={"discussion"}
              handleDelete={(reason) => {
                deleteTopic(topicId, topicTitle, reason ? reason : "").then(
                  () => {
                    const params = searchParams;
                    params.set("page", "topics");
                    params.delete("repliesNum");
                    params.delete("repliesIndex");
                    setSearchParams(params);
                  }
                );
              }}
            />
          ) : null}
          {editingTitle ? (
            <div className="register-form">
              <div>
                <label htmlFor="discussionTitleInTopic">Discussion Title</label>
                <input
                  style={{ backgroundColor: "#f3f5f2", color: "#010101" }}
                  autoFocus
                  id="discussionTitleInTopic"
                  type="text"
                  placeholder="Title goes here..."
                  required
                  value={topicTitleEdit}
                  onChange={(event) => {
                    if (event.target.value.length <= 100) {
                      setTopicTitleEdit(event.target.value);
                    }
                  }}
                />

                <button
                  onClick={() => {
                    updateTopicTitle(topicId, topicTitleEdit)
                      .then(() => {
                        setTopicTitleReal(topicTitleEdit);
                      })
                      .finally(() => {
                        setEditingTitle(false);
                      });
                  }}
                  className="btn btn--outline min-width--even-larger margin-top-bigger"
                  disabled={!topicTitleEdit.length}
                >
                  Update Discussion Title
                </button>
              </div>
            </div>
          ) : (
            <p className="description--large bold center-text">
              {topicTitleReal}
            </p>
          )}
          {userId === opUserId ? (
            <EditAThing
              toggleEditHandler={handleToggleEdit}
              argEditing={editingTitle}
            />
          ) : null}
        </div>
      </div>
      <ReplyList
        myProfile={myProfile}
        myUserId={userId}
        topicId={topicId}
        searchParams={searchParams}
        setSearchParams={setSearchParams}
        forceRefresh={forceRefresh}
        stalePaginationArray={stalePaginationArray}
        handleWillNeedToSeek={handleWillNeedToSeek}
        handleGatherSuggestedUserIds={handleGatherSuggestedUserIds}
        handleReplyToMessage={handleReplyToMessage}
        nightMode={nightMode}
      />
      <ForumAndDebriefRules
        rulesType={"forum"}
        readOnly={true}
        nightMode={nightMode}
      />
      <div ref={commentRef}>
        <CreateTopic
          userId={userId}
          isReply={true}
          topicId={topicId}
          handleForceRefresh={handleForceRefresh}
          suggestedUserIds={suggestedUserIds}
          replyToMessageRequest={replyToMessageRequest}
          handleClearReplyToMessage={handleClearReplyToMessage}
          nightMode={nightMode}
        />
      </div>
    </div>
  );
}

export default Topic;
