import Pricing from "./Pricing";
import {
  stateNames,
  getBattleInfo,
  nightModeStyleLessDarkBackgroundOnly,
  nightModeStyleWhite,
} from "../publicFunctions/publicHelpers/helpers";
import Reveal from "./animations/Reveal";
import { useEffect, useState } from "react";

function PricingSection({
  battles,
  hideTitle,
  openLogInHandler,
  userInfo,
  alreadyJoinedMap,
  nightMode,
}) {
  const [renderedPricingCards, setRenderedPricingCards] = useState(null);

  useEffect(() => {
    if (battles?.length) {
      const tempRender = [];
      for (let i = 0; i < battles.length && tempRender.length < 2; i++) {
        const battle = battles[i];
        if (alreadyJoinedMap.has(battle.battleId)) continue;
        if (
          (battle.state && battle.state === stateNames.NOT_STARTED) ||
          battle.state === stateNames.INITIALIZED
        ) {
          tempRender.push(
            <Pricing
              className={
                tempRender.length !== 0
                  ? "pricing-battle--nextSoonest" + (nightMode ? " white" : "")
                  : i === 0
                  ? "pricing-battle--soonest"
                  : "pricing-battle--first"
              }
              key={battle.battleId}
              battleInfo={getBattleInfo(battle)}
              openLogInHandler={openLogInHandler}
              isLoggedIn={userInfo && userInfo.isLoggedIn()}
              nightMode={nightMode}
              nextStyle={tempRender.length !== 0 ? true : false}
            />
          );
        }
      }
      setRenderedPricingCards(tempRender);
    }
  }, [battles, nightMode]);

  if (!renderedPricingCards?.length) return null;

  return (
    <section id="home-pricing-section" className="section-pricing">
      <div className={hideTitle ? "hidden" : "container"}>
        <Reveal type="fromBottom" rotate={true}>
          <span className={"subheading" + (nightMode ? " blue" : "")}>
            Join the battle
          </span>
        </Reveal>
        <Reveal type="fromBottom" rotate={true}>
          <h2
            style={nightMode ? nightModeStyleWhite : {}}
            className="heading-secondary"
          >
            Every few months a new Battle begins!
          </h2>
        </Reveal>
      </div>

      <Reveal type="fromBottom" fadeIn={true}>
        <div className="container grid grid--2-cols margin-bottom-md">
          {renderedPricingCards}
        </div>
      </Reveal>
      <div
        style={nightMode ? nightModeStyleWhite : {}}
        className="container grid"
      >
        <aside className="battle-details">
          *Prices are in USD and include all applicable taxes.
          <br />
        </aside>
      </div>
    </section>
  );
}

export default PricingSection;
