import { useEffect, useState } from "react";
import { getUploadedStoryByUserIdAndRaidId } from "../../publicFunctions/debriefHandler";
import { getCardImageUrlByCard } from "../../publicFunctions/publicHelpers/imageUrlBuilder";
import CardState from "../../publicFunctions/clientClasses/CardState";
import BattleCard from "../battle/BattleCard";
import { createCardWrapperForTavernCard } from "../../publicFunctions/tavernHandler";
import Lottie from "react-lottie-player";
import loadingAnimation from "../../img/animations/loadinganimation.json";
import {
  dayModeStyleBlack,
  nightModeStyleWhite,
} from "../../publicFunctions/publicHelpers/helpers";

function Raid({
  myProfile,
  raid,
  completedRaidAtSomePoint,
  setLoadedStory,
  nightMode,
}) {
  const [fetchingStoryState, setFetchingStoryState] = useState("init");
  const [raidStory, setRaidStory] = useState(null);
  const [renderedRaid, setRenderedRaid] = useState(null);

  async function fetchRaidStory(userId, raidId) {
    const tempDebriefRaidStory = await getUploadedStoryByUserIdAndRaidId(
      userId,
      raidId
    );
    if (tempDebriefRaidStory) {
      return tempDebriefRaidStory;
    } else {
      return null;
    }
  }

  // in order for this component to show, myProfile will have to have a userId
  useEffect(() => {
    if (completedRaidAtSomePoint && myProfile?.userId) {
      const userId = myProfile.userId;
      fetchRaidStory(userId, raid.id)
        .then((raidStory) => {
          if (raidStory) {
            setRaidStory(raidStory);
          } else {
            setFetchingStoryState("complete");
          }
        })
        .catch((err) => {
          console.log(err);
          setFetchingStoryState("error");
        });
    } else if (myProfile?.userId) {
      setFetchingStoryState("complete");
    }
  }, [myProfile]);

  useEffect(() => {
    // if we have a raid story then we don't want to set to complete until we've downloaded the story
    if (raidStory) {
      setFetchingStoryState("complete");
    }
  }, [raidStory]);

  useEffect(() => {
    const renderedTinyCards = [];
    if (raid.loot?.length) {
      for (let i = 0; i < raid.loot.length; i++) {
        renderedTinyCards.push(
          <img
            className="debrief-genre-title-card"
            key={i.toString(10) + "lootTinyImg"}
            src={getCardImageUrlByCard(
              { imageFileName: raid.loot[i].imageFileName },
              true
            )}
          />
        );
      }
    }

    const firstCardState = createCardState(
      raid.cards.length >= 3 ? raid.cards[0] : null,
      true
    );
    const secondCardState = createCardState(
      raid.cards.length >= 3 ? raid.cards[1] : null,
      false
    );
    const thirdCardState = createCardState(
      raid.cards.length >= 3 ? raid.cards[2] : null,
      false
    );

    let definition = "";
    if (raid.definition) {
      definition = raid.definition;
    }

    let buttonText = "";
    let raidCompletedThenDeleted = false;
    let showEditStoryButton = false;
    if (fetchingStoryState === "complete") {
      if (!completedRaidAtSomePoint) {
        buttonText = "Start Raid!";
      } else {
        if (raidStory) {
          buttonText = "View my story";
          showEditStoryButton = true;
        } else {
          // the user probably deleted the story
          buttonText = "Start Raid!";
          raidCompletedThenDeleted = true;
        }
      }
    }

    setRenderedRaid(
      <div
        className={
          "raid-container" +
          (nightMode
            ? " raid-container--white"
            : raidStory
            ? " raid-container--done"
            : " raid-container--avail")
        }
      >
        <h2
          style={nightMode ? nightModeStyleWhite : dayModeStyleBlack}
          className="heading-secondary margin--none center-text"
        >
          {raid.name + (completedRaidAtSomePoint ? " - COMPLETED" : "")}
        </h2>
        <p className="description italic center text">{definition}</p>
        <br />
        <br />
        <div className="prompt-selector-cards flex justify-content--center flex--gap-smaller align-items--center">
          <div className="prompt-selector-container">
            <BattleCard
              cardState={firstCardState}
              position={0}
              requestUnlock={null}
              isWorking={false}
              cardIsBeingRedrawn={false}
              forceLockCards={true}
              makeThisAButton={true}
              ownedBorder={false}
              promptSelector={true}
            />
          </div>
          <div className="prompt-selector-container">
            <BattleCard
              cardState={secondCardState}
              position={0}
              requestUnlock={null}
              isWorking={false}
              cardIsBeingRedrawn={false}
              forceLockCards={true}
              makeThisAButton={true}
              ownedBorder={false}
              promptSelector={true}
            />
          </div>
          <div className="prompt-selector-container">
            <BattleCard
              cardState={thirdCardState}
              position={0}
              requestUnlock={null}
              isWorking={false}
              cardIsBeingRedrawn={false}
              forceLockCards={true}
              makeThisAButton={true}
              ownedBorder={false}
              promptSelector={true}
            />
          </div>
        </div>
        {fetchingStoryState === "error" ? (
          <p className="description bold center-text">
            Hmmm... something went wrong. Please try refreshing the page.
          </p>
        ) : fetchingStoryState === "init" ? (
          <div className="center-flex">
            <Lottie
              loop
              animationData={loadingAnimation}
              play
              style={{ width: "5rem", height: "5rem" }}
            ></Lottie>
          </div>
        ) : (
          <div className="margin-top-bottom-md ">
            {raidCompletedThenDeleted ? (
              <p className="description--small italic center-text">
                Note: you already earned the loot for this Raid.
              </p>
            ) : null}
            <div className="flex flex--direction-column flex--gap-small">
              <button
                onClick={() => {
                  if (completedRaidAtSomePoint && raidStory) {
                    // view story
                    window.open("/story/tavern/" + raidStory.storyId, "_blank");
                  } else {
                    // just create a blank raid story as per normal
                    setLoadedStory({ storyId: "", raidObject: raid });
                  }
                }}
                className="btn btn--register min-width--larger story-submission-btn"
              >
                {buttonText}
              </button>
              {showEditStoryButton && raidStory ? (
                <button
                  onClick={() => {
                    setLoadedStory(raidStory);
                  }}
                  className="btn btn--register min-width--larger story-submission-btn"
                >
                  Edit my story
                </button>
              ) : null}
            </div>
          </div>
        )}
        <p className="description--larger bold margin--none center-text">
          {completedRaidAtSomePoint
            ? "You earned the following Loot:"
            : "Loot:"}
        </p>
        {!completedRaidAtSomePoint ? (
          <p className="description--small center-text italic">
            Complete this Raid in order to earn the following cards.
          </p>
        ) : null}
        <div>{renderedTinyCards}</div>
      </div>
    );
  }, [fetchingStoryState, raidStory, nightMode]);

  return <>{renderedRaid}</>;
}

function createCardState(card, isGenre = false) {
  if (!card) {
    return new CardState("");
  }
  const tempCardState = isGenre ? new CardState("Genre") : new CardState("");
  const cardWrapper = createCardWrapperForTavernCard({
    id: card.id,
    imageFileName: card.imageFileName,
  });
  cardWrapper.text = card.text;
  cardWrapper.type = card.type;
  tempCardState.injectCardWithDataFromServer(cardWrapper);
  return tempCardState;
}

export default Raid;
