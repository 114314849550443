import { IonIcon } from "@ionic/react";
import {
  chevronDownOutline,
  shareSocialOutline,
  closeCircle,
  checkmarkCircleOutline,
} from "ionicons/icons";
import { useState } from "react";
import { makeSomethingPublic } from "../publicFunctions/publicProfileHandler";
import CopyToClipboard from "./CopyToClipboard";
import {
  dayModeDefault,
  dayModeStyleBlack,
  nightModeStyleLessDark,
  nightModeStyleWhite,
} from "../publicFunctions/publicHelpers/helpers";

function SharingSettings({
  type,
  isPublic,
  refreshHandler,
  documentId = "",
  isDebriefStory,
  nightMode,
}) {
  const [expanded, setExpanded] = useState(false);
  const [iUnderstandMakePublic, setIUnderstandMakePublic] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showYouSure, setShowYouSure] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  let sharingLink = "";
  if (type === "Profile") {
    sharingLink = "https://www.writingbattle.com/profile/" + documentId;
  } else if (type === "Story") {
    sharingLink =
      "https://www.writingbattle.com/story/" +
      (isDebriefStory ? "debrief/" : "arena/") +
      documentId +
      "?uploadedStory";
  }

  return (
    <div className="container">
      <div
        className={
          expanded
            ? "sharing-settings blue-background--darker white"
            : "sharing-settings--closed"
        }
      >
        <div className="flex flex--direction-column align-items--end">
          <div className="flex">
            {expanded ? (
              <p className="description">
                {!isPublic
                  ? "Your " +
                    type +
                    " currently can only be viewed by active members of Writing Battle."
                  : "Currently, your " +
                    type +
                    " can be viewed by anyone with a link or by active members of Writing Battle."}
              </p>
            ) : null}
            <IonIcon
              style={
                nightMode && !expanded ? nightModeStyleWhite : dayModeStyleBlack
              }
              onClick={(event) => {
                event.preventDefault();
                setExpanded(!expanded);
              }}
              className="settings-icon"
              icon={expanded ? chevronDownOutline : shareSocialOutline}
            />
          </div>
          {errorMessage ? (
            <p className="description bold right-text">{errorMessage}</p>
          ) : null}
          {expanded ? (
            <div className="flex flex--direction-column">
              <div className="align-self--end">
                {isSubmitting ? null : !isPublic ? (
                  <p className="description bold right-text">
                    Would you like to make your {type} public so you could share
                    it?"
                  </p>
                ) : (
                  <div className="copy-link right-text">
                    <div className="flex align-items--center">
                      <p className="description">
                        Share this link: <br />
                        <span className="bold break-long-words">
                          {sharingLink}
                        </span>
                      </p>
                      <CopyToClipboard
                        textToCopy={sharingLink}
                        // always true when in sharing settings
                        nightMode={true}
                      />
                    </div>
                  </div>
                )}
                {isPublic && showYouSure ? (
                  <p className="description bold right-text margin-top-smaller">
                    Revert back to PRIVATE:
                  </p>
                ) : null}
                {showYouSure ? (
                  <div className="flex justify-content--end align-items--center">
                    <div className="redraw--youSure">
                      <p className="description white">Are you sure?</p>
                      <IonIcon
                        onClick={async (event) => {
                          event.preventDefault();
                          setIsSubmitting(true);
                          setShowYouSure(false);
                          setErrorMessage("One moment...");
                          try {
                            const success = await makeSomethingPublic(
                              type,
                              !isPublic,
                              type === "Story" ? documentId : ""
                            );
                            if (success) {
                              setErrorMessage("Success!");
                              refreshHandler(
                                isPublic ? "toPrivate" : "toPublic"
                              );
                            } else {
                              setErrorMessage(
                                "Hmmm something went wrong. Please try again in a few minutes."
                              );
                            }
                          } catch (err) {
                            console.log(err);
                            setErrorMessage(
                              "Hmmm something went wrong. Please try again in a few minutes."
                            );
                          }
                        }}
                        className="im-sure"
                        icon={checkmarkCircleOutline}
                      />
                      <IonIcon
                        onClick={() => {
                          setShowYouSure(false);
                          setIsSubmitting(false);
                          setIUnderstandMakePublic(false);
                        }}
                        className="not-sure"
                        icon={closeCircle}
                      />
                    </div>
                  </div>
                ) : null}
                {isPublic ? (
                  <div className="flex flex--direction-column">
                    <p className="description--smaller right-text">
                      Would you like to revert your {type} to private so that
                      only active members of the site can see it?
                    </p>
                    <p className="description--smaller right-text">
                      Click{" "}
                      <span
                        className="clickable-text"
                        onClick={(event) => {
                          event.preventDefault();
                          setShowYouSure(true);
                        }}
                      >
                        HERE
                      </span>{" "}
                      to change the public status of this {type}.
                    </p>
                  </div>
                ) : (
                  <div className="flex flex--direction-column">
                    {!showYouSure ? (
                      <button
                        onClick={async (event) => {
                          event.preventDefault();
                          setShowYouSure(true);
                        }}
                        className="btn--register login-btn"
                        disabled={!iUnderstandMakePublic || isSubmitting}
                      >
                        Make My {type} Public
                      </button>
                    ) : null}
                    <div className="flex flex--gap-smaller padding">
                      <input
                        required
                        id="iUnderstandCheckbox"
                        className="checkbox--medium"
                        type="checkbox"
                        value="iUnderstandMakePublic"
                        checked={iUnderstandMakePublic}
                        onChange={(event) => {
                          setIUnderstandMakePublic(!iUnderstandMakePublic);
                          setErrorMessage("");
                        }}
                      />
                      <label
                        htmlFor="iUnderstandCheckbox"
                        className="description--small"
                      >
                        {"(*REQUIRED) "}I understand that by making it Public,
                        anyone with this link could view my {type}.
                      </label>
                    </div>
                  </div>
                )}
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default SharingSettings;
