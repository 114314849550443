import BattleRavenTarotBanner from "../../img/deckBanners/BattleRavenTarotBanner.webp";
import PromptsVolumeIBanner from "../../img/deckBanners/PromptsVolumeIBanner.webp";
import {
  dayModeDefault,
  nightModeStyle,
} from "../../publicFunctions/publicHelpers/helpers";

function DeckBannerAds({ nightMode }) {
  return (
    <div
      style={nightMode ? nightModeStyle : {}}
      className="container banner-ads-container cursor--pointer"
      onClick={(event) => {
        event.preventDefault();
        window.open("https://www.writingbattle.shop", "_blank");
      }}
    >
      <div className="flex flex--direction-column align-items--center flex--gap-smaller">
        <p className="description bold center-text">
          Order these decks in the shop!
        </p>
        <img className="banner-ad-img" src={PromptsVolumeIBanner} />
        <img className="banner-ad-img" src={BattleRavenTarotBanner} />
        <p className="description--small italic center-text">
          Did you know that buying a physical deck also unlocks those cards in
          the Tavern?
        </p>
      </div>
    </div>
  );
}

export default DeckBannerAds;
