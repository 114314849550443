import { useState } from "react";
import CardSelector from "./CardSelector";
import DeckBannerAds from "../decks/DeckBannerAds";
import {
  dayModeDefault,
  nightModeStyle,
} from "../../publicFunctions/publicHelpers/helpers";

function CardCollection({
  myProfile,
  myCards,
  collectionMeta,
  requestCollectionMeta,
  requestMyCards,
  nightMode,
}) {
  const [neverShowUnownedCards, setNeverShowUnownedCards] = useState(false);

  const selectedCardHandler = (cardId) => {
    // do nothing
  };
  return (
    <div style={nightMode ? nightModeStyle : {}}>
      <div className="flex justify-content--center margin-top-bottom-md">
        <div className="flex flex--gap-smaller padding">
          <input
            required
            id="neverShowUnearnedCheckbox"
            className="checkbox"
            type="checkbox"
            value="neverShowUnearnedCheckbox"
            checked={neverShowUnownedCards}
            onChange={(event) => {
              setNeverShowUnownedCards(!neverShowUnownedCards);
            }}
          />
          <label
            htmlFor="neverShowUnearnedCheckbox"
            className="description--small"
          >
            Only show cards that I've earned
          </label>
        </div>
      </div>
      <div className="flex justify-content--center margin-top-bottom-md">
        <CardSelector
          collectionMeta={collectionMeta}
          requestCollectionMeta={requestCollectionMeta}
          requestMyCards={requestMyCards}
          myCards={myCards}
          myProfile={myProfile}
          selectedCardHandler={selectedCardHandler}
          neverShowUnownedCards={neverShowUnownedCards}
          nightMode={nightMode}
        />
      </div>
      <DeckBannerAds nightMode={nightMode} />
    </div>
  );
}

export default CardCollection;
