import { useEffect, useState } from "react";
import Lottie from "react-lottie-player";
import loadingAnimation from "../../img/animations/simpleLoading.json";
import { updateMyPrivateUserInfo } from "../../publicFunctions/registerHandler";
import Settings from "./Settings";
import MyKnockoutPoints from "./MyKnockoutPoints";
import { nightModeStyle } from "../../publicFunctions/publicHelpers/helpers";

function PrivateAccount({
  myProfile,
  showMe,
  refreshMyProfile,
  openLogInHandler,
  handleChangePage,
  nightMode,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [changesMade, setChangesMade] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [useAlias, setUseAlias] = useState(false);
  const [alias, setAlias] = useState("");
  const [email, setEmail] = useState("");
  const [credit, setCredit] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    if (myProfile && myProfile.isInitialized()) {
      setIsLoading(false);
      setChangesMade(false);
      if (myProfile.names.firstName) setFirstName(myProfile.names.firstName);
      if (myProfile.names.lastName) setLastName(myProfile.names.lastName);
      if (myProfile.names.alias) setAlias(myProfile.names.alias);
      if (myProfile.names.useAlias) setUseAlias(true);
      else setUseAlias(false);
      setEmail(myProfile.email);
      if (myProfile.wallet && myProfile.wallet.credit)
        setCredit(myProfile.wallet.credit);
    }
  }, [myProfile]);

  const onSubmit = async (event) => {
    event.preventDefault();

    // do a client side check of the update
    if (!firstName || !lastName || (!alias && useAlias)) {
      if (!firstName || !lastName)
        setErrorMessage("First and last name cannot be blank.");
      if (!alias && useAlias)
        setErrorMessage(
          "Please enter an Alias or uncheck the Use Alias checkbox."
        );
      return;
    }

    setIsLoading(true);
    // if success
    updateMyPrivateUserInfo(myProfile.userId, {
      names: {
        firstName: firstName,
        lastName: lastName,
        alias: alias,
        useAlias: useAlias,
      },
    })
      .then((success) => {
        setTimeout(function () {
          refreshMyProfile(true).then((success) => {
            if (success) {
              setErrorMessage("Successfully updated profile!");
              setChangesMade(false);
              setIsLoading(false);
            } else {
              setErrorMessage(
                "Hmmmm something went wrong. Please refresh the page and try again."
              );
            }
          });
        }, 3500); // delay so that the hook can happen in back end
      })
      .catch((err) => {
        setErrorMessage(err.message);
        setIsLoading(false);
      });
  };

  const onFirstNameChange = (event) => {
    setFirstName(event.target.value);
    setChangesMade(true);
    setErrorMessage("");
  };
  const onLastNameChange = (event) => {
    setLastName(event.target.value);
    setChangesMade(true);
    setErrorMessage("");
  };
  const onAliasChange = (event) => {
    setAlias(event.target.value);
    setChangesMade(true);
    setErrorMessage("");
  };
  const onUseAliasChange = (event) => {
    setUseAlias(event.target.checked);
    setChangesMade(true);
    setErrorMessage("");
  };

  return (
    <div>
      <p className="description">
        Login Email: <strong>{email}</strong>
      </p>
      <p className="description">
        Password: *********** {"("}
        <span
          onClick={() => handleChangePage("changePassword")}
          className="clickable-text"
        >
          change password
        </span>
        {")"}
      </p>
      <p className={credit ? "description" : "hidden"}>
        Writing Battle Credit: {"$" + credit.toString(10) + ".00 USD"}
      </p>
      <br />
      <br />
      <br />
      <form className="register-form" onSubmit={onSubmit}>
        <div className="flex flex--gap-small">
          <div>
            <label htmlFor="firstNameMyAccount">First Name</label>
            <input
              style={nightMode ? nightModeStyle : {}}
              disabled={useAlias}
              id="firstNameMyAccount"
              type="text"
              required
              value={firstName}
              onChange={onFirstNameChange}
            />
          </div>
          <div>
            <label htmlFor="lastNameMyAccount">Last Name</label>
            <input
              style={nightMode ? nightModeStyle : {}}
              disabled={useAlias}
              id="lastNameMyAccount"
              type="text"
              required
              value={lastName}
              onChange={onLastNameChange}
            />
          </div>
        </div>
        <div>
          <label htmlFor="aliasMyAccount" disabled={!useAlias}>
            Alias
          </label>
          <input
            style={nightMode ? nightModeStyle : {}}
            disabled={!useAlias}
            id="aliasMyAccount"
            type="text"
            required
            value={alias}
            onChange={onAliasChange}
          />
        </div>
        <div className="flex flex--gap-small flex--justify-start flex--align-center">
          <input
            style={nightMode ? nightModeStyle : {}}
            className="checkbox"
            type="checkbox"
            id="useAliasMyAccount"
            value="useAlias"
            checked={useAlias}
            onChange={onUseAliasChange}
          />
          <label className="center-vert" htmlFor="useAliasMyAccount">
            Use Alias
          </label>
        </div>

        {errorMessage ? (
          <p className="description error-text">{errorMessage}</p>
        ) : null}
        <button
          className="btn btn--register"
          disabled={isLoading || !changesMade}
        >
          Update Name
          <div className={isLoading ? "simple-loader" : "hidden"}>
            <Lottie
              loop
              animationData={loadingAnimation}
              speed={1.5}
              play
            ></Lottie>
          </div>
        </button>
      </form>
      <Settings
        myProfile={myProfile}
        refreshMyProfile={refreshMyProfile}
        nightMode={nightMode}
      />
      <MyKnockoutPoints myProfile={myProfile} nightMode={nightMode} />
    </div>
  );
}

export default PrivateAccount;
